import AudioPlayer from '../../AudioPlayer'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import marked from 'marked'
import SubscribeCTA from '../../Layout/SubscribeCTA'

const getMarkdownText = input => {
  let rawMarkup = marked(input || '')
  return { __html: rawMarkup }
}

// setPodcastVisibility(podCastVisible = true)

const Podcast = props => {
  let isOdd = false
  if (props.post.sequence % 2 === 0) {
    isOdd = true
  }
  const [podCastVisible, setPodcastVisibility] = useState(false)
  const session = null

  return (
    <div className='relative w-full'>

      {/* outer grid */}
      <div className='grid items-center grid-cols-12 gap-y-8 lg:gap-y-0 container'>

        {/* left col */}
        <div className={`col-span-12 lg:col-span-6 pr-5 order-2 ${isOdd ? 'lg:order-1 lg:col-start-1' : 'lg:order-2 lg:col-start-7'}`}>

          {/* title */}
          <h1 className={`title title--${props.fontSize} ${props.italic ? 'title--italic' : ''} ${props.fontType === 'serif' ? 'title--serif' : 'title--sans'}`}>
            {props.post.sections[0].title}
          </h1>

          {/* subtitle */}
          {
            props.body !== '<p><br></p>'
              ? <div className='my-2.5 lg:my-4 componentSubTitle subtitle subtitle--serif' dangerouslySetInnerHTML={getMarkdownText(props.body)} />
              : null
          }

          {/* show buttons if user is loggded in... show join cta if not logged in */}
          {session
            ? <div className='flex flex-row flex-wrap items-center mt-8'>
            <button onClick={() => {}} className='flex-shrink-0 mb-3 mr-4 button last:ml-0' key='1'>
                <svg className='w-3 h-3 mr-2 -mt-px' viewBox='0 0 15 15' fill='none'><path d='M7.5 10.5l-3.25-3m3.25 3l3-3m-3 3V1m6 6v6.5h-12V7' stroke='currentColor' /></svg>
                Download
              </button>
            <button onClick={() => setPodcastVisibility(!podCastVisible)} className='flex-shrink-0 mb-3 mr-4 button last:ml-0' key='2'>
                <svg className='w-3 h-3 mr-2 -mt-px' viewBox='0 0 15 15' fill='none'>
                  <path d='M6.5 5.5l.248-.434A.5.5 0 006 5.5h.5zm0 4H6a.5.5 0 00.748.434L6.5 9.5zm3.5-2l.248.434a.5.5 0 000-.868L10 7.5zM7.5 14A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM6 5.5v4h1v-4H6zm.748 4.434l3.5-2-.496-.868-3.5 2 .496.868zm3.5-2.868l-3.5-2-.496.868 3.5 2 .496-.868z' fill='currentColor' />
                </svg>
                Listen
              </button>
            <button className='flex-shrink-0 mb-3 mr-4 button last:ml-0' key='3'>
                <svg className='w-3 h-3 mr-2 -mt-px' viewBox='0 0 15 15' fill='none'>
                  <path d='M7.5 13.5l-.354.354a.5.5 0 00.708 0L7.5 13.5zM1.536 7.536l-.354.353.354-.353zm5-5l-.354.353.354-.353zM7.5 3.5l-.354.354a.5.5 0 00.708 0L7.5 3.5zm.964-.964l-.353-.354.353.354zm-.61 10.61L1.889 7.182l-.707.707 5.964 5.965.708-.708zm5.257-5.964l-5.965 5.964.708.708 5.964-5.965-.707-.707zM6.182 2.889l.964.965.708-.708-.965-.964-.707.707zm1.672.965l.964-.965-.707-.707-.965.964.708.708zM10.964 1c-1.07 0-2.096.425-2.853 1.182l.707.707A3.037 3.037 0 0110.964 2V1zM14 5.036c0 .805-.32 1.577-.89 2.146l.708.707A4.036 4.036 0 0015 5.036h-1zm1 0A4.036 4.036 0 0010.964 1v1A3.036 3.036 0 0114 5.036h1zM4.036 2c.805 0 1.577.32 2.146.89l.707-.708A4.036 4.036 0 004.036 1v1zM1 5.036A3.036 3.036 0 014.036 2V1A4.036 4.036 0 000 5.036h1zm.89 2.146A3.035 3.035 0 011 5.036H0c0 1.07.425 2.096 1.182 2.853l.707-.707z' fill='currentColor' />
                </svg>
                Save for later
              </button>
            {/* buttons.map((button, index) => {
                return

                <a onClick={() => setPodcastVisibility(!podCastVisible)} className='flex-shrink-0 mb-3 mr-4 button last:ml-0' key={index}>{button.text}</a>
                // return <a onClick={() => setPodcastVisibility(true)} className='mr-4 button last:ml-0' key={index} href={button.link}>{button.text}</a>
              */}
          </div>
            : <div className='pl-4 mt-10 border-l-2 border-accent'>
            <SubscribeCTA
                title='Members only podcast'
                message='Aenean lacinia bibendum nulla sed consectetur. Cum sociis natoque penatibus et magnis dis parturient montes.'
                cta='Join to Listen'
              />
          </div>}

        </div>

        {/* right col (the image) */}
        <div className={`col-span-12 flex items-center justify-center lg:col-span-5 order-1 lg:sticky lg:top-20 relative ${isOdd ? 'lg:order-2 lg:col-start-8' : 'lg:order-1 lg:col-start-1'}`}>
          <svg className='absolute w-12 h-12 text-white md:w-16 md:h-16' viewBox='0 0 68 69' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M29.333 24.9722L29.5864 24.5411C29.4318 24.4502 29.2403 24.4491 29.0847 24.5382C28.929 24.6272 28.833 24.7928 28.833 24.9722H29.333ZM29.333 44.1722H28.833C28.833 44.3515 28.929 44.5171 29.0847 44.6061C29.2403 44.6952 29.4318 44.6941 29.5864 44.6032L29.333 44.1722ZM45.6663 34.5722L45.9197 35.0032C46.0725 34.9134 46.1663 34.7494 46.1663 34.5722C46.1663 34.3949 46.0725 34.2309 45.9197 34.1411L45.6663 34.5722ZM33.9997 67.6722C16.2477 67.6722 1.83301 52.8662 1.83301 34.5722H0.833008C0.833008 53.3917 15.669 68.6722 33.9997 68.6722V67.6722ZM66.1663 34.5722C66.1663 52.8662 51.7516 67.6722 33.9997 67.6722V68.6722C52.3303 68.6722 67.1663 53.3917 67.1663 34.5722H66.1663ZM33.9997 1.47217C51.7516 1.47217 66.1663 16.2782 66.1663 34.5722H67.1663C67.1663 15.7526 52.3303 0.472168 33.9997 0.472168V1.47217ZM33.9997 0.472168C15.669 0.472168 0.833008 15.7526 0.833008 34.5722H1.83301C1.83301 16.2782 16.2477 1.47217 33.9997 1.47217V0.472168ZM28.833 24.9722V44.1722H29.833V24.9722H28.833ZM29.5864 44.6032L45.9197 35.0032L45.413 34.1411L29.0797 43.7411L29.5864 44.6032ZM45.9197 34.1411L29.5864 24.5411L29.0797 25.4032L45.413 35.0032L45.9197 34.1411Z' fill='currentColor' />
          </svg>

          <img src={props.imageUrl} className='w-full' />
        </div>

      </div>
      {/* PODCAST PLAYER */}
      <div className={`${podCastVisible ? 'audio-player--active' : 'audio-player--hidden'} audio-player`}>
        <AudioPlayer title={props.title} imageUrl={props.imageUrl} media={{ url: props.mediaUrl }} />
      </div>
    </div>

  )
}

Podcast.propTypes = {
  body: PropTypes.string,
  imageUrl: PropTypes.string,
  mediaUrl: PropTypes.string,
  post: PropTypes.object,
  title: PropTypes.string
}

export default Podcast
