// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { InteractionContext } from '@/contexts/InteractionContext'
// import * as Constants from '@/lib/constants'
import MembersOnlyLayer from '../../MembersOnlyLayer'
import React, { useContext } from 'react'
import marked from 'marked'
import * as url from '@/lib/url'

const getImageUrl = image => {
  return url().getMediaUrl(image.path, { q: 70, w: 800 })
}

const getMarkdownText = input => {
  return {
    __html: marked(input || '')
  }
}

// const renderQuestions = (post, q, onInteraction) => {
//   return <Handler post={post} responses={q.responses} id={q._id} onInteraction={onInteraction} />
// }

const Cta = ({ data, post }) => {
  const body = data.body
  // const questions = data.questions
  const title = data.title
  const imageUrl = (data.image && data.image[0]) ? getImageUrl(data.image[0]) : null


  const {
    // handleInteraction,
    setExpandedCta
  } = useContext(InteractionContext)

  // const handleCtaInteraction = (data = {}) => {
  //   handleInteraction({
  //     type: Constants.INTERACTION_TYPE.RESPONSE,
  //     data: {
  //       post: post._id,
  //       question: questions[0]._id,
  //       ...data
  //     }
  //   })
  // }

  const handleCtaExpansion = () => {
    setExpandedCta({ data, post })
  }

  // determine whether article can be viewed
  const { userIsMember, subscriberOnly } = post
  let canView = true
  if (subscriberOnly && !userIsMember) {
    canView = false
  }

  return (
    <div
      id='grid-cta'
      className={`collapsed-cta ${subscriberOnly && canView && 'cursor-pointer'}`}
      onClick={
        subscriberOnly && canView ? handleCtaExpansion : null
      }
    >
      {subscriberOnly &&
        (
          <MembersOnlyLayer type='response' title={title} primaryImage={imageUrl} memberCTA='Read now' nonMemberCTA='Upgrade to respond' userIsMember={userIsMember} />
        )}
      <div className='cta-inner'>
        {
          imageUrl
            ? (
              <div className='cta-image'>
                <img src={imageUrl} />
              </div>
            )
            : null
        }
        <div className='title'>
          <h1 className='text-white title--sans title--sml' dangerouslySetInnerHTML={getMarkdownText(title)} />
        </div>
        {
          body
            ? (
              <div className='description'>
                <p className='bodyText' dangerouslySetInnerHTML={getMarkdownText(body)} />
              </div>
            )
            : null
        }
        <button onClick={handleCtaExpansion} className='transition-colors cta-button'>
          Respond
          <svg viewBox='0 0 15 15' fill='none' className='w-4 h-4 ml-1.5'><path d='M13.5 7.5l-4-4m4 4l-4 4m4-4H1' stroke='currentColor' /></svg>
        </button>
      </div>
    </div>
  )
}

export default Cta
