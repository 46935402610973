import audio from '@/components/Handlers/audio'
import bodyText from '@/components/Handlers/bodyText'
import cta from '@/components/Handlers/cta'
import CustomComponent from '@/components/CustomComponent/index'
import form from '@/components/Handlers/form'
import image from '@/components/Handlers/image'
import imageGallery from '@/components/Handlers/imageGallery'
import promo from '@/components/Handlers/promo'
import promoCollection from '@/components/Handlers/promoCollection'
import pullQuote from '@/components/Handlers/pullQuote'
import shopProduct from '@/components/Handlers/shopProduct'
import subtitle from '@/components/Handlers/subtitle'
import textOverMedia from '@/components/Handlers/textOverMedia'
import title from '@/components/Handlers/title'
import videoEmbed from '@/components/Handlers/videoEmbed'
const urlUtils = require('./url')()

const SECTION_HANDLERS = {
  audio,
  bodyText,
  cta,
  form,
  image,
  imageGallery,
  promo,
  pullQuote,
  shopProduct,
  subtitle,
  textOverMedia,
  title,
  videoEmbed
}

class PostUtils {
  constructor (post) {
    this.post = post

    if (post) {
      this.primaryTag = post.tags && post.tags.find(t => t.primary)
      this.tags = post.tags
      this.contentTypeTag = post.tags && post.tags.find(t => !t.primary && !t.visible && !t.secondary)
    }
  }

  combinePostSections () {
    let data = {}

    this.post.sections.forEach(section => {
      data = {
        ...data,
        singleImages: (data.singleImages || []).concat(section.image),
        ...section
      }
    })

    data.singleImages = data.singleImages.filter(Boolean)
    data.primaryImage = data.singleImages[0]
    data.title = this.getTitle()
    return data
  }

  getTitle () {
    const typesMap = this.post._refSections
    const type = this.getType()

    if (typesMap) {
      let titleComponentType = 'title'

      if (type === 'response') {
        titleComponentType = 'cta'
      }

      const titleComponentId = Object.entries(typesMap).find(type => {
        // type = [ 'sectionId', 'sectionType' ]
        return type[1] === titleComponentType
      })

      if (titleComponentId) {
        const titleComponent = this.post.sections.find(s => s._id === titleComponentId[0])

        if (titleComponent) {
          return titleComponent.title
        }
      }
    }

    return this.post.title
  }

  getSeoDescription () {
    if (this.post.metaDescription) {
      return this.post.metaDescription
    }

    const fieldTypes = ['body', 'content']
    let description

    this.post.sections.forEach(section => {
      Object.keys(section).forEach(field => {
        if (fieldTypes.includes(field) && !description) {
          description = section[field]
        }
      })
    })

    return description || this.post.title
  }

  getPostComponents (displayMode) {
    let components
    if (displayMode !== 'raw') {
      components = this.getCustomComponent()
    }

    if (!components) {
      const sectionHandlers = this.getSectionHandlers()

      components = this.post.sections
        .map((section, index) => {
          const { _id: id } = section

          const Handler = sectionHandlers[index]

          if (typeof Handler !== 'function') {
            return null
          }

          return <Handler data={section} key={id} post={this.post} />
        })
        .filter(Boolean)

      if (components.length === 0) {
        return <div />
      }
    }

    return components
  }

  getCustomComponent () {
    const type = this.getType()

    if (type) {
      const data = this.combinePostSections()
      return [<CustomComponent data={data} key={0} post={this.post} type={type} />]
    }
  }

  getType () {
    if (!this.contentTypeTag) {
      return false
    }

    const types = {
      'content-type-cta': 'response',
      'content-type-gallery': 'exhibition',
      'content-type-article': 'article',
      'content-type-video': 'video',
      'content-type-quote': 'quote'
    }

    return types[this.contentTypeTag.value]
  }

  getSectionHandlers () {
    const typesMap = this.post._refSections

    // Collect an array of Promo components within this post.
    const promos = Object.keys(typesMap).map(id => {
      if (typesMap[id] === 'promo') {
        return id
      }

      return false
    }).filter(Boolean)

    const hasMultiplePromos = promos.length > 1

    const sectionHandlers = this.post.sections
      .map(section => {
        const { _id: id } = section

        let Handler

        // Don't return a promo component if there are multiple within this post,
        // we will render them all at the end.
        if (typesMap[id] === 'promo' && hasMultiplePromos) {
          return null
        }

        // Handle Podcast posts differently to other post types in the feed.
        if (this.primaryTag && this.primaryTag.value === 'listen' && typesMap[id] === 'audio') {
          Handler = CustomComponent
        } else {
          Handler = typesMap[id] && SECTION_HANDLERS[typesMap[id]]
        }

        return Handler
      }).filter(Boolean)

    if (hasMultiplePromos) {
      sectionHandlers.push(promoCollection)
    }

    return sectionHandlers
  }

  getStructuredTags () {
    const visibleTags = (this.post.tags || []).filter(t => t.visible)

    return visibleTags.map(tag => {
      if (!tag.primary && !tag.secondary) {
        return {
          href: `/tagged/${tag.value}`,
          label: tag.label
        }
      }
      // JIM L 13/09/2021 - not expecting multiple levels of primary/secondary
      // navigation, so disabling this section and simply returning the tag.

      /*
        const structuredLinkTags = this.renderTag(visibleTags, tag)
        const href = `/${structuredLinkTags.map(tag => tag.value).join('/')}`
        const label = structuredLinkTags[structuredLinkTags.length - 1].label

        return {
          href,
          label
        }
      */

      return {
        href: `/${tag.value}`,
        label: tag.label
      }
    }).filter(Boolean)
  }

  getPrimaryTag () {
    const tag = this.post.tags && this.post.tags.find(t => t.primary)
    return tag && tag.label ? tag.label : ''
  }

  getNonPrimaryTags () {
    const tags = this.post.tags && this.post.tags.find(t => !t.primary && t.visible)
    return tags || []
  }

  getSeoImage () {
    // Set a default for the OG image.
    let ogImageUrl = urlUtils.getCurrentUrl('/images/og.png')

    const type = this.getType()
    const allPostData = this.combinePostSections()
    let imagePath

    if (allPostData.primaryImage) {
      imagePath = allPostData.primaryImage.path
    } else if (type === 'exhibition' && allPostData.images && allPostData.images[0]) {
      imagePath = allPostData.images[0].path
    } else if (type === 'video' && allPostData.posterImage && allPostData.posterImage[0]) {
      imagePath = allPostData.posterImage[0].path
    }

    if (imagePath) {
      ogImageUrl = urlUtils.getMediaUrl(imagePath, { q: 85, w: 1200, h: 630, resize: 'entropy' })
    }

    return [{
      url: ogImageUrl,
      width: 1200,
      height: 630,
      alt: this.getTitle()
    }]
  }

  getTypes () {

  }

  hashtags (asArray, includeHash) {
    // const visibleTags = (this.post.tags || []).filter(t => t.visible)
    const visibleTags = [{ label: 'wearescramblers' }]

    const tags = visibleTags.map(tag => {
      const prefix = includeHash ? '#' : ''
      return `${prefix}${tag.label}`
    })

    return asArray ? tags : tags.join(' ')
  }

  renderTag (tags, currentTag) {
    const index = tags.findIndex(tag => tag.value === currentTag.value)

    const relevantTags = []

    for (let i = 0; i <= index; i++) {
      relevantTags.push(tags[i])
    }

    return relevantTags
  }

  sequence () {
    return this.post.sequence < 10 ? `0${this.post.sequence}` : this.post.sequence
  }

  socialShareMessage () {
    return this.post.socialSummary || 'Check out this post from Scramblers'
  }

  socialShareUrl () {
    return urlUtils.getShareUrl(this.post)
  }
}

module.exports = PostUtils
