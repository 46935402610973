import FacebookIcon from '../Icons/Facebook'
import InstagramIcon from '../Icons/Instagram'
import { InteractionContext } from '@/contexts/InteractionContext'
import React, { useContext } from 'react'
import { Transition } from '@headlessui/react'
import TwitterIcon from '../Icons/Twitter'
// import usePopularTags from '@/hooks/usePopularTags'
import { useUser } from '@/hooks/useUser'

const MegaMenu = props => {
  const { setMenuOpen } = useContext(InteractionContext)
  // const { tags } = usePopularTags()
  const { user } = useUser()

  return (
    <div id='megaMenu' className='h-full overflow-y-scroll'>
      {/* content */}
      <div className='container flex flex-col items-center justify-start min-h-full pt-6 overflow-y-auto lg:h-full xl:pb-6 lg:pt-8 lg:pb-8'>
        {/* logo row */}
        <div className='flex items-center justify-between w-full'>
          <div className='flex-1' />
          <img
            className='h-9 md:h-10 lg:h-12'
            src='/images/scramblers-logo.svg'
            alt='Scramblers'
          />
          {/* close button */}
          <div className='flex justify-end flex-1'>
            <button
              onClick={() => setMenuOpen(false)}
              className='button--close group'
            >
              <svg
                className='block w-3 h-3 transition-all duration-300 ease-in transform group-hover:rotate-180'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor' />
              </svg>
            </button>
          </div>
        </div>

        {/* main menu content */}
        <div className='flex flex-col items-start justify-start flex-1 w-full mt-8 lg:flex-row lg:mt-12'>
          {/* col 1 - menu and tags */}
          <Transition.Child
            enter='transition duration-200 ease-in delay-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition duration-300 ease-out'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            className='w-full lg:w-1/3 lg:h-full'
          >
            <div className='flex flex-col justify-start h-full'>
              <div className='relative z-0 flex items-center justify-center w-full h-5'>
                <div className='absolute top-0 left-0 z-0 flex items-center w-full h-full'>
                  <div className='w-full h-px bg-gray-800' />
                </div>
                <div className='z-10 flex items-center justify-center w-full h-full'>
                  <span className='inline-block px-2 text-xs leading-none tracking-wider text-white uppercase bg-darkGrey'>
                    menu
                  </span>
                </div>
              </div>
              {renderMenu()}

              {/* Presented by Bicester Heritage */}
              <div className='flex-col justify-end flex-1 hidden lg:flex'>
                <div className='relative z-0 flex items-center justify-center w-full h-5 mt-8'>
                  <div className='absolute top-0 left-0 z-0 flex items-center w-full h-full'>
                    <div className='absolute right-0 hidden w-4 h-full bg-darkGrey lg:block' />
                    <div className='w-full h-px bg-gray-800' />
                  </div>
                  <div className='z-10 flex items-center justify-center w-full h-full'>
                    <span className='inline-block px-2 text-xs leading-none tracking-wider text-white uppercase bg-darkGrey'>
                      presented by
                    </span>
                  </div>
                </div>
                <div className='flex items-start justify-center mt-4'>
                  <a
                    href='https://bicesterheritage.co.uk/'
                    target='_blank'
                    rel='noreferrer'
                    alt='Presented by Bicester Heritage'
                    title='Presented by Bicester Heritage'
                  >
                    <img width='75' src='/images/bicester-heritage-logo.svg' />
                  </a>
                </div>
              </div>
            </div>
          </Transition.Child>

          {/* col 2 - current issue - hidden on mobile */}
          <Transition.Child
            enter='transition duration-200 ease-in delay-150'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition duration-300 ease-out'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            className='flex-col hidden w-full md:w-1/3 lg:h-full lg:flex'
          >
            <div className='flex flex-col h-full'>
              <div className='relative z-0 flex items-center justify-center w-full h-5'>
                <div className='absolute top-0 left-0 z-0 flex items-center w-full h-full'>
                  <div className='w-full h-px bg-gray-800' />
                </div>
                <div className='z-10 flex items-center justify-center w-full h-full'>
                  <span className='inline-block px-2 text-xs leading-none tracking-wider text-white uppercase bg-darkGrey'>
                    next event
                  </span>
                </div>
              </div>

              {renderPromo()}
            </div>
          </Transition.Child>

          {/* col 3 - login/register - hidden on mobile  */}
          {user && <Transition.Child
            enter='transition duration-200 ease-in delay-200'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition duration-300 ease-out'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            className='hidden w-full mt-8 lg:block lg:w-1/3 lg:h-full lg:mt-0'
          >
            <div className='flex flex-col h-full'>
              <div className='flex-1'>
                <div className='relative z-0 flex items-center justify-center w-full h-5'>
                  <div className='absolute top-0 left-0 z-0 flex items-center w-full h-full'>
                    <div className='w-full h-px bg-gray-800' />
                  </div>
                  <div className='z-10 flex items-center w-full h-full lg:justify-center'>
                    <span className='inline-block pr-2 text-xs leading-none tracking-wider text-white uppercase bg-darkGrey lg:px-2'>
                      Welcome back
                    </span>
                  </div>
                </div>

                {renderUserColumn()}
              </div>

              {/* <div className=''>
                <div className='relative z-0 flex items-center justify-center w-full h-5 mt-8'>
                  <div className='absolute top-0 left-0 z-0 flex items-center w-full h-full'>
                    <div className='absolute left-0 hidden w-4 h-full bg-darkGrey lg:block' />
                    <div className='w-full h-px bg-gray-800' />
                  </div>
                  <div className='z-10 flex items-center justify-center w-full h-full'>
                    <span className='inline-block px-2 text-xs leading-none tracking-wider text-white uppercase bg-darkGrey'>in association with</span>
                  </div>
                </div>
                <div className='flex items-start justify-center mt-4'>
                  <a href='https://www.hagerty.co.uk/' target='_blank' rel='noreferrer' alt='In association with Hagerty' title='In association with Hagerty'>
                    <img width='160' src='/images/promo/hagerty.svg' alt='In association with Hagerty' />
                  </a>
                </div>
              </div> */}
            </div>
          </Transition.Child>}
        </div>

        {/* menu footer */}
        <div className='z-30 flex flex-col items-center w-full pt-5 pb-6 mt-16 text-white border-t border-gray-800 lg:mt-5 bg-darkGrey md:justify-between md:flex-row text-navigation'>
          <div className='flex items-center justify-start order-2 mt-5 space-x-4 md:mt-0 md:order-1'>
            <a
              href='/post/so-tell-me-about-scramblers'
              className='hover:text-accent'
            >
              About
            </a>
            <a
              href='https://bicesterheritage.co.uk/privacy-policy'
              target='_blank'
              rel='noreferrer'
              className='hover:text-accent'
            >
              Privacy policy
            </a>
            <a
              href='https://bicesterheritage.co.uk/cookies-statement/'
              target='_blank'
              rel='noreferrer'
              className='hover:text-accent'
            >
              Cookies statement
            </a>
            <a
              href='mailto:hello@wearescramblers.com'
              target='_blank'
              rel='noreferrer'
              className='hover:text-accent'
            >
              Contact Us
            </a>
          </div>
          <div className='flex items-center justify-start order-1 space-x-4 md:order-2'>
            <a
              className='hover:text-accent'
              href='https://facebook.com/wearescramblers'
              target='_blank'
              rel='noreferrer'
            >
              <FacebookIcon className='w-5 h-5' />
            </a>
            <a
              className='hover:text-accent'
              href='https://twitter.com/wearescramblers'
              target='_blank'
              rel='noreferrer'
            >
              <TwitterIcon className='w-5 h-5' />
            </a>
            <a
              className='hover:text-accent'
              href='https://instagram.com/wearescramblers'
              target='_blank'
              rel='noreferrer'
            >
              <InstagramIcon className='w-5 h-5' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )

  function renderMenu () {
    return (
      <>
        <div className='flex-1 mt-2 text-white lg:px-8'>
          <div className='flex flex-col items-center mt-3 text-4xl font-medium text-center title-med lg:text-5xl lg:mt-6'>
            <a href='/' className='mb-1 lg:w-full hover:text-accent'>
              All
            </a>
            <a href='/read' className='mb-1 lg:w-full hover:text-accent'>
              Read
            </a>
            <a href='/watch' className='mb-1 lg:w-full hover:text-accent'>
              Watch
            </a>
            <a href='/respond' className='mb-1 lg:w-full hover:text-accent'>
              Respond
            </a>
            <a href='/visit' className='mb-1 lg:w-full hover:text-accent'>
              Visit
            </a>
            <a href='/shop/all' className='mb-1 lg:w-full hover:text-accent'>
              Shop
            </a>
          </div>
        </div>

        <div className='w-full lg:hidden'>
          <div className='relative z-0 flex items-center justify-center w-full h-5 mt-8'>
            <div className='absolute top-0 left-0 z-0 flex items-center w-full h-full'>
              <div className='absolute right-0 hidden w-4 h-full bg-darkGrey lg:block' />
              <div className='w-full h-px bg-gray-800' />
            </div>
            <div className='z-10 flex items-center justify-center w-full h-full'>
              <span className='inline-block px-2 text-xs leading-none tracking-wider text-white uppercase bg-darkGrey'>
                me
              </span>
            </div>
          </div>
          <div className='flex flex-wrap items-center justify-center w-full mt-5'>
            <a href='/profile/me' className='mb-2 mr-3 button'>
              <svg
                className='w-4 h-4 mr-1.5'
                viewBox='0 0 24 24'
                strokeWidth='2'
                stroke='currentColor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <circle cx='12' cy='7' r='4' />
                <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
              </svg>
              My Profile
            </a>
            <a href='/saved' className='mb-2 button'>
              <svg
                className='w-4 h-4 mr-2'
                viewBox='0 0 24 24'
                strokeWidth='2'
                stroke='currentcolor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M13 7a2 2 0 0 1 2 2v12l-5 -3l-5 3v-12a2 2 0 0 1 2 -2h6z' />
                <path d='M9.265 4a2 2 0 0 1 1.735 -1h6a2 2 0 0 1 2 2v12l-1 -.6' />
              </svg>
              My saved posts
            </a>
          </div>
        </div>

        <div className='relative z-0 flex items-center justify-center w-full h-5 mt-8'>
          <div className='absolute top-0 left-0 z-0 flex items-center w-full h-full'>
            <div className='absolute right-0 hidden w-4 h-full bg-darkGrey lg:block' />
            {/* <div className='w-full h-px bg-gray-800' /> */}
          </div>
          {/* <div className='z-10 flex items-center justify-center w-full h-full'>
            <span className='inline-block px-2 text-xs leading-none tracking-wider text-white uppercase bg-darkGrey'>explore</span>
          </div> */}
        </div>

        {/* tags */}
        {/* <div className='flex flex-wrap items-start justify-center w-full pr-4 mt-5 space-x-2 caption'> */}
        {
          // tags
          //   ? tags.map((tag, index) => {
          //       return (
          //         <a
          //           className='px-2 py-1 mb-2 text-white border border-gray-600 rounded-full hover:bg-accent hover:text-white'
          //           href={tag.primary ? `/${tag.value}` : `/tagged/${tag.value}`}
          //           key={index}
          //         >
          //           {tag.label}
          //         </a>
          //       )
          //     })
          //   : null
        }
        {/* </div> */}
      </>
    )
  }

  function renderPromo () {
    return (
      <div className='flex flex-col items-start flex-1 px-8 mt-2 space-y-4 text-white border-gray-800 md:border-l md:border-r'>
        <img
          className='w-full'
          src='/images/welcomeMat/callingAllHeros.png'
          alt=''
        />
        <h4 className='font-medium text-white uppercase subtitle subtitle--sans'>
          Scramblers Monthly Assemblies
        </h4>
        <p className='text-gray-400 bodyText !mt-2'>
          The last weekend of every month (usually!) is reserved for our themed
          member events. These are free but places are limited – for latest
          details of the next dates for your diary, please hit the link below.
        </p>
        <a href='/visit' className='button'>
          More details
        </a>
      </div>
    )
  }

  function renderUserColumn () {
    return (
      <div className='flex-1 md:w-1/2 lg:w-full lg:pl-8'>
        <p className='mt-5 text-white subtitle subtitle--sans'>
          Welcome back,{' '}
          <span className='italic font-bold'>{user.displayName}.</span> Having
          fun? Access your account details below or manage your curated list of
          favourited articles.
        </p>
        <div className='flex flex-wrap items-center w-full mt-5'>
          <a
            href='/profile/me'
            className='w-full mb-2 xl:mr-3 button xl:w-auto'
          >
            <svg
              className='w-4 h-4 mr-1.5'
              viewBox='0 0 24 24'
              strokeWidth='2'
              stroke='currentColor'
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <circle cx='12' cy='7' r='4' />
              <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
            </svg>
            My Profile
          </a>
          <a href='/saved' className='w-full mb-2 button xl:w-auto'>
            <svg
              className='w-4 h-4 mr-2'
              viewBox='0 0 24 24'
              strokeWidth='2'
              stroke='currentcolor'
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <path d='M13 7a2 2 0 0 1 2 2v12l-5 -3l-5 3v-12a2 2 0 0 1 2 -2h6z' />
              <path d='M9.265 4a2 2 0 0 1 1.735 -1h6a2 2 0 0 1 2 2v12l-1 -.6' />
            </svg>
            My saved posts
          </a>
        </div>
      </div>
    )
  }
}

export default MegaMenu
