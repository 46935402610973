module.exports = [
  {
    start: new Date('2023-05-18T00:00:00.000Z'),
    end: new Date('2023-06-01T00:00:00.000Z')
  },
  {
    start: new Date('2023-06-01T00:00:00.000Z'),
    end: new Date('2023-07-01T00:00:00.000Z')
  },
  {
    start: new Date('2023-07-01T00:00:00.000Z'),
    end: new Date('2023-08-01T00:00:00.000Z')
  },
  {
    start: new Date('2023-08-01T00:00:00.000Z'),
    end: new Date('2023-09-01T00:00:00.000Z')
  },
  {
    start: new Date('2023-09-01T00:00:00.000Z'),
    end: new Date('2023-10-01T00:00:00.000Z')
  },
  {
    start: new Date('2023-10-01T00:00:00.000Z'),
    end: new Date('2023-11-01T00:00:00.000Z')
  },
  {
    start: new Date('2023-11-01T00:00:00.000Z'),
    end: new Date('2023-12-01T00:00:00.000Z')
  },
  {
    start: new Date('2023-12-01T00:00:00.000Z'),
    end: new Date('2024-02-01T00:00:00.000Z')
  },
  {
    start: new Date('2024-02-01T00:00:00.000Z'),
    end: new Date('2024-03-01T00:00:00.000Z')
  },
  {
    start: new Date('2024-03-01T00:00:00.000Z'),
    end: new Date('2024-04-01T00:00:00.000Z')
  },
  {
    start: new Date('2024-04-01T00:00:00.000Z'),
    end: new Date('2024-05-01T00:00:00.000Z')
  },
  {
    start: new Date('2024-05-01T00:00:00.000Z'),
    end: new Date('2024-06-01T00:00:00.000Z')
  }
]
