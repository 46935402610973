import * as gtag from '@/lib/gtag'
import Link from 'next/link'
import PropTypes from 'prop-types'
import scrollIntoView from 'scroll-into-view'
import Title from '../../Sections/Title'
import Subtitle from '../../Sections/Subtitle'
import { Waypoint } from 'react-waypoint'
const url = require('@/lib/url')()
const Flickity = require('react-flickity-component')

const getThumbnail = image => {
  return url.getMediaUrl(image.path, { h: 330 })
}

const Promo = props => {
  const { post } = props

  function tweakedPromo () {
    return (
      <div className='bg-pink-500 tweakedPromo'>
        <div className='container grid items-center grid-cols-12'>
          <div className='col-span-3'>
            <img
              className='w-full transform scale-110 shadow rotate-2'
              src={props.post.sections[0].imageUrl}
            />
          </div>
          <div className='flex flex-col items-start col-span-7 col-start-5 space-y-4 text-white'>
            <h1 className='title title--med title--sans'>{props.title}</h1>
            <p className='text-white bodyText'>{props.description}</p>
            <Link
              href='/'
              className='inline-block text-white border-white button hover:text-pink-500 hover:bg-white'
            >
              Buy it now
            </Link>
          </div>
        </div>
      </div>
    )
  }

  function simplePromo () {
    return (
      <div className='py-24 text-white bg-pink-500 simpleGalleryPromo'>
        <div className='container grid items-center grid-cols-12'>
          <div className='col-span-3'>
            <Flickity
              options={{
                cellAlign: 'left',
                imagesLoaded: false,
                setGallerySize: true,
                wrapAround: false,
                contain: true,
                pageDots: true,
                prevNextButtons: false,
                autoPlay: 3000
              }}
              disableImagesLoaded={false}
              reloadOnUpdate
            >
              <img className='w-full' src={props.post.sections[0].imageUrl} />
              <img className='w-full' src={props.post.sections[0].imageUrl} />
              <img className='w-full' src={props.post.sections[0].imageUrl} />
              <img className='w-full' src={props.post.sections[0].imageUrl} />
            </Flickity>
          </div>
          <div className='flex flex-col items-start col-span-7 col-start-5 space-y-4'>
            <h1 className='title title--med title--sans'>{props.title}</h1>
            <p className='bodyText'>{props.description}</p>
            <Link href='/' className='inline-block button button--black'>
              Buy it now
            </Link>
          </div>
        </div>
      </div>
    )
  }

  function galleryPromo () {
    return (
      <div className='galleryPromo'>
        <Title title={props.title} fontSize='med' fontType={props.fontType} />
        <Subtitle
          subtitle={props.description}
          showBorder={props.showBorder}
          author={props.author}
          post={post}
          italic
          width='col-span-12 lg:col-span-10 xl:col-span-8'
        />
        <div className='container grid grid-cols-12 mt-10 mb-5 overflow-hidden md:my-10'>
          <div className='relative z-0 col-span-12 col-start-1 mt-5 md:col-span-12'>
            <Flickity
              options={{
                cellAlign: 'left',
                imagesLoaded: false,
                setGallerySize: true,
                wrapAround: false,
                contain: false,
                pageDots: true,
                prevNextButtons: false,
                autoPlay: 3000
              }}
              disableImagesLoaded={false}
              reloadOnUpdate={false} // default false
              // static // default false
            >
              {props.images.map((image, index) => (
                <img
                  key={index}
                  src={getThumbnail(image)}
                  className='mr-12 h-80'
                />
              ))}
            </Flickity>
          </div>
          <Link href='/' className='inline-block mt-16 button'>
            Buy it now
          </Link>
        </div>
      </div>
    )
  }

  return (
    // promo type
    galleryPromo(props)
  )
}

Promo.propTypes = {
  post: PropTypes.object
}

export default Promo
