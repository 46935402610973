import Link from 'next/link'
import { useUser } from '@/hooks/useUser'
import { getUserMembershipType } from '@/lib/memberships'

const UserNav = () => {
  const { user } = useUser()

  const getMembershipType = user => {
    const membershipType = getUserMembershipType(user)

    if (!membershipType) {
      return false
    }

    if (membershipType === 'tier2') {
      return 'Merlin'
    }

    if (['tier1', 'paid'].includes(membershipType)) {
      return 'Pegasus'
    }
  }

  const handleLogout = async () => {
    await fetch('/api/logout', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({})
    })
  }

  return (
    <div className='w-56 space-y-4 overflow-hidden transform bg-white border border-gray-800 rounded-md shadow-lg pointer-events-auto translate-y-9'>
      <div className='flex flex-col pt-1 text-left text-gray-800 text-navigation'>
        <div className='flex flex-col px-5 py-3 space-y-1 border-b border-gray-400'>
          <span className='text-gray-600 caption'>Signed in as</span>
          {/* <span className='text-base text-gray-800 truncate bodyText'>{session.user.name || session.user.email}</span> */}
          <span className='text-base text-black truncate bodyText'>
            {user.email}
          </span>
          <span className='font-light text-gray-600 caption caption--lower'>
            Membership level:{' '}
            <span className='font-normal capitalize text-secondary'>
              {getMembershipType(user) || 'Standard'}
            </span>
          </span>
        </div>
        <Link
          href='/profile/me'
          className='px-5 py-3 border-b border-gray-400 hover:text-white hover:bg-accent'
        >
          My Profile
        </Link>
        <a
          onClick={handleLogout}
          className='px-5 py-3 text-left hover:bg-accent hover:text-white'
          href='/api/logout'
        >
          Sign Out
        </a>
      </div>
    </div>
  )
}

export default UserNav
