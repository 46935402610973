import React from 'react'

const Pin = () => (
  <div className='container grid grid-cols-12 mb-3'>
    <div className='col-span-2'>
      <div className='w-auto flex justify-start'>
        <span className='flex w-auto justify-start items-center text-gray-800 text-xs border border-gray-300 rounded-full py-1 px-2'>
          <svg className='mr-1' width='10' height='10' viewBox='0 0 15 15' fill='none'><path d='M7.5 15V8.5m0 0a4 4 0 100-8 4 4 0 000 8z' stroke='currentColor' /></svg>
          Pinned
        </span>
      </div>
    </div>
  </div>
  // <div className='container grid grid-cols-12 mb-3'>
  //   <div className='col-span-2'>
  //     <div className='w-auto flex justify-start'>
  //       <span className='flex justify-center items-center text-gray-800 text-xs border border-gray-300 rounded-full w-6 h-6'>
  //         <svg width='10' height='10' viewBox='0 0 15 15' fill='none'><path d='M7.5 15V8.5m0 0a4 4 0 100-8 4 4 0 000 8z' stroke='currentColor' /></svg>
  //       </span>
  //     </div>
  //   </div>
  // </div>
)

export default Pin
