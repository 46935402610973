import React from 'react'
import ShopProduct from '../Sections/ShopProduct'

const shopProduct = ({ data, post }) => {
  return (
    <ShopProduct
      post={post}
      title={data.title}
      description={data.description}
      shortDescription={data.shortDescription}
      imageUrl={data.imageUrl}
    />
  )
}

export default shopProduct
