import * as gtag from '@/lib/gtag'
import DefaultWelcomeMat from './WelcomeMat'
import { getWelcomeMat } from '../WelcomeMats'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import Footer from './Footer'
import Header from './Header'
import { InteractionContext } from '@/contexts/InteractionContext'
import Login from '../Auth/Login'
import MegaMenu from './MegaMenu'
import MembersOnlyModal from '../MembersOnlyModal'
import PostExpanded from '../PostExpanded'
import ShareControlsMobile from '../ShareControlsMobile'
import FullScreenExhibition from '../CustomComponent/Exhibition/FullScreenExhibition'
import CtaExpanded from '../CustomComponent/Cta/CtaExpanded'
import SignUpModal from './SignUpModal'

import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import CookieConsent from './CookieConsent'
import { useRouter } from 'next/router'

const Layout = ({
  allowWelcomeMat,
  baseUrl,
  authenticatedUser,
  category,
  children,
  cookies,
  deepLink,
  tags,
  token,
  returnUrl
}) => {
  const {
    loginOpen,
    menuOpen,
    expandedCta,
    expandedExhibition,
    expandedPost,
    mobileShareData,
    setExpandedPost,
    setExpandedCta,
    setExpandedExhibition,
    showSignUpModal
  } = useContext(InteractionContext)
  const router = useRouter()

  // Handle location updates when posts are expanded to fullscreen.
  useEffect(() => {
    let postUrl = baseUrl

    const isPostRoute = () => {
      const { asPath } = router
      const { lastVisitedPost } = window || {}

      let isPost = asPath.indexOf('/post/') > -1

      if (lastVisitedPost) {
        isPost = lastVisitedPost.indexOf('/post/') > -1
      }

      return isPost
    }

    if (expandedCta) {
      postUrl = `/post/${expandedCta.post.slug}`
      gtag.pageView(expandedCta.title, expandedCta.post.slug, true)
    }

    if (expandedExhibition) {
      postUrl = `/post/${expandedExhibition.slug}`
      gtag.pageView(expandedExhibition.title, expandedExhibition.slug, true)
    }

    if (expandedPost) {
      postUrl = `/post/${expandedPost.slug}`
      gtag.pageView(expandedPost.title, expandedPost.slug, true)
    }

    if (window) {
      if (expandedExhibition || expandedCta || expandedPost) {
        window.history.pushState({}, '', postUrl)
      } else if (isPostRoute()) {
        window.history.replaceState({}, '', '/')
        window.lastVisitedPost = ''
      }

      // Handles the use of the back button.
      window.onpopstate = function (event) {
        setExpandedPost(null)
        setExpandedCta(null)
        setExpandedExhibition(null)
      }

      window.lastVisitedPost = postUrl
    }
  }, [expandedCta, expandedExhibition, expandedPost])

  useEffect(() => {
    if (loginOpen) {
      // Wait a short period for the element to appear in the DOM.
      setTimeout(() => {
        const authModal = document.querySelector('#loginModal')
        // Lock the body scroll, allowing the auth modal to scroll.
        disableBodyScroll(authModal)
      }, 500)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [loginOpen])

  useEffect(() => {
    if (menuOpen) {
      // Wait a short period for the element to appear in the DOM.
      setTimeout(() => {
        const megaMenu = document.querySelector('#megaMenu')
        // Lock the body scroll, allowing the auth modal to scroll.
        disableBodyScroll(megaMenu)
      }, 500)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [menuOpen])

  useEffect(() => {
    if (showSignUpModal) {
      // Wait a short period for the element to appear in the DOM.
      setTimeout(() => {
        const signUpModal = document.querySelector('#signUpModal')
        // Lock the body scroll, allowing the auth modal to scroll.
        disableBodyScroll(signUpModal)
      }, 500)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [showSignUpModal])

  useEffect(() => {
    if (expandedPost) {
      // Wait a short period for the element to appear in the DOM.
      setTimeout(() => {
        const expandedPostModal = document.querySelector(
          '#full-screen-post-holder'
        )
        // Lock the body scroll, allowing the auth modal to scroll.
        disableBodyScroll(expandedPostModal)
      }, 500)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [expandedPost])

  useEffect(() => {
    if (expandedExhibition) {
      // Wait a short period for the element to appear in the DOM.
      setTimeout(() => {
        const exhibitionOverlay = document.querySelector(
          '#full-screen-exhibition'
        )
        // Lock the body scroll, allowing the auth modal to scroll.
        disableBodyScroll(exhibitionOverlay)
      }, 500)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [expandedExhibition])

  useEffect(() => {
    if (expandedCta) {
      // Wait a short period for the element to appear in the DOM.
      setTimeout(() => {
        const ctaOverlay = document.querySelector('#full-screen-cta-holder')
        // Lock the body scroll, allowing the auth modal to scroll.
        disableBodyScroll(ctaOverlay)
      }, 500)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [expandedCta])

  let WelcomeMat = null

  if (allowWelcomeMat) {
    if (category) {
      WelcomeMat = getWelcomeMat(category)
    } else {
      WelcomeMat = DefaultWelcomeMat
    }
  }

  return (
    <div>
      <Header
        hasWelcomeMat={WelcomeMat !== null}
        category={category}
        tags={tags}
        returnUrl={returnUrl}
      />
      {WelcomeMat ? <WelcomeMat /> : null}

      <Transition
        show={menuOpen === true}
        enter='transition duration-200 ease-in'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition duration-300 ease-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed top-0 left-0 z-50 items-start justify-center w-screen h-full bg-opacity-100 bg-darkGrey'
      >
        <MegaMenu />
      </Transition>

      <Transition
        show={showSignUpModal === true}
        enter='transition duration-200 ease-in'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition duration-300 ease-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed top-0 left-0 z-50 items-start justify-center w-screen h-full bg-opacity-100 bg-darkGrey'
      >
        <SignUpModal token={token} />
      </Transition>

      <Transition
        show={loginOpen === true}
        enter='transition duration-200 ease-in'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition duration-200 delay-200 ease-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed top-0 z-50 flex items-center justify-center w-screen min-h-screen py-10 overflow-y-auto bg-white bg-opacity-95'
      >
        <Login isAuthenticated={authenticatedUser} />
      </Transition>

      <Transition
        show={expandedPost !== null}
        enter='transition duration-200 ease-in'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition duration-200 delay-200 ease-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed top-0 left-0 z-50 w-screen h-screen bg-darkGrey bg-opacity-[0.99] overflow-y-auto px-3'
        id='full-screen-post-holder'
      >
        {expandedPost ? (
          <PostExpanded post={expandedPost} deepLink={!deepLink !== true} />
        ) : null}
      </Transition>

      <Transition
        show={expandedExhibition !== null}
        enter='transition duration-200 ease-in'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition duration-200 delay-200 ease-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed top-0 left-0 z-50 w-screen h-full pt-24 pb-8 bg-themeBg bg-opacity-[0.99]'
      >
        {expandedExhibition ? (
          <FullScreenExhibition
            data={expandedExhibition}
            deepLink={!deepLink !== true}
          />
        ) : null}
      </Transition>

      <Transition
        show={expandedCta !== null}
        enter='transition duration-200 ease-in'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition duration-200 delay-200 ease-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed top-0 left-0 z-30 w-screen h-screen bg-themeBg bg-opacity-[0.99] overflow-y-auto px-3'
        id='full-screen-cta-holder'
      >
        {expandedCta ? (
          <CtaExpanded ctaData={expandedCta} deepLink={!deepLink !== true} />
        ) : null}
      </Transition>

      <Transition
        show={mobileShareData.open}
        enter='transition duration-200 ease-in'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition duration-100 delay-300 ease-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed top-0 left-0 z-50 w-full h-full bg-themeBg bg-opacity-[0.8] overflow-y-auto px-3'
      >
        <ShareControlsMobile post={mobileShareData.post} />
      </Transition>

      <div
        id='feed'
        className={`container relative min-h-screen ${
          WelcomeMat ? '-mt-16' : 'pt-36'
        } z-90`}
      >
        <div className=''>{children}</div>
        <Footer />
      </div>

      <CookieConsent cookies={cookies} />

      <MembersOnlyModal />
    </div>
  )
}

Layout.propTypes = {
  allowWelcomeMat: PropTypes.bool,
  deepLink: PropTypes.bool,

  // Indicates whether there is a current user.
  authenticatedUser: PropTypes.object
}

export default Layout
