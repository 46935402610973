import React from 'react'
import Promo from '../Sections/Promo'
const url = require('@/lib/url')()

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 75 })
}

const promo = ({ data, post }) => {
  let imageUrl = ''

  if (data.image && data.image[0]) {
    imageUrl = getImageUrl(data.image[0])
  }
  // } else if (data.post && data.post.sections[0]) {
  //   imageUrl = data.post.sections[0].imageUrl
  // }

  return (
    <Promo
      imageUrl={imageUrl}
      originalPost={data.post}
      post={post}
      shortDescription={data.shortDescription}
      title={data.title}
    />
  )
}

export default promo
