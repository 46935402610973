import React from 'react'
import Title from '../Sections/Title'

const title = ({ data, post }) => {
  return (
    <Title {...data} post={post} />
  )
}

export default title
