import PropTypes from 'prop-types'
import React from 'react'

const Form = ({ body, elements, title }) => {
  return (
      <div>
        <h2>{title}</h2>
        <div>{body}</div>
        <form>
        {elements.map(element => {
          return (
            <>
              <label htmlFor={element.name}>{element.label}:</label>
              <input type={element.type} name={element.name} />
            </>
          )
        })
        }
        </form>
      </div>
  )
}

export default Form
