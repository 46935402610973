import PropTypes from 'prop-types'
import React from 'react'
import marked from 'marked'

const getMarkdownText = input => {
  return {
    __html: marked(input || '')
  }
}

// function createMarkup(text) {
//   return {__html: text}
// }

const Title = ({ title }) => (
  <div>
    <h1
      className='title title--sans title--sml'
      dangerouslySetInnerHTML={getMarkdownText(title)}
    />
  </div>
)

Title.propTypes = {
  fontSize: PropTypes.string,
  post: PropTypes.object,
  title: PropTypes.string
}

export default Title
