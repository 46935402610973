import PropTypes from 'prop-types'
import React from 'react'
import ShareControls from '../ShareControls'
const PostUtils = require('@/lib/post')

const PostDivider = ({ post, title, index, contentType }) => {
  const postUtils = new PostUtils(post)

  const tags = postUtils.getStructuredTags()
  let positionClass = 'left-0 px-3 lg:px-6 top-3 lg:top-4'
  if (contentType && contentType === 'content-type-cta') {
    positionClass = 'left-0 px-6 lg:px-8 top-6 lg:top-7'
  }

  return (

    <div className={`${positionClass} absolute z-20 flex items-center justify-between w-full leading-none text-white post-divider`}>
      <div className='flex items-center space-x-1 font-light leading-none md:hidden caption'>
        <span className='font-medium uppercase'>{title}</span>
      </div>
      <div className='items-center hidden mr-2 md:flex'>
        <svg className='w-3 h-3 mr-1.5' viewBox='0 0 15 15' fill='none'>
          <path d='M.5 7.5l-.354-.354a.5.5 0 000 .708L.5 7.5zm7 7l-.354.354a.5.5 0 00.708 0L7.5 14.5zm7-7l.354.354A.5.5 0 0015 7.5h-.5zm-7-7V0a.5.5 0 00-.354.146L7.5.5zM.146 7.854l7 7 .708-.708-7-7-.708.708zm7.708 7l7-7-.708-.708-7 7 .708.708zM15 7.5v-6h-1v6h1zM13.5 0h-6v1h6V0zM7.146.146l-7 7 .708.708 7-7-.708-.708zM15 1.5A1.5 1.5 0 0013.5 0v1a.5.5 0 01.5.5h1zM10.5 5a.5.5 0 01-.5-.5H9A1.5 1.5 0 0010.5 6V5zm.5-.5a.5.5 0 01-.5.5v1A1.5 1.5 0 0012 4.5h-1zm-.5-.5a.5.5 0 01.5.5h1A1.5 1.5 0 0010.5 3v1zm0-1A1.5 1.5 0 009 4.5h1a.5.5 0 01.5-.5V3z' fill='currentColor' />
        </svg>
        <span className='flex items-center space-x-1 caption'>
          {tags.map((tag, i) => {
            return (
              <span key={i}>
                <a
                  className='underline capitalize first:ml-0'
                  href={tag.href}
                  onClick={() => {
                    { /* pageView(tag.href) */ }
                  }}
                >{tag.label}
                </a>
                {tags.length > 1 && i + 1 < tags.length ? ', ' : null}
              </span>
            )
          })}
        </span>
      </div>
      <ShareControls post={post} size='sml' />
    </div>
  )
}

PostDivider.propTypes = {
  title: PropTypes.string
}

export default PostDivider
