import Link from 'next/link'
import PropTypes from 'prop-types'
import { useState } from 'react'
// import { signin } from 'next-auth/client'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { useStateMachine } from 'little-state-machine'
import updateAction from './updateAction'

const signin = () => {}

const JoinStep1 = props => {
  // const { authProviders } = props
  const {
    formState: { errors },
    register,
    handleSubmit
  } = useForm({
    mode: 'onChange'
  })
  const { actions, state } = useStateMachine({ updateAction })
  const [errorMessage, setErrorMessage] = useState('')
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] =
    useState(false)
  const [status, setStatus] = useState('Next')

  const handleEmailPreferencesChange = () => {
    setEmailNotificationsEnabled(!emailNotificationsEnabled)
  }

  const onSubmit = async data => {
    const { onNextStep } = props

    data.canEmail = !!emailNotificationsEnabled

    actions.updateAction(data)

    setStatus('Saving your details')

    try {
      const res = await fetch('/api/userCheck', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })

      if (res.status === 200) {
        setErrorMessage('An account already exists with that email address')
        setStatus('Next')
      } else {
        onNextStep('joinStep2')
      }
    } catch (error) {
      setErrorMessage(error.message)
      setStatus('Next')
    }
  }

  return (
    <>
      <img
        className='h-10 mb-4 sm:hidden'
        src='/images/scramblers-logo.svg'
        alt='Scramblers'
      />
      <div className='flex items-center justify-center w-full space-x-4'>
        <div className='flex items-center space-x-2'>
          <span className='font-light tracking-widest text-white uppercase text-[8px]'>
            Presented by
          </span>
          <a
            className='w-8 md:w-10'
            href='https://bicesterheritage.co.uk/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='w-full'
              src='/images/bicester-heritage-logo-badge.svg'
              alt='Presented by Bicester Heritage'
            />
          </a>
        </div>
        {/* <div className='flex items-center space-x-2'>
        <span className='font-light tracking-widest text-white uppercase text-[8px]'>In association with</span>
        <a className='w-20 md:w-24' href='https://www.hagerty.co.uk/' target='_blank'>
          <img className='w-full' src='/images/promo/hagerty.svg' alt='In association with Hagerty' />
        </a>
      </div> */}
      </div>
      <p className='w-full max-w-2xl mt-4 text-2xl font-light text-center text-white uppercase sm:text-3xl md:text-4xl sm:px-4'>
        <span className='italic font-bold text-accent'>
          A NEW AUTOMOTIVE MOVEMENT
        </span>{' '}
        BORN FROM THE SUCCESS OF SCRAMBLE EVENTS.
      </p>
      <div className='w-full max-w-xl px-6 py-10 pt-0 mt-10 bg-black border border-gray-800 rounded-lg shadow-lg md:px-10 bg-opacity-60'>
        {/* social join */}
        {/* hidden for launch */}
        <div className='flex flex-col space-y-3'>
          <div className='relative flex items-center justify-center flex-1 text-sm transform -translate-y-3'>
            <span className='flex items-center justify-center h-6 px-3 text-gray-300 uppercase bg-black border border-gray-800 rounded-full caption'>
              Sign up now
            </span>
          </div>
          <div className='flex flex-wrap items-center justify-center hidden space-x-2'>
            {/* {authProviders && authProviders.facebook
            ? ( */}
            <button
              className='flex-shrink-0 button bg-[#3b5998] border-none hover:bg-opacity-50 hover:bg-[#3b5998]'
              onClick={() => signin('facebook')}
            >
              <svg
                className='w-3 h-3'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7.5 14.5a7 7 0 110-14 7 7 0 010 14zm0 0v-8a2 2 0 012-2h.5m-5 4h5'
                  stroke='currentColor'
                />
              </svg>
              <span>Facebook</span>
            </button>
            {/* )
            : null} */}
            {/* {authProviders && authProviders.twitter
            ? ( */}
            <button
              className='flex-shrink-0 button bg-[#1da1f2] border-none hover:bg-opacity-50 hover:bg-[#1da1f2]'
              onClick={() => signin('twitter')}
            >
              <svg
                className='w-3 h-3'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.478 1.5l.5-.033a.5.5 0 00-.871-.301l.371.334zm-.498 2.959a.5.5 0 10-1 0h1zm-6.49.082h-.5.5zm0 .959h.5-.5zm-6.99 7V12a.5.5 0 00-.278.916L.5 12.5zm.998-11l.469-.175a.5.5 0 00-.916-.048l.447.223zm3.994 9l.354.353a.5.5 0 00-.195-.827l-.159.474zm7.224-8.027l-.37.336.18.199.265-.04-.075-.495zm1.264-.94c.051.778.003 1.25-.123 1.606-.122.345-.336.629-.723 1l.692.722c.438-.42.776-.832.974-1.388.193-.546.232-1.178.177-2.006l-.998.066zm0 3.654V4.46h-1v.728h1zm-6.99-.646V5.5h1v-.959h-1zm0 .959V6h1v-.5h-1zM10.525 1a3.539 3.539 0 00-3.537 3.541h1A2.539 2.539 0 0110.526 2V1zm2.454 4.187C12.98 9.503 9.487 13 5.18 13v1c4.86 0 8.8-3.946 8.8-8.813h-1zM1.03 1.675C1.574 3.127 3.614 6 7.49 6V5C4.174 5 2.421 2.54 1.966 1.325l-.937.35zm.021-.398C.004 3.373-.157 5.407.604 7.139c.759 1.727 2.392 3.055 4.73 3.835l.317-.948c-2.155-.72-3.518-1.892-4.132-3.29-.612-1.393-.523-3.11.427-5.013l-.895-.446zm4.087 8.87C4.536 10.75 2.726 12 .5 12v1c2.566 0 4.617-1.416 5.346-2.147l-.708-.706zm7.949-8.009A3.445 3.445 0 0010.526 1v1c.721 0 1.37.311 1.82.809l.74-.671zm-.296.83a3.513 3.513 0 002.06-1.134l-.744-.668a2.514 2.514 0 01-1.466.813l.15.989zM.222 12.916C1.863 14.01 3.583 14 5.18 14v-1c-1.63 0-3.048-.011-4.402-.916l-.556.832z'
                  fill='currentColor'
                />
              </svg>
              <span>Twitter</span>
            </button>
            {/* )
            : null} */}
            {/* {authProviders && authProviders.google
            ? ( */}
            <button
              className='flex-shrink-0 button bg-[#ea4335] border-none hover:bg-opacity-50 hover:bg-[#ea4335]'
              onClick={() => signin('google')}
            >
              <svg
                className='w-3 h-3'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
              >
                <path
                  d='M14.5 7.5h.5V7h-.5v.5zm-7 6.5A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 0A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zm0 1c1.819 0 3.463.746 4.643 1.951l.714-.7A7.479 7.479 0 007.5 0v1zM8 8h6.5V7H8v1z'
                  fill='currentColor'
                />
              </svg>
              <span>Google</span>
            </button>
            {/* )
            : null} */}
          </div>
        </div>

        <form className='mt-3' onSubmit={handleSubmit(onSubmit)}>
          {/* <div className='relative flex items-center w-full space-x-1'>
          <div className='flex-1 h-px bg-gray-800' />
          <div className='relative flex justify-center text-sm leading-5'>
            <span className='text-gray-300 uppercase caption'>Or</span>
          </div>
          <div className='flex-1 h-px bg-gray-800' />
        </div> */}

          {errorMessage && (
            <p className='p-1.5 mb-5 text-white bg-red-500 rounded-sm bodyTextSml'>
              {errorMessage}
            </p>
          )}

          <div className='grid gap-3 mt-0 md:grid-cols-1'>
            <div className='relative flex flex-col items-start w-full'>
              <label htmlFor='displayName' className='label'>
                My Name <sup>*</sup>
              </label>
              <input
                name='displayName'
                id='displayName'
                autoComplete='off'
                type='text'
                {...register('displayName', { required: true })}
                className={`input input--dark w-full ${
                  errors.displayName ? 'input--error' : ''
                }`}
                placeholder='My name'
              />
              {errors.displayName && <span className='error'>Required</span>}
            </div>
            <div className='relative form-row'>
              <div className='relative flex flex-col items-start'>
                <label htmlFor='email' className='label'>
                  My Email <sup>*</sup>
                </label>
                <input
                  name='email'
                  id='email'
                  autoComplete='off'
                  type='text'
                  {...register('email', {
                    required: true,
                    pattern: /[^@]+@[^\\.]+\..+/
                  })}
                  className={`input input--dark w-full ${
                    errors.email ? 'input--error' : ''
                  }`}
                  placeholder='me@mail.com'
                />
                {errors.email && errors.email.type === 'required' && (
                  <span className='error'>Required</span>
                )}
                {errors.email && errors.email.type === 'pattern' && (
                  <span className='error'>Invalid</span>
                )}
              </div>
              <div className='relative flex flex-col items-start'>
                <label htmlFor='password' className='label'>
                  Password <sup>*</sup>
                </label>
                <input
                  name='password'
                  id='password'
                  autoComplete='off'
                  type='password'
                  {...register('password', {
                    required: true,
                    minLength: { value: 8 }
                  })}
                  className={`input input--dark w-full ${
                    errors.password ? 'input--error' : ''
                  }`}
                  placeholder='&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;'
                />
                {errors.password && errors.password.type === 'required' && (
                  <span className='error'>Required</span>
                )}
                {errors.password && errors.password.type === 'minLength' && (
                  <span className='error'>Min 8 characters</span>
                )}
              </div>
            </div>
          </div>

          <div
            onClick={() => {}}
            className='relative flex flex-col items-start mt-5'
          >
            <span className='text-left label'>
              Yes, sign me up to the Scramblers newsletter!
            </span>
            <div className='flex flex-row items-center justify-between flex-grow w-full'>
              <span className='pr-5 mt-1 font-light text-left text-white bodyTextSml'>
                I want the latest Scramblers news and know you won’t spam me.
              </span>
              <Switch
                checked={false}
                onChange={handleEmailPreferencesChange}
                className={`${
                  emailNotificationsEnabled ? 'bg-accent' : 'bg-gray-500'
                } relative inline-flex items-center h-6 transition-colors rounded-full w-11 flex-shrink-0`}
              >
                <span className='sr-only'>Enable notifications</span>
                <span
                  className={`${
                    emailNotificationsEnabled
                      ? 'translate-x-6'
                      : 'translate-x-1'
                  } inline-block w-4 h-4 transform bg-white transition-all rounded-full`}
                />
              </Switch>
            </div>
          </div>

          <p className='mt-10 text-xs font-light text-gray-500'>
            By creating an account, you agree to Scramblers{' '}
            <a
              className='underline'
              href='https://bicesterheritage.co.uk/privacy-policy/'
              rel='noreferrer'
              target='_blank'
            >
              Privacy Policy
            </a>
            .
            {/* and <a className='underline' href='#' target='_blank'>Terms of Use</a>. */}
          </p>
          {/* <button onClick={(event) => this.handleFormSwitch('joinStepTwo')} className='w-full mt-5 button button--dark'>Join Us</button> */}

          <input
            type='password'
            autoComplete='off'
            id='disable-pwd-mgr-1'
            style={{ display: 'none' }}
            value='stop-pwd-mgr-1'
            readOnly
          />
          <input
            type='password'
            autoComplete='off'
            id='disable-pwd-mgr-2'
            style={{ display: 'none' }}
            value='stop-pwd-mgr-2'
            readOnly
          />

          <button
            type='submit'
            className='w-full mt-5 space-x-1 button button--light'
          >
            <span>{status}</span>
            {status === 'Saving your details' ? (
              <span className='animate-spin'>
                <svg
                  className='w-3 h-3'
                  viewBox='0 0 15 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8 1V.5H7V1h1zM7 4.5V5h1v-.5H7zm1 6V10H7v.5h1zM7 14v.5h1V14H7zM4.5 7.995H5v-1h-.5v1zm-3.5-1H.5v1H1v-1zM14 8h.5V7H14v1zm-3.5-1.005H10v1h.5v-1zM7 1v3.5h1V1H7zm0 9.5V14h1v-3.5H7zM4.5 6.995H1v1h3.5v-1zM14 7l-3.5-.005v1L14 8V7zM2.147 2.854l3 3 .708-.708-3-3-.708.708zm10-.708l-3 3 .708.708 3-3-.708-.708zM2.854 12.854l3-3-.708-.708-3 3 .708.708zm6.292-3l3 3 .708-.708-3-3-.708.708z'
                    fill='currentColor'
                  />
                </svg>
              </span>
            ) : (
              <span>&rarr;</span>
            )}
          </button>
        </form>
      </div>
      <p className='mt-2 text-xs font-light text-white'>
        Already a member?{' '}
        <Link
          href={`/login?returnUrl=${props.returnUrl}`}
          className='underline'
        >
          Log in here
        </Link>
        .
      </p>
    </>
  )
}

JoinStep1.propTypes = {
  authProviders: PropTypes.any,
  onNextStep: PropTypes.func
}

export default JoinStep1
