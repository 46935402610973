import React from 'react'
import Audio from '../Sections/Audio'
const url = require('@/lib/url')()

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 75, w: 500 })
}

const AudioComponent = ({ data, post }) => {
  const imageUrl = (data.image && data.image[0]) ? getImageUrl(data.image[0]) : ''

  return (
    <Audio
      title={data.title}
      body={data.body}
      allowDownload={data.allowDownload}
      media={data.media}
      url={data.url}
      post={post}
      image={imageUrl}
    />
  )
}

export default AudioComponent
