import PropTypes from 'prop-types'
import React from 'react'
import marked from 'marked'

const getMarkdownText = input => {
  const rawMarkup = marked(input)
  return { __html: rawMarkup }
}

const PullQuote = ({ author, post, text, fontSize, fontType, link, linkText }) => {
  if (link && link.indexOf('http') < 0) {
    link = `https://${link}`
  }

  const isExternalLink = link && new URL(link).origin !== window.location.origin

  return (
    <div className='flex flex-col w-11/12 mt-10'>
      <cite className='text-primary'>

        <span
          className='text-3xl italic font-normal text-left uppercase' dangerouslySetInnerHTML={getMarkdownText('"' + text + '"')} />

        {/* author */}
        {author
          ? (
            <div className='flex items-center mt-3 space-x-2 text-black'>
              {/* <img className='object-cover rounded-full w-7 h-7' src='https://images.unsplash.com/photo-1528892952291-009c663ce843?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=596&q=80' /> */}
              <div className='flex flex-col not-italic font-thin leading-tight text-xxs lg:text-sm'>
                <span className=''>{author}</span>
                {
              link
                ? (
                  <div className='flex items-center'>
                    <span>Via <a href={link} target={`${isExternalLink ? '_blank' : '_self'}`} className='italic font-normal underline'>{linkText}</a></span>
                    {
                    isExternalLink
                      ? (
                        <svg className='ml-0.5 md:ml-0.5 w-2.5 h-2.5 md:w-3.5 md:h-3.5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14' />
                        </svg>
                        )
                      : null
                      }
                  </div>
                  )
                : null
              }
              </div>
            </div>
            )
          : null}
      </cite>
    </div>
  )
}

PullQuote.propTypes = {
  author: PropTypes.string,
  post: PropTypes.object,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string
}

export default PullQuote
