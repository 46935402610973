import FacebookIcon from '../Icons/Facebook'
import {
  getMembershipCheckout,
  getRecurringPrices,
  tierBenefits
} from '@/lib/memberships'
import InstagramIcon from '../Icons/Instagram'
import { InteractionContext } from '@/contexts/InteractionContext'
import { useContext, useState } from 'react'
import { Switch, Transition } from '@headlessui/react'
import TwitterIcon from '../Icons/Twitter'
import ModalMembershipOption from '../Profile/ModalMembershipOption'
import { useForm } from 'react-hook-form'

const SignUpModal = props => {
  const [currentStep, setCurrentStep] = useState(1)
  const [membershipType, setMembershipType] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] =
    useState(false)
  const [status, setStatus] = useState(null)
  const { setShowSignUpModal } = useContext(InteractionContext)

  const {
    formState: { errors, isValid },
    register,
    handleSubmit
  } = useForm({
    mode: 'onChange'
  })

  const MEMBERSHIP_TYPES = {
    standard: {
      active: true,
      title: 'Standard',
      key: 'standard',
      price: 'FREE'
    },
    tier1_old: {
      active: false,
      title: 'Pegasus',
      key: 'tier1',
      price: '£54',
      priceId: getRecurringPrices().tier1
    },
    tier2: {
      active: false,
      title: 'Merlin',
      key: 'tier2',
      price: '£349',
      priceId: getRecurringPrices().tier2
    },
    tier1: {
      active: true,
      title: 'Pegasus',
      // key: 'paid',
      key: 'tier1',
      price: '£69',
      // priceId: getRecurringPrices().paid
      priceId: getRecurringPrices().tier1
    }
  }

  const handleEmailPreferencesChange = () => {
    setEmailNotificationsEnabled(!emailNotificationsEnabled)
  }

  const handleMembershipTypeChange = type => {
    setMembershipType(MEMBERSHIP_TYPES[type])
  }

  const onSubmit = async data => {
    data.canEmail = !!emailNotificationsEnabled

    setErrorMessage('')
    setStatus('Saving your details')

    try {
      // Sign user up.
      const signUpResponse = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Application-ID': 'web',
          Authorization: `Bearer ${props.token}`
        },
        body: JSON.stringify(data)
      })

      if (signUpResponse.status === 201) {
        // Successful sign up.
        const response = await signUpResponse.json()
        const { user } = response

        if (membershipType.key !== 'standard') {
          setStatus('Preparing checkout...')

          await getMembershipCheckout({
            priceId: membershipType.priceId,
            user
          })
        } else {
          // Redirect to home.
          window.location.href = '/'
        }
      } else if (signUpResponse.status === 409) {
        setErrorMessage(
          'An account already exists with that email address, please <a class="underline" href="/login">log in</a> first.'
        )
        // setStatus('Proceed to payment')
        setStatus('Next')
      }
    } catch (error) {
      setErrorMessage(error.message)
      setStatus('Next')
    }
  }

  return (
    <div id='signUpModal' className='h-full overflow-y-scroll'>
      {/* content */}
      <div className='container flex flex-col items-center justify-start min-h-full pt-6 overflow-y-scroll lg:h-full xl:pb-6 lg:pt-8 lg:pb-8'>
        {/* logo row */}
        <div className='flex items-center justify-between w-full'>
          {/* <div className='flex-1' /> */}
          <img
            className='h-9 md:h-10 lg:h-10'
            src='/images/scramblers-logo.svg'
            alt='Scramblers'
          />
          {/* close button */}
          <div className='flex justify-end flex-1'>
            <button
              onClick={() => setShowSignUpModal(false)}
              className='button--close group'
            >
              <svg
                className='block w-3 h-3 transition-all duration-300 ease-in transform group-hover:rotate-180'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor' />
              </svg>
            </button>
          </div>
        </div>

        <div className='relative z-0 flex items-center justify-center w-full h-5 mt-6'>
          <div className='absolute top-0 left-0 z-0 flex items-center w-full h-full'>
            <div className='w-full h-px bg-gray-800' />
          </div>
          {/* <div className='z-10 flex items-center justify-center w-full h-full'>
            <span className='inline-block px-2 text-xs leading-none tracking-wider text-white uppercase bg-darkGrey'>
              {currentStep === 1
                ? '1. Choose your membership type'
                : '2. Complete your registration'}
            </span>
          </div> */}
        </div>

        {/* STEP 1 - SELECT MEMBERSHIP TYPE */}
        {currentStep === 1 && (
          <div className='flex flex-col items-start justify-start flex-1 w-full gap-8 pt-10 pb-5 lg:flex-row'>
            {/* col 1 - intro */}
            <div className='w-full lg:w-1/3 lg:h-full'>
              <span className='text-sm uppercase text-accent'>Step 1 of 2</span>
              <h3
                className='text-xl font-normal text-white lg:text-2xl'
                style={{ textWrap: 'balance' }}
              >
                Choose your membership type
              </h3>
              <p className='mt-3 text-base font-light text-gray-400 md:text-sm xl:pr-10'>
                It's free to join Scramblers. We'll take you behind the scenes
                of the specialists of Bicester Heritage on a daily basis, with
                news and features from around the site. Upgrade to Pegasus and
                you'll get even closer: join us for member meets, exclusive
                events, and even grab a free coffee each time. It pays to be a
                Scrambler.
              </p>
            </div>

            {/* col 2 - free tier */}
            <div className='w-full lg:w-1/3 lg:h-full'>
              <ModalMembershipOption
                title='Standard'
                price='FREE'
                description='The entry level Scramblers package:'
                features={tierBenefits.standard}
                onSelect={() => {
                  setCurrentStep(2)
                  handleMembershipTypeChange('standard')
                }}
              />
            </div>

            {/* col 3 - paid tier */}
            <div className='w-full lg:w-1/3 lg:h-full'>
              <ModalMembershipOption
                title='Pegasus'
                price='£69'
                priceDiscounted='£69'
                description='All standard tier benefits plus:'
                features={tierBenefits.tier1}
                onSelect={() => {
                  setCurrentStep(2)
                  handleMembershipTypeChange('tier1')
                }}
              />
            </div>

            {/* col 3 - login/register - hidden on mobile  */}
            {/* <div className='w-full lg:w-1/3 lg:h-full'>
              <ModalMembershipOption
                title='Merlin'
                price='£349'
                priceDiscounted={null}
                description='All standard tier benefits plus:'
                features={[
                  '4x Scramble tickets',
                  'Car photoshoot at Bicester Heritage',
                  'Free copy of Scramblers Annual',
                  'Free "Evening with" ticket',
                  'Free entry to every Assembly',
                  'Priority access to Scramble and Flywheel tickets',
                  'Dedicated parking area',
                  'Scramble display pass priority',
                  'Exclusive events',
                  '10% off subscriptions to The Road Rat',
                  '10% off £25 or more at Sky Wave Gin',
                  'Free gift with any in-store purchase at Motor Spirit',
                  '£5 Starter Motor donation',
                  'Membership tag & pack'
                ]}
                onSelect={() => {
                  setCurrentStep(2)
                  handleMembershipTypeChange('tier2')
                }}
              />
            </div> */}
          </div>
        )}

        {/* STEP 2 - COMPLETE REGISTRATION */}
        {currentStep === 2 && (
          <div className='flex flex-col items-start justify-start flex-1 w-full pt-10 pb-5 lg:flex-row'>
            <div className='w-full lg:w-1/3 lg:h-full xl:pr-20'>
              <span className='text-sm uppercase text-accent'>Step 2 of 2</span>
              <h3
                className='text-xl font-normal text-white lg:text-2xl'
                style={{ textWrap: 'balance' }}
              >
                Complete your registration
              </h3>
              <p className='mt-3 text-base font-light text-gray-400 md:text-sm !text-red-500 hidden'>
                Donec sed odio dui. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nullam quis risus eget urna mollis ornare vel
                eu leo.
              </p>
            </div>
            <div className='w-full h-full mt-10 xl:pr-24 lg:w-2/3 lg:pl-16 xl:pl-8 lg:mt-0'>
              <div className='w-full p-5 bg-black bg-opacity-25 rounded-md'>
                <form className='mt-3' onSubmit={handleSubmit(onSubmit)}>
                  {errorMessage && (
                    <p
                      className='p-1.5 mb-5 text-white bg-red-500 rounded-sm bodyTextSml'
                      dangerouslySetInnerHTML={{ __html: errorMessage }}
                    />
                  )}

                  <div className='grid gap-5 mt-0 md:grid-cols-1'>
                    <div className='relative flex flex-col items-start w-full'>
                      <label htmlFor='displayName' className='label'>
                        My Name <sup>*</sup>
                      </label>
                      <input
                        name='displayName'
                        id='displayName'
                        autoComplete='off'
                        type='text'
                        {...register('displayName', { required: true })}
                        className={`input input--transparent w-full ${
                          errors.displayName ? 'input--error' : ''
                        }`}
                        placeholder='My name'
                      />
                      {errors.displayName && (
                        <span className='error'>Required</span>
                      )}
                    </div>
                    <div className='relative form-row'>
                      <div className='relative flex flex-col items-start'>
                        <label htmlFor='email' className='label'>
                          My Email <sup>*</sup>
                        </label>
                        <input
                          name='email'
                          id='email'
                          autoComplete='off'
                          type='text'
                          {...register('email', {
                            required: true,
                            pattern: /[^@]+@[^\\.]+\..+/
                          })}
                          className={`input input--transparent w-full ${
                            errors.email ? 'input--error' : ''
                          }`}
                          placeholder='me@mail.com'
                        />
                        {errors.email && errors.email.type === 'required' && (
                          <span className='error'>Required</span>
                        )}
                        {errors.email && errors.email.type === 'pattern' && (
                          <span className='error'>Invalid</span>
                        )}
                      </div>
                      <div className='relative flex flex-col items-start'>
                        <label htmlFor='password' className='label'>
                          Password <sup>*</sup>
                        </label>
                        <input
                          name='password'
                          id='password'
                          autoComplete='off'
                          type='password'
                          {...register('password', {
                            required: true,
                            minLength: { value: 8 }
                          })}
                          className={`input input--transparent w-full ${
                            errors.password ? 'input--error' : ''
                          }`}
                          placeholder='&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;'
                        />
                        {errors.password &&
                          errors.password.type === 'required' && (
                            <span className='error'>Required</span>
                          )}
                        {errors.password &&
                          errors.password.type === 'minLength' && (
                            <span className='error'>Min 8 characters</span>
                          )}
                      </div>
                    </div>
                    <div className='relative flex flex-col items-start w-full'>
                      <label htmlFor='displayName' className='label'>
                        Membership type
                      </label>
                      <select
                        defaultValue={membershipType.key}
                        className='w-full input input--transparent'
                        onChange={event => {
                          setMembershipType(
                            MEMBERSHIP_TYPES[event.target.value]
                          )
                        }}
                      >
                        {Object.keys(MEMBERSHIP_TYPES)
                          .map((key, index) => {
                            const membershipType = MEMBERSHIP_TYPES[key]

                            if (membershipType.active) {
                              return (
                                <option key={index} value={membershipType.key}>
                                  {membershipType.title} ({membershipType.price}
                                  )
                                </option>
                              )
                            } else {
                              return null
                            }
                          })
                          .filter(Boolean)}
                        {/*   Standard (FREE)
                        </option>
                        <option
                          value='tier1'
                          // selected={membershipType.title === 'Pegasus'}
                        >
                          Pegasus (£54)
                        </option>
                        <option
                          value='tier2'
                          // selected={membershipType.title === 'Merlin'}
                        >
                          Merlin (£349)
                        </option> */}
                      </select>
                    </div>
                  </div>

                  <div
                    onClick={() => {}}
                    className='relative flex flex-col items-start mt-8'
                  >
                    <span className='text-left label'>
                      Yes, sign me up to the Scramblers newsletter!
                    </span>
                    <div className='flex flex-row items-center justify-between flex-grow w-full'>
                      <span className='pr-5 mt-1 font-light text-left text-gray-400 bodyTextSml'>
                        I want the latest Scramblers news and know you won’t
                        spam me.
                      </span>
                      <Switch
                        checked={false}
                        onChange={handleEmailPreferencesChange}
                        className={`${
                          emailNotificationsEnabled
                            ? 'bg-accent'
                            : 'bg-gray-500'
                        } relative inline-flex items-center h-6 transition-colors rounded-full w-11 flex-shrink-0`}
                      >
                        <span className='sr-only'>Enable notifications</span>
                        <span
                          className={`${
                            emailNotificationsEnabled
                              ? 'translate-x-6'
                              : 'translate-x-1'
                          } inline-block w-4 h-4 transform bg-white transition-all rounded-full`}
                        />
                      </Switch>
                    </div>
                  </div>

                  <p className='mt-8 text-xs font-light text-gray-400'>
                    By creating an account, you agree to Scramblers{' '}
                    <a
                      className='underline'
                      href='https://bicesterheritage.co.uk/privacy-policy/'
                      rel='noreferrer'
                      target='_blank'
                    >
                      Privacy Policy
                    </a>
                    .
                    {/* and <a className='underline' href='#' target='_blank'>Terms of Use</a>. */}
                  </p>
                  {/* <button onClick={(event) => this.handleFormSwitch('joinStepTwo')} className='w-full mt-5 button button--dark'>Join Us</button> */}

                  <input
                    type='password'
                    autoComplete='off'
                    id='disable-pwd-mgr-1'
                    style={{ display: 'none' }}
                    value='stop-pwd-mgr-1'
                    readOnly
                  />
                  <input
                    type='password'
                    autoComplete='off'
                    id='disable-pwd-mgr-2'
                    style={{ display: 'none' }}
                    value='stop-pwd-mgr-2'
                    readOnly
                  />

                  <button
                    type='submit'
                    className='w-full mt-6 space-x-1 button button--light'
                    disabled={!isValid}
                  >
                    <span>
                      {membershipType.key === 'standard' &&
                        'Complete registration'}
                      {membershipType.key !== 'standard' &&
                        status !== 'Preparing checkout...' &&
                        'Proceed to payment'}
                      {status === 'Preparing checkout...' && status}
                    </span>
                    {status === 'Saving your details' ? (
                      <span className='animate-spin'>
                        <svg
                          className='w-3 h-3'
                          viewBox='0 0 15 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 1V.5H7V1h1zM7 4.5V5h1v-.5H7zm1 6V10H7v.5h1zM7 14v.5h1V14H7zM4.5 7.995H5v-1h-.5v1zm-3.5-1H.5v1H1v-1zM14 8h.5V7H14v1zm-3.5-1.005H10v1h.5v-1zM7 1v3.5h1V1H7zm0 9.5V14h1v-3.5H7zM4.5 6.995H1v1h3.5v-1zM14 7l-3.5-.005v1L14 8V7zM2.147 2.854l3 3 .708-.708-3-3-.708.708zm10-.708l-3 3 .708.708 3-3-.708-.708zM2.854 12.854l3-3-.708-.708-3 3 .708.708zm6.292-3l3 3 .708-.708-3-3-.708.708z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    ) : (
                      <span>&rarr;</span>
                    )}
                  </button>
                  {membershipType.title !== 'Standard' && (
                    <p className='w-full mt-4 text-xs font-light text-center text-gray-400'>
                      You will be taken to our secure form to complete payment
                      of {membershipType.price} for your {membershipType.title}{' '}
                      membership.
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}

        {/* footer */}
        <div className='z-30 flex flex-col items-center w-full pt-5 pb-6 mt-16 text-white border-t border-gray-800 lg:mt-5 bg-darkGrey md:justify-between md:flex-row text-navigation'>
          <div className='flex items-center justify-start order-2 mt-5 space-x-4 md:mt-0 md:order-1'>
            <a
              href='/post/so-tell-me-about-scramblers'
              className='hover:text-accent'
            >
              About
            </a>
            <a
              href='https://bicesterheritage.co.uk/privacy-policy'
              target='_blank'
              rel='noreferrer'
              className='hover:text-accent'
            >
              Privacy policy
            </a>
            <a
              href='https://bicesterheritage.co.uk/cookies-statement/'
              target='_blank'
              rel='noreferrer'
              className='hover:text-accent'
            >
              Cookies statement
            </a>
            <a
              href='mailto:hello@wearescramblers.com'
              target='_blank'
              rel='noreferrer'
              className='hover:text-accent'
            >
              Contact Us
            </a>
          </div>
          <div className='flex items-center justify-start order-1 space-x-4 md:order-2'>
            <a
              className='hover:text-accent'
              href='https://facebook.com/wearescramblers'
              target='_blank'
              rel='noreferrer'
            >
              <FacebookIcon className='w-5 h-5' />
            </a>
            <a
              className='hover:text-accent'
              href='https://twitter.com/wearescramblers'
              target='_blank'
              rel='noreferrer'
            >
              <TwitterIcon className='w-5 h-5' />
            </a>
            <a
              className='hover:text-accent'
              href='https://instagram.com/wearescramblers'
              target='_blank'
              rel='noreferrer'
            >
              <InstagramIcon className='w-5 h-5' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpModal
