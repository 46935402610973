import PropTypes from 'prop-types'
import React from 'react'
import ShareControls from '../ShareControls'
import marked from 'marked'
const url = require('@/lib/url')()

const getMarkdownText = input => {
  const rawMarkup = marked(input)
  return { __html: rawMarkup }
}

const getAuthorImage = author => {
  return url.getMediaUrl(author.image[0].path, { q: 80 })
}

const Subtitle = ({ bold, fontType, italic, post, subtitle, showBorder, width }) => (
  <div className='w-full mt-4 componentSubTitle'>
    <div className={`${showBorder ? 'pb-6 border-b border-gray-400' : null}`}>

      {/* SUBTITLE */}
      <h3 className={`subtitle md:pr-5 ${italic ? 'subtitle--italic' : null} subtitle--sans`} dangerouslySetInnerHTML={getMarkdownText(subtitle)} />

      {/* AUTHOR / SHARE */}
      <div className='flex items-center justify-between mt-6'>

        {/* author */}
        {
          (post.author && post.author[0] && post.author[0].firstName) || post.contributor
            ? (
              <div className='flex items-center justify-start space-x-2 font-light bodyTextSml'>
                {/* {post.author && post.author[0] && post.author[0].image
              ? <img src={getAuthorImage(post.author[0])} alt='' className='object-cover object-top overflow-hidden border border-gray-700 rounded-full w-7 h-7' />
              : null
            } */}
                <div className='flex items-center space-x-1 caption'>
                  <span className=''>By</span>
                  <span className=''>
                    {
                      post.contributor
                        ? post.contributor
                        : post.author[0].firstName + ' ' + post.author[0].lastName
                    }
                  </span>
                </div>
              </div>
              )
            : null
           }
      </div>
    </div>
  </div>
)

Subtitle.propTypes = {
  bold: PropTypes.bool,
  fontType: PropTypes.string,
  italic: PropTypes.bool,
  post: PropTypes.object,
  subtitle: PropTypes.string,
  showBorder: PropTypes.bool
}

export default Subtitle
