import Draggable, {DraggableCore} from 'react-draggable'
import PropTypes from 'prop-types'
import React from 'react'
import ReactHowler from 'react-howler'
import ReactInterval from 'react-interval'

class Audio extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0,
      seekTime: '0:00',
      handlePos: '0'
    }

    this.getDuration = this.getDuration.bind(this)
    this.getSeekPos = this.getSeekPos.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleOnLoad = this.handleOnLoad.bind(this)
    this.handleOnEnd = this.handleOnEnd.bind(this)
    this.handleOnPlay = this.handleOnPlay.bind(this)
    this.handleStop = this.handleStop.bind(this)
    this.renderSeekPos = this.renderSeekPos.bind(this)
    this.handleLoopToggle = this.handleLoopToggle.bind(this)
    this.handleMuteToggle = this.handleMuteToggle.bind(this)
    this.handleDragStop = this.handleDragStop.bind(this)
    this.handleScrubClick = this.handleScrubClick.bind(this)
  }

  getDuration () {
    if (this.state.duration) {
      return new Date(this.state.duration * 1000).toISOString().substr(11, 8)
    }

    return '0:00'
  }

  getSeekPos () {
    if (this.state.seek) {
      this.setState({
        seekTime: new Date(this.state.seek * 1000).toISOString().substr(11, 8),
        handlePos: `${(this.state.seek / this.state.duration) * 100}%`
      })
    }
    
    // return '0:00'
  }

  handleToggle () {
    this.setState({
      playing: !this.state.playing
    })
  }

  handleOnLoad () {
    this.setState({
      loaded: true,
      duration: this.player.duration()
    })
  }

  handleOnPlay () {
    this.setState({
      playing: true
    })
    this.renderSeekPos()
  }

  handleOnEnd () {
    this.setState({
      playing: false
    })
  }

  handleStop () {
    this.player.stop()
    this.setState({
      playing: false // Need to update our local state so we don't immediately invoke autoplay
    })
    this.renderSeekPos()
  }

  handleLoopToggle () {
    this.setState({
      loop: !this.state.loop
    })
  }

  handleMuteToggle () {
    this.setState({
      mute: !this.state.mute
    })
  }

  renderSeekPos () {
    console.log('hit 1')
    this.setState({
      seek: this.player.seek()
    })
    if (this.state.playing) {
      this.getSeekPos()
    }
  }

  handleDragStop () {
    console.log('drag stop')
  }

  handleScrubClick (event) {
    console.log('scrub click')
    console.log('target', event.target)
    const scrubberLeftPos = event.target.offsetLeft
    const scrubberWidth = event.target.clientWidth
    const clickX = event.clientX - scrubberLeftPos
    const percentToScrub = (clickX / scrubberWidth) * 100
    if (this.state.playing) {
      const scrubSeconds = (this.state.duration / 100) * percentToScrub
      this.player.seek(scrubSeconds)
    }
  }

  render () {
    const { image, media, body, title } = this.props
    return (
      <>
        <div className='relative grid grid-cols-12 my-10 componentAudio container'>
          <div className='default-grid-cols'>
            <ReactHowler
              src='http://goldfirestudios.com/proj/howlerjs/sound.ogg'
              // src='{[media[0].url]}'
              playing={this.state.playing}
              onLoad={this.handleOnLoad}
              onPlay={this.handleOnPlay}
              onEnd={this.handleOnEnd}
              loop={this.state.loop}
              mute={this.state.mute}
              volume={this.state.volume}
              ref={(ref) => (this.player = ref)}
            />
            <ReactInterval
              timeout={500}
              enabled={true}
              callback={() => this.renderSeekPos()}
            />
            <div className='flex items-center w-full p-4 space-x-4 rounded-sm bg-gray-50'>

              {/* image / play button */}
              <div
                onClick={this.handleToggle}
                className='relative flex items-center justify-center flex-shrink-0 w-16 h-16 overflow-hidden transition border-2 border-transparent rounded-full cursor-pointer hover:border-gray-400'
              >
                <img className='object-cover w-full h-full' src={image} />
                {!this.state.playing
                  ? <svg className='absolute w-6 h-6 text-white' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M4.79 2.093A.5.5 0 004 2.5v10a.5.5 0 00.79.407l7-5a.5.5 0 000-.814l-7-5z' fill='currentColor' />
                  </svg>
                  :
                  <svg className='absolute w-6 h-6 text-white' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M5.5 3v9m4-9v9' stroke='currentColor' />
                  </svg>
                }
              </div>

              {/* scrubber & time */}
              <div className='flex items-center flex-1 space-x-2'>

                {/* scrubber */}
                <div onClick={this.handleScrubClick} className='relative flex items-center flex-1 h-2.5 cursor-pointer'>
                  <span className='w-full h-px bg-black pointer-events-none' />
                  <Draggable
                    axis='x'
                    handle='.handle'
                    defaultPosition={{ x: 0, y: 0 }}
                    scale={1}
                    bounds='parent'
                    // onStart={this.handleStart}
                    // onDrag={this.handleDrag}
                    onStop={this.handleDragStop}
                  >
                    <div
                      style={{ left: `${this.state.handlePos}` }}
                      className='handle cursor-pointer w-2.5 h-2.5 bg-black absolute rounded-full transition-all duration-500 ease-linear'
                    />
                  </Draggable>
                </div>

                {/* time */}
                <div className='flex items-center space-x-1.5 caption'>
                  <span>{this.state.seekTime}</span>
                  <span className='opacity-25'>/</span>
                  <span>{this.getDuration()}</span>
                </div>

              </div>
            </div>

            {/* caption */}
            <p className='w-full mt-1 text-center caption'>
              <span className='text-black'>LISTEN:</span> {body}
            </p>

          </div>
        </div>
      </>
    )
  }
}

Audio.propTypes = {
  allowDownload: PropTypes.bool,
  body: PropTypes.string,
  image: PropTypes.string,
  media: PropTypes.object,
  post: PropTypes.objects,
  title: PropTypes.string
}

export default Audio
