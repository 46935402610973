// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (title, path, isPost) => {
  if (typeof window === 'undefined') {
    return
  }

  if (window.gtag) {
    window.gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
      page_path: isPost ? `/post/${path}` : path,
      page_title: title
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (typeof window === 'undefined') {
    return
  }

  const options = {}

  if (category) {
    options.event_category = category
  }

  if (label) {
    options.event_label = label
  }

  if (value) {
    options.value = value
  }

  if (window.gtag) {
    window.gtag('event', action, options)
  }
}
