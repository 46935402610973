import * as gtag from '@/lib/gtag'
import { InteractionContext } from '@/contexts/InteractionContext'
import MembersOnlyLayer from '../../MembersOnlyLayer'
import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react'
import scrollIntoView from 'scroll-into-view'
import { Waypoint } from 'react-waypoint'
import SubscribeCTA from '../../Layout/SubscribeCTA'
import Title from '../../Sections/Title'
import { Transition } from '@headlessui/react'
import Subtitle from '../../Sections/Subtitle'
import marked from 'marked'
const url = require('@/lib/url')()

const getMarkdownText = input => {
  const rawMarkup = marked(input)
  return { __html: rawMarkup }
}

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 70, w: 800 })
}

const getThumbnail = image => {
  return url.getMediaUrl(image.path, { w: 300, q: 70 })
}

const Article = ({ author, content, image, primaryImage, images, imageMetadata, post, title }) => {
  const { setExpandedPost } = useContext(InteractionContext)

  const handleArticleExpansion = () => {
    setExpandedPost(post)
  }

  // determine whether article can be viewed
  const { userIsMember, subscriberOnly } = post
  let canView = true
  if (subscriberOnly && !userIsMember) {
    canView = false
  }

  return (
    <div
      id='grid-article'
      className={`${canView ? 'cursor-pointer' : ''}`}
      // on click, only show the expanded post if user is a paid member
      onClick={
        canView ? handleArticleExpansion : null
      }
    >
      {subscriberOnly &&
        (
          <MembersOnlyLayer
            type='article'
            title={title}
            primaryImage={primaryImage ? getImageUrl(primaryImage) : null}
            memberCTA='Read now'
            nonMemberCTA='Upgrade to access'
            userIsMember={userIsMember}
          />
        )}

      {/* image preview area */}
      <div className='article-image'>

        {/* wing */}
        <div className='text-white wing'>
          <div className='w-3 h-full bg-white' />
          <svg className='block h-3 -mx-px' viewBox='0 0 93 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M93 0H92.6963C92.6963 0 68.7686 0.337891 59.9053 4.32568C54.8066 6.61963 52.0254 11.2734 49.1289 16.6475C44.2627 7.27539 39.9756 4.32568 34.7607 2.62158C27.1562 0.13623 0 0 0 0V17H93V0Z' fill='currentColor' />
          </svg>
          <div className='flex-1 w-3 h-full bg-white' />
        </div>

        {/* image */}
        <img className='preview-image' src={primaryImage ? getImageUrl(primaryImage) : null} />
      </div>

      {/* title / subtitle */}
      <div className='relative article-detail'>
        <div className='article-gradient' />
        <div className='relative z-10 w-full article-detail__titles'>
          {/* title */}
          <h1 className='title--sans title--sml' dangerouslySetInnerHTML={getMarkdownText(title)} />

          {/* subtitle */}
          {content && <p className='mt-2 text-base font-light leading-tight article-subtitle' dangerouslySetInnerHTML={getMarkdownText(content)} />}

          {/* cta */}
          {/* <div className='btn-read-article'>
            <button className='button button--black button--small'>
              Read Article
            </button>
          </div> */}
          {/* author */}
          {
            post.author
              ? (
                <div className='flex items-center mt-3 space-x-2'>
                  {/* <img className='object-cover rounded-full w-7 h-7' src={post.author[0].image[0].url} /> */}
                  <span className='caption'>By {post.author[0].firstName} {post.author[0].lastName}</span>
                </div>
                )
              : null
          }
        </div>
      </div>
    </div>
  )
}

Article.propTypes = {
  post: PropTypes.object
}

export default Article
