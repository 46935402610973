const renewalPeriods = require('./renewalPeriods')

export const tierBenefits = {
  standard: [
    'Access to news and web content',
    'Notification of Scramble tickets going on sale'
  ],
  tier1: [
    'Scramble and save: get 10% off your ticket',
    'Buy your Scramble passes a week before anyone else',
    'Attend every Assembly – for free',
    'Book up to four additional Assembly tickets',
    'Grab a coffee on us at at every Scramble and Assembly',
    'Take a break in the exclusive Orchard Enclosure',
    'Be part of it: join the member-only display ballot',
    'Beat the queues with priority parking at the Scramble',
    'Meet your heroes with member-exclusive events',
    'Spend and save at selected specialists and partners',
    'Savour the moment with your event photos',
    "We'll donate £5 to StarterMotor on your behalf",
    'Receive an exclusive member pack and tag'
  ]
}

/**
 * Cancel a user's membership.
 * @param {Object} user - the user object.
 * @returns {Object} the updated subscription object, or null if the cancellation fails.
 */
export const cancelMembership = async user => {
  // Get the user's active membership.
  const activeMembership = getActiveMembership(user)

  const res = await fetch('/api/cancelMembership', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      cancel: true,
      subscriptionId: activeMembership.stripeSubscriptionId
    })
  })

  if (res.status === 200) {
    const { subscription } = await res.json()

    return subscription
  }

  return null
}

/**
 * Get the Stripe subscription associated with a user's membership.
 * @param {Object} user - the user object.
 * @returns {String} the subscription, or null if the user has no active membership.
 */
export const getStripeSubscription = async user => {
  const activeMembership = getActiveMembership(user)

  if (!activeMembership) {
    return null
  }

  const res = await fetch(
    `/api/getStripeSubscription?subscriptionId=${activeMembership.stripeSubscriptionId}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  )

  if (res.status === 200) {
    const { subscription } = await res.json()

    return subscription
  }

  return null
}

/**
 * Get and load the Stripe checkout URL for a membership payment.
 *
 * @param {Object} options - the options object.
 * @param {String} options.discountId (optional) - the Stripe coupon ID to apply to the checkout.
 * @param {Boolean} options.isRenewing (optional) - true if the user is renewing their membership, otherwise false.
 * @param {Boolean} options.isSwitching (optional) - true if the user is switching their membership, otherwise false.
 * @param {Boolean} options.isUpgrading (optional) - true if the user is upgrading their membership, otherwise false.
 * @param {String} options.priceId - the Stripe price ID for the membership.
 * @param {Object} options.user - the user object.
 */
export const getMembershipCheckout = async ({
  discountId = null,
  isRenewing = false,
  isSwitching = false,
  isUpgrading = false,
  priceId,
  user
}) => {
  const requestBody = {
    discountId,
    isRenewing,
    isSwitching,
    isUpgrading,
    priceId,
    user
  }

  const res = await fetch('/api/getSubscriptionCheckout', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody)
  })

  if (res.status === 200) {
    const { checkoutUrl } = await res.json()

    // trackEvent({
    //   action: 'begin_checkout',
    //   data: shopifyUtils.getEventData(items)
    // })

    window.location.replace(checkoutUrl)
  }
}

export const getRecurringPrices = () => {
  return {
    tier1: process.env.NEXT_PUBLIC_RECURRING_PRICE_TIER_1,
    tier2: process.env.NEXT_PUBLIC_RECURRING_PRICE_TIER_2,
    paid: process.env.NEXT_PUBLIC_RECURRING_PRICE_PAID
  }
}

/**
 * Get the renewal period for a user's membership.
 * @param {Object} user - the user object.
 * @param {String} testDate (optional) - a "current date" to use in testing, in the format YYYY-MM-DD.
 * @returns {Object} the renewal period object,
 *  or null if:
 *    a) the user has no active membership, or
 *    b) no renewal period is found where the membership expiry date is between the start and end dates of the renewal period
 */
export const getRenewalPeriod = (user, testDate) => {
  const membership = getActiveMembership(user)

  if (!membership || membership.stripeSubscriptionId) {
    return null
  }

  let currentDate = new Date()

  if (testDate) {
    const dateParts = testDate.split('-')
    currentDate = new Date(
      `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}T00:00:00.000Z`
    )
  }

  // Determine the expiry date for the user's membership (in milliseconds).
  const expiryDate = membership.startedAt + 1000 * 60 * 60 * 24 * 365

  return renewalPeriods.find(
    p =>
      currentDate.getTime() >= p.start.getTime() &&
      currentDate.getTime() < p.end.getTime() &&
      expiryDate >= p.start.getTime() &&
      expiryDate < p.end.getTime()
  )
}

/**
 * Check if user has a recurring membership.
 * @param {Object} user - the user object.
 * @returns {Boolean} true if user has an active membership with a stripeSubscriptionId, otherwise false.
 */
export const hasRecurringMembership = user => {
  return (
    user &&
    user.memberships &&
    user.memberships.find(m => m.stripeSubscriptionId && m.active)
  )
}

/**
 * Get a user's active non-recurring membership.
 * @param {Object} user - the user object.
 * @returns {String} the user's membership, or null if they have no active, non-recurring membership.
 */
export const getActiveMembership = user => {
  if (user && user.memberships) {
    return user.memberships.find(m => m.active)
  }

  return null
}

/**
 * Get a user's membership type.
 * @param {Object} user - the user object.
 * @returns {String} the user's membership type, or null if they have no active membership.
 */
export const getUserMembershipType = user => {
  if (user) {
    const activeMembership = getActiveMembership(user)

    if (!activeMembership) {
      return null
    }

    return activeMembership.membershipOption.key
  }

  return null
}

/**
 * Check if the specified user's membership should be expired.
 * @param {Object} user - the user object.
 * @param {String} testDate (optional) - a "current date" to use in testing, in the format YYYY-MM-DD.
 * @returns {Boolean} true if the membership expiry date fits into a renewal period in the past.
 */
export const membershipShouldExpire = (user, testDate) => {
  let currentDate = new Date()

  // Override current date for testing.
  // Usage https://scramblers.localbicesterheritage.com/profile/me?testDate=2023-05-18
  if (testDate) {
    const dateParts = testDate.split('-')
    currentDate = new Date(
      `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}T00:00:00.000Z`
    )
  }

  const membership = getActiveMembership(user)

  if (!membership) {
    return false
  }

  if (membership.expiresAt && membership.expiresAt > currentDate.getTime()) {
    return false
  }

  // Determine the expiry date for the user's membership (in milliseconds). If the membership is set
  // to not expire (i.e. == 1000), set the expiryDate to a date in the future (01/01/2099)
  const expiryDate =
    membership.expiresAt === 1000
      ? 4070908800000
      : membership.startedAt + 1000 * 60 * 60 * 24 * 365

  // Determine the possible renewal period for a user based on the start date
  // of their membership.
  const validRenewalPeriod = renewalPeriods.find(
    p => expiryDate >= p.start.getTime() && expiryDate < p.end.getTime()
  )

  console.log('expiryDate :>> ', expiryDate)
  console.log('validRenewalPeriod :>> ', validRenewalPeriod)

  // If there is no valid renewal period, they most likely have a membership that started after the renewals process in May 2023.
  // In this case, test the expiry date against the current date.
  if (!validRenewalPeriod) {
    return expiryDate < currentDate.getTime()
  }

  console.log(
    'membershipShouldExpire :>> ',
    validRenewalPeriod.end.getTime() < currentDate.getTime()
  )

  console.log('currentDate :>> ', currentDate)
  console.log('validRenewalPeriod.end :>> ', validRenewalPeriod.end)

  // Handles the cases during the renewals period starting May 2023 and running for 12 months.
  return (
    validRenewalPeriod &&
    validRenewalPeriod.end.getTime() < currentDate.getTime()
  )
}

/**
 * Reactivate a user's membership.
 * @param {Object} user - the user object.
 * @returns {Object} the updated subscription object, or null if the cancellation fails.
 */
export const reactivateMembership = async user => {
  // Get the user's active membership.
  const activeMembership = getActiveMembership(user)

  const res = await fetch('/api/cancelMembership', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      cancel: false,
      subscriptionId: activeMembership.stripeSubscriptionId
    })
  })

  if (res.status === 200) {
    const { subscription } = await res.json()

    return subscription
  }

  return null
}
