import React from 'react'

const SubscribeCTA = ({ title, message, cta }) => (
  <div className='w-full'>
    <div className='flex flex-col flex-1'>
      <div className='flex items-center space-x-2'>
        <svg className='w-3 h-3' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M4.5 6.5v-3a3 3 0 016 0v3m-8 0h10a1 1 0 011 1v6a1 1 0 01-1 1h-10a1 1 0 01-1-1v-6a1 1 0 011-1z' stroke='currentColor' />
        </svg>
        <h4 className='text-black bodyText'>{title}</h4>
      </div>
      <div className='flex flex-col items-start justify-between w-full md:flex-row'>
        <p className='flex-1 mt-1 text-sm text-gray-600 md:pr-10'>
          {message}
        </p>
        <button className='flex-shrink-0 mt-4 button md:mt-0'>
          {/* <svg className='w-3 h-3 mr-1.5' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M10.5 14.49v.5h.5v-.5h-.5zm-10 0H0v.5h.5v-.5zm7-4.996v.5-.5zm-4 0v-.5.5zM8 3.498a2.499 2.499 0 01-2.5 2.498v1C7.433 6.996 9 5.43 9 3.498H8zM5.5 5.996A2.499 2.499 0 013 3.498H2a3.499 3.499 0 003.5 3.498v-1zM3 3.498A2.499 2.499 0 015.5 1V0A3.499 3.499 0 002 3.498h1zM5.5 1A2.5 2.5 0 018 3.498h1A3.499 3.499 0 005.5 0v1zm5 12.99H.5v1h10v-1zm-9.5.5v-1.996H0v1.996h1zm2.5-4.496h4v-1h-4v1zm6.5 2.5v1.996h1v-1.997h-1zm-2.5-2.5a2.5 2.5 0 012.5 2.5h1a3.5 3.5 0 00-3.5-3.5v1zm-6.5 2.5a2.5 2.5 0 012.5-2.5v-1a3.5 3.5 0 00-3.5 3.5h1zM12 5v5h1V5h-1zm-2 3h5V7h-5v1z' fill='currentColor' />
          </svg> */}
          {cta}
        </button>
      </div>
    </div>
  </div>
)

export default SubscribeCTA
