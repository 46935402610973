import * as Constants from '@/lib/constants'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const Multiple = ({ data, post, responses, id, onInteraction, user }) => {
  const [selectedResponse, setSelectedResponse] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const { active, inactiveMessage } = data

  const handleSubmit = () => {
    onInteraction({ response: selectedResponse._id })
    setSubmitting(true)
  }

  const hasResponded = post.userInteractions &&
    post.userInteractions.length &&
    post.userInteractions.find(i => {
      if (
        i.type === Constants.INTERACTION_TYPE.RESPONSE &&
        i.post &&
        i.post._id === post._id &&
        responses.map(r => r._id).includes(i.response._id)
      ) {
        return i
      }

      return false
    })

  // console.log('selectedResponse, hasResponded :>> ', selectedResponse, hasResponded)
  // const allResponses = [] // (post.interactions.post && post.interactions.post[Constants.INTERACTION_TYPE.RESPONSE]) || []

  return (
    <div className='relative w-full mt-3'>
      <div className='relative flex flex-wrap justify-center w-full px-4'>
        {responses.map((response, index) => {
          const { _id, text } = response

          const isSelected = (selectedResponse && selectedResponse._id === _id) || (hasResponded && hasResponded.response._id === _id)
          // const allResponse = allResponses.find(r => r.response._id === _id)
          // console.log('isSelected :>> ', isSelected)

          if (isSelected && !selectedResponse) {
            setSelectedResponse(response)
          }

          return (
            <label
              onClick={() => {
                if (!hasResponded) {
                  setSelectedResponse(response)
                }
              }} className={`w-auto mb-2 mr-2 checkbox last:mr-0 ${hasResponded ? 'pointer-events-none' : ''}`} key={index}
            >
              <input
                defaultChecked={hasResponded && isSelected}
                // checked={hasResponded && isSelected ? 'checked' : ''}
                readOnly={hasResponded}
                className='absolute invisible w-0 h-0 p-0 m-0'
                name={id}
                type='checkbox'
              />
              <div className='inner'>
                <span className='inner-circle'>
                  <div className='w-2 h-2 rounded-full bg-secondary' />
                </span>
                <span>{text}</span>
                {/* {isSelected && <span> (selected)</span>} */}
                {/* {allResponse && <span> [{allResponse.count}, {`${allResponse.percentage}%`}]</span>} */}
              </div>
            </label>
          )
        })}
      </div>
      {user
        ? (
          <button onClick={handleSubmit} className={`transition-colors mt-4 cta-button ${hasResponded || submitting || !active ? ' pointer-events-none button--disabled' : null}`}>
            {active &&
              <>
                {hasResponded ? 'Response submitted' : (submitting ? 'Saving' : 'Submit')}
                {!hasResponded && !submitting
                  ? <svg viewBox='0 0 15 15' fill='none' className='w-4 h-4 ml-1.5'><path d='M13.5 7.5l-4-4m4 4l-4 4m4-4H1' stroke='currentColor' /></svg>
                  : null}
              </>}
            {!active && <span>{inactiveMessage}</span>}
          </button>
          )
        : (
          <a href='/signup' className='mt-4 transition-colors cta-button'>Register to submit your answer</a>
          )}
    </div>
  )
}

Multiple.propTypes = {
  onInteraction: PropTypes.func,
  respopnses: PropTypes.object,
  id: PropTypes.string
}

export default Multiple
