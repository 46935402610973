import React, { useContext, useEffect, useRef } from 'react'
import { useUser } from '@/hooks/useUser'
import { getUserMembershipType } from '@/lib/memberships'
import { InteractionContext } from '@/contexts/InteractionContext'

// gsap scrolltrigger experimentation
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const WelcomeMat = () => {
  const { setShowSignUpModal } = useContext(InteractionContext)
  const { user } = useUser()

  const getMembershipType = user => {
    if (!user) {
      return false
    }

    const membershipType = getUserMembershipType(user)

    if (!membershipType) {
      return 'free'
    }

    // return 'paid'
    return membershipType
  }

  // hero image and welcome message
  let heroImage = '/images/welcomeMat/nonMember.jpg.jpg'
  let welcomeMessage

  switch (getMembershipType(user)) {
    case false:
      heroImage = '/images/welcomeMat/nonMember.jpg'
      welcomeMessage =
        'Welcome to Scramblers, a membership club that celebrates Bicester Heritage and the people and cars it supports. Be part of it for free. Upgrade to make the most of it.'
      break
    case 'free':
      heroImage = '/images/welcomeMat/freeMember.jpg'
      welcomeMessage =
        'Thanks for being part of Scramblers. This is just the start. Learn more about the benefits of being a Pegasus Scrambler below.'
      break
    case 'tier1':
      heroImage = '/images/welcomeMat/paidMember.jpg'
      welcomeMessage =
        'This is your one-stop shop for all things Bicester Heritage. Book tickets, meet the specialists and register for the latest goings-on right here. Exclusive to you.'
      break
    case 'paid':
      heroImage = '/images/welcomeMat/paidMember.jpg'
      welcomeMessage =
        'Thanks for your support. Cras justo odio, dapibus ac facilisis in, egestas eget quam.'
      break
    default:
      heroImage = '/images/welcomeMat/nonMember.jpg'
      welcomeMessage =
        'Welcome to Scramblers, a membership club that celebrates Bicester Heritage and the people and cars it supports. Every month we offer exclusive content and a themed, invitation-only event.'
      break
  }

  // subtle parallax as user scrolls away frorm welcoome mat
  let mat = useRef()
  const anim = useRef(null)

  useEffect(() => {
    anim.current = gsap.to(mat, {
      y: 100,
      ease: 'none',
      opacity: 0,
      scrollTrigger: {
        trigger: mat,
        start: 'top top',
        end: 'bottom top',
        scrub: true
      }
    })
    return () => {
      // In this function kill all your GSAP instances
      // that could be affecting other areas of your code
      // our throwing errors
      anim.current.kill()
    }
  }, [])

  return (
    <div
      ref={el => (mat = el)}
      id='welcomeMat'
      className='z-0 flex flex-col items-center justify-start w-full bg-darkGrey'
    >
      {/* gradients overalying welcome mat */}
      <div className='absolute top-0 left-0 z-10 w-full h-40 bg-gradient-to-b from-darkGrey' />
      <div className='absolute bottom-0 left-0 z-10 block w-full h-72 bg-gradient-to-t from-darkGrey' />
      <div className='absolute top-0 left-0 z-10 hidden w-full h-full md:block bg-gradient-to-r from-darkGrey' />

      {/* the bg image */}
      <img
        className='absolute top-0 left-0 z-0 object-cover object-right w-full h-full opacity-30 lg:opacity-95'
        src={heroImage}
      />

      {/* message and cta 👇 */}
      {/* first a fakie div at the top to take up the space of the header so the message can be properly centered */}
      <div className='h-20' />

      <div className='container relative z-20 flex flex-col items-start justify-center flex-1'>
        <div className='relative z-20 flex flex-col items-center w-full text-center lg:items-start lg:text-left'>
          <div className='flex items-center justify-center w-full space-x-4 lg:justify-start'>
            <div className='flex items-center space-x-2'>
              <span className='font-light tracking-widest text-white uppercase text-[8px]'>
                Presented by
              </span>
              <a
                className='w-8 md:w-10'
                href='https://bicesterheritage.co.uk/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='w-full'
                  src='/images/bicester-heritage-logo-badge.svg'
                  alt='Presented by Bicester Heritage'
                />
              </a>
            </div>
            {/* <div className='flex items-center space-x-2'>
              <span className='font-light tracking-widest text-white uppercase text-[8px]'>
                In association with
              </span>
              <a
                className='w-20 md:w-24'
                href='https://www.hagerty.co.uk/'
                target='_blank'
              >
                <img
                  className='w-full'
                  src='/images/promo/hagerty.svg'
                  alt='In association with Hagerty'
                />
              </a>
            </div> */}
          </div>
          <h1 className='mt-6 text-4xl text-white md:mt-10 md:text-5xl lg:text-5xl font-welcomeMat'>
            Welcome {user?.firstName}
          </h1>
          <p
            className='w-11/12 mt-4 text-base font-light text-white md:mt-4 md:text-lg lg:mt-5 lg:text-xl lg:w-8/12 xl:w-[600px]'
            style={{ textWrap: 'balance' }}
          >
            {welcomeMessage}
          </p>

          {/* NON MEMBER CTAs */}
          {!getMembershipType(user) && (
            <div className='flex mt-6 space-x-3'>
              <a
                href='https://scramblers.bicesterheritage.co.uk/post/so-tell-me-about-scramblers'
                className='button button--light'
              >
                Find out more
              </a>

              <button
                className='button'
                onClick={() => setShowSignUpModal(true)}
              >
                Membership options
              </button>

              <a href='/shop/event-tickets' className='button'>
                Buy tickets
              </a>
            </div>
          )}

          {/* FREE MEMBER CTAs */}
          {getMembershipType(user) === 'free' && (
            <div className='flex mt-6 space-x-3'>
              <a
                href='https://scramblers.bicesterheritage.co.uk/post/so-tell-me-about-scramblers'
                className='button button--light'
              >
                Find out more
              </a>

              <a href='/profile/me#membership' className='button'>
                Upgrade membership
              </a>

              <a href='/shop/event-tickets' className='button'>
                Buy tickets
              </a>
            </div>
          )}

          {/* PAID MEMBER CTAs */}
          {/* {getMembershipType(user) === 'paid' && ( */}
          {getMembershipType(user) === 'tier1' && (
            <div className='flex mt-6 space-x-3'>
              <a href='/profile/me' className='button'>
                View profile page
              </a>

              {/* <a href='/shop' className='button'>
                Visit Scramblers Shop
              </a> */}
              <a
                href='https://scramblers.bicesterheritage.co.uk/tagged/member%20benefits'
                rel='noreferrer'
                className='button'
              >
                View benefits
              </a>
            </div>
          )}
        </div>
      </div>

      {/* and a fakie div at the bottom to take up the space of the negative margin on the feed */}
      <div className='h-16' />
    </div>
  )
}

export default WelcomeMat
