import * as Constants from '@/lib/constants'
import { InteractionContext } from '@/contexts/InteractionContext'
import React, { useContext, useState } from 'react'
import useUserInteractions from '@/hooks/useUserInteractions'
import { useUser } from '@/hooks/useUser'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share'

const PostUtils = require('@/lib/post')

const ShareControlsExpanded = ({ post, size }) => {
  const postUtils = new PostUtils(post)
  const { handleInteraction, setLoginOpen } = useContext(InteractionContext)
  const [shareOptionsOpen, setShareOptionsOpen] = useState(false)
  const { user } = useUser()
  const { interactions: userInteractions } = useUserInteractions(user, post)

  const handleBookmarkIntention = event => {
    event.preventDefault()

    if (user) {
      handleInteraction({
        type: Constants.INTERACTION_TYPE.BOOKMARK,
        data: {
          post: post._id
        }
      })
    } else {
      setLoginOpen(true)
    }
  }

  const handleShareInteraction = platform => {
    handleInteraction({
      type: Constants.INTERACTION_TYPE.SHARE,
      data: {
        post: post._id
      }
    })
  }

  const isBookmarked = userInteractions && userInteractions.find(i => i.type === 'BOOKMARK' && i.active && i.post._id === post._id) !== undefined

  return (
    <div className={`fixed flex flex-col items-center justify-center space-y-4 text-white ${user ? 'bottom-5' : 'bottom-32'} right-4`}>

      {/* share icons */}
      {/* these are hidden by default and animate out based on the 'shareOptionsOpen' hook */}
      <div className='overflow-hidden'>
        <div className={`flex flex-col items-center justify-center space-y-2 transform transition duration-300 ease-out ${shareOptionsOpen ? 'translate-y-0' : 'translate-y-full'}`}>
          <FacebookShareButton
            className={`transform transition-all duration-300 ease-out ${shareOptionsOpen ? 'opacity-100 scale-100 rotate-0 translate-y-0' : 'opacity-0 scale-50 rotate-180 translate-y-full'}`}
            hashtag={postUtils.hashtags(false, true)}
            onShareWindowClose={() => handleShareInteraction('facebook')}
            quote={postUtils.socialShareMessage()}
            url={postUtils.socialShareUrl()}
          >
            <FacebookIcon size={30} round />
          </FacebookShareButton>
          <TwitterShareButton
            className={`transform transition-all duration-300 ease-out ${shareOptionsOpen ? 'opacity-100 scale-100 rotate-0' : 'opacity-0 scale-50 rotate-180'}`}
            hashtags={postUtils.hashtags(true, false)}
            onShareWindowClose={() => handleShareInteraction('twitter')}
            title={postUtils.socialShareMessage()}
            url={postUtils.socialShareUrl()}
            via='wearescramblers'
          >
            <TwitterIcon size={30} round />
          </TwitterShareButton>
          <WhatsappShareButton
            className={`transform transition-all duration-300 ease-out ${shareOptionsOpen ? 'opacity-100 scale-100 rotate-0' : 'opacity-0 scale-50 rotate-180'}`}
            onShareWindowClose={() => handleShareInteraction('whatsapp')}
            title={postUtils.socialShareMessage()}
            url={postUtils.socialShareUrl()}
          >
            <WhatsappIcon size={30} round />
          </WhatsappShareButton>
        </div>
      </div>

      {/* share button */}
      <a
        className={`border-none cursor-pointer hover:text-accent rounded-full ${shareOptionsOpen ? 'text-accent' : ''}`}
        onClick={() => setShareOptionsOpen(!shareOptionsOpen)}
      >
        <svg className='w-5 h-5' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M4.5 7.5L8.5 7.5M11 4L8.5 7.49542L11 11M14.5 2.4987C14.5 3.60198 13.604 4.49739 12.5 4.49739C11.396 4.49739 10.5 3.60198 10.5 2.4987C10.5 1.39542 11.396 0.5 12.5 0.5C13.604 0.5 14.5 1.39542 14.5 2.4987ZM14.5 12.4922C14.5 13.5954 13.604 14.4909 12.5 14.4909C11.396 14.4909 10.5 13.5954 10.5 12.4922C10.5 11.3889 11.396 10.4935 12.5 10.4935C13.604 10.4935 14.5 11.3889 14.5 12.4922ZM4.5 7.49543C4.5 8.59871 3.604 9.49413 2.5 9.49413C1.396 9.49413 0.5 8.59871 0.5 7.49543C0.5 6.39215 1.396 5.49673 2.5 5.49673C3.604 5.49673 4.5 6.39215 4.5 7.49543Z' stroke='currentColor' strokeLinecap='square' />
        </svg>
      </a>

      {/* save button */}

      {
        user
          ? isBookmarked
              ? (
                <a
                  className='border-none cursor-pointer text-accent'
                  onClick={handleBookmarkIntention}
                >
                  <svg className='w-5 h-5' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg' width='15' height='15'>
                    <path d='M13 0H2v14.5a.5.5 0 00.812.39L7.5 11.14l4.688 3.75A.5.5 0 0013 14.5V0z' fill='currentColor' />
                  </svg>
                </a>
                )
              : (
                <a
                  className='border-none cursor-pointer hover:text-accent'
                  onClick={handleBookmarkIntention}
                >
                  <svg className='w-5 h-5' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M12.5 14.5l-.312.39A.5.5 0 0013 14.5h-.5zm0-14h.5V0h-.5v.5zm-10 0V0H2v.5h.5zm0 14H2a.5.5 0 00.812.39L2.5 14.5zm5-4l.312-.39a.5.5 0 00-.624 0l.312.39zm5.5 4V.5h-1v14h1zM2 .5v14h1V.5H2zm.812 14.39l5-4-.624-.78-5 4 .624.78zm4.376-4l5 4 .624-.78-5-4-.624.78zM12.5 0h-10v1h10V0z' fill='currentColor' />
                  </svg>
                </a>
                )
          : null
      }
    </div>
  )
}

export default ShareControlsExpanded
