import { InteractionContext } from '@/contexts/InteractionContext'
import MembersOnlyLayer from '../../MembersOnlyLayer'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Title from '../../Sections/Title'
import marked from 'marked'
const url = require('@/lib/url')()

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 70, w: 800 })
}

const getMarkdownText = input => {
  return {
    __html: marked(input || '')
  }
}

const Video = ({ content, post, posterImage, title }) => {
  const { setExpandedPost } = useContext(InteractionContext)

  const handleVideoExpansion = () => {
    setExpandedPost(post)
  }

  // determine whether article can be viewed
  const { userIsMember, subscriberOnly } = post
  let canView = true
  if (subscriberOnly && !userIsMember) {
    canView = false
  }

  return (
    <div
      id='grid-video'
      className={`${subscriberOnly && canView && 'cursor-pointer'}`}
      onClick={
        subscriberOnly && canView ? handleVideoExpansion : null
      }
    >
      {subscriberOnly &&
        (
          <MembersOnlyLayer
            type='video'
            title={title}
            primaryImage={posterImage ? getImageUrl(posterImage) : null}
            memberCTA='Watch now'
            nonMemberCTA='Upgrade to watch the video'
            userIsMember={userIsMember}
          />
        )}
      {/* video / image preview area */}
      <div onClick={handleVideoExpansion} className='cursor-pointer grid-video__video group'>
        <div className='transition-opacity opacity-75 play-icon group-hover:opacity-100'>
          <svg className='w-full h-full' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M0 7.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zm6.249-2.432a.5.5 0 01.5-.002l3.5 2a.5.5 0 010 .868l-3.5 2A.5.5 0 016 9.5v-4a.5.5 0 01.249-.432z' fill='currentColor' />
          </svg>
        </div>
        <div className='text-white wing'>
          <div className='w-3 h-full bg-white' />
          <svg className='block h-3 -mx-px' viewBox='0 0 93 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M93 0H92.6963C92.6963 0 68.7686 0.337891 59.9053 4.32568C54.8066 6.61963 52.0254 11.2734 49.1289 16.6475C44.2627 7.27539 39.9756 4.32568 34.7607 2.62158C27.1562 0.13623 0 0 0 0V17H93V0Z' fill='currentColor' />
          </svg>
          <div className='flex-1 w-3 h-full bg-white' />
        </div>
        {
          posterImage
            ? <img className='preview-image' src={getImageUrl(posterImage[0])} />
            : null
        }
      </div>

      {/* title / subtitle */}
      <div className='video-detail'>
        <div className='video-gradient' />
        <div className='z-10 video-detail__titles'>
          {/* title */}
          <Title title={title} />

          {/* subtitle */}
          <p className='mt-2 text-base font-light leading-tight video-subtitle' dangerouslySetInnerHTML={getMarkdownText(content)} />

          {/* cta */}
          <div onClick={handleVideoExpansion} className='mt-2 btn-play-video'>
            <button className='button button--small'>
              <svg className='w-3 h-3 mr-1.5' viewBox='0 0 15 15' fill='none'>
                <path d='M6.5 5.5l.248-.434A.5.5 0 006 5.5h.5zm0 4H6a.5.5 0 00.748.434L6.5 9.5zm3.5-2l.248.434a.5.5 0 000-.868L10 7.5zM7.5 14A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM6 5.5v4h1v-4H6zm.748 4.434l3.5-2-.496-.868-3.5 2 .496.868zm3.5-2.868l-3.5-2-.496.868 3.5 2 .496-.868z' fill='currentColor' />
              </svg>
              Watch Now
            </button>
          </div>
          <div onClick={handleVideoExpansion} className='mt-4 btn-play-video--lge'>
            <button className='button button--black'>
              <svg className='w-4 h-4 mr-1.5' viewBox='0 0 15 15' fill='none'>
                <path d='M6.5 5.5l.248-.434A.5.5 0 006 5.5h.5zm0 4H6a.5.5 0 00.748.434L6.5 9.5zm3.5-2l.248.434a.5.5 0 000-.868L10 7.5zM7.5 14A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM6 5.5v4h1v-4H6zm.748 4.434l3.5-2-.496-.868-3.5 2 .496.868zm3.5-2.868l-3.5-2-.496.868 3.5 2 .496-.868z' fill='currentColor' />
              </svg>
              Watch Now
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

Video.propTypes = {
  post: PropTypes.object
}

export default Video
