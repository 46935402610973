import FacebookIcon from '@/components/Icons/Facebook'
import InstagramIcon from '@/components/Icons/Instagram'
import TwitterIcon from '@/components/Icons/Twitter'
import React from 'react'

const Footer = () => (
  <div>
    <div className='flex flex-col items-center justify-between w-full pt-5 pb-10 mt-5 border-t border-white border-opacity-10 lg:flex-row'>
      <div className='flex items-center justify-start space-x-4 text-sm font-light text-white'>
        <a
          href='/post/so-tell-me-about-scramblers'
          className='hover:text-accent'
        >
          About
        </a>
        <a
          href='https://bicesterheritage.co.uk/terms'
          target='_blank'
          rel='noreferrer'
          className='hover:text-accent'
        >
          Terms &amp; Conditions
        </a>
        <a
          href='mailto:hello@wearescramblers.com'
          target='_blank'
          rel='noreferrer'
          className='hover:text-accent'
        >
          Contact Us
        </a>
      </div>

      <div className='flex items-center justify-between w-full mt-10 space-x-6 md:w-auto md:justify-start lg:mt-0'>
        <div className='flex items-center space-x-2'>
          <span className='font-light tracking-widest text-white uppercase text-[8px]'>
            Presented by
          </span>
          <a
            className='w-10 md:w-10'
            href='https://bicesterheritage.co.uk/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='w-full'
              src='/images/bicester-heritage-logo-badge.svg'
              alt='Presented by Bicester Heritage'
            />
          </a>
        </div>
        <div className='hidden w-px bg-white md:block opacity-20 h-7' />
        <div className='flex items-center space-x-3'>
          <span className='font-light tracking-widest text-white uppercase text-[8px]'>
            Join us:
          </span>
          <div className='flex items-center justify-start space-x-4 text-white'>
            <a
              className='hover:text-accent'
              href='https://facebook.com/wearescramblers'
              target='_blank'
              rel='noreferrer'
            >
              <FacebookIcon className='w-6 h-6' />
            </a>
            <a
              className='hover:text-accent'
              href='https://twitter.com/wearescramblers'
              fill='none'
              target='_blank'
              rel='noreferrer'
            >
              <TwitterIcon className='w-6 h-6' />
            </a>
            <a
              className='hover:text-accent'
              href='https://instagram.com/wearescramblers'
              fill='none'
              target='_blank'
              rel='noreferrer'
            >
              <InstagramIcon className='w-6 h-6' />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
