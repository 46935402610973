import * as Constants from '@/lib/constants'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactSlider from 'react-slider'

const Slider = ({ data, post, responses, id, onInteraction, user }) => {
  // const [submitVisible, setSubmitVisible] = useState(false)
  const [sliderValue, setSliderValue] = useState(10)
  const [submitting, setSubmitting] = useState(false)
  const { active, inactiveMessage } = data

  const handleSubmit = () => {
    onInteraction({ data: sliderValue.toString() })
    setSubmitting(true)
  }

  const updateValue = value => {
    setSliderValue(value)
    // setSubmitVisible(true)
  }

  const hasResponded = post.userInteractions &&
    post.userInteractions.length &&
    post.userInteractions.find(i => {
      if (
        i.type === Constants.INTERACTION_TYPE.RESPONSE &&
        i.post &&
        i.post._id === post._id
      ) {
        return i
      }

      return false
    })

  return (
    <div className='relative w-full mt-6'>
      <div className='px-4'>
        <ReactSlider
          className={`w-full horizontal-slider ${hasResponded ? 'opacity-75 hasResponded' : ''}`}
          thumbClassName='handle'
          trackClassName='bar'
          defaultValue={hasResponded && hasResponded.data ? Number(hasResponded.data) : Number(responses[0].text)}
          min={Number(responses[0].text)}
          max={Number(responses[1].text)}
          onAfterChange={val => updateValue(val)}
          onChange={val => updateValue(val)}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          disabled={Boolean(hasResponded)}
        />
        <div className='flex items-center justify-between w-full mt-5 text-white text-navigation'>
          <span>{responses[0].text}</span>
          <span>{responses[1].text}</span>
        </div>
      </div>
      {
        user
          ? (
            <button onClick={handleSubmit} className={`transition-colors mt-4 cta-button ${hasResponded || submitting || !active ? ' pointer-events-none button--disabled' : null}`}>
              {active &&
                <>
                  {hasResponded ? 'Thank you' : (submitting ? 'Saving' : 'Submit')}
                  {!hasResponded && !submitting
                    ? <svg viewBox='0 0 15 15' fill='none' className='w-4 h-4 ml-1.5'><path d='M13.5 7.5l-4-4m4 4l-4 4m4-4H1' stroke='currentColor' /></svg>
                    : null}
                </>}
              {!active && <span>{inactiveMessage}</span>}
            </button>
            )
          : (
            <a href='/signup' className='mt-4 transition-colors cta-button'>Register to submit your answer</a>
            )
}
    </div>
  )
}

Slider.propTypes = {
  respopnses: PropTypes.object,
  id: PropTypes.string
}

export default Slider
