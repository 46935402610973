import { useContext, useEffect, useRef, useState } from 'react'

import { InteractionContext } from '@/contexts/InteractionContext'
import { useUser } from '@/hooks/useUser'
import * as gtag from '@/lib/gtag'
import {
  getUserMembershipType,
  membershipShouldExpire
} from '@/lib/memberships'
import { Transition } from '@headlessui/react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useCart } from 'react-use-cart'

import UserNav from '../Auth/UserNav'
import Cart from '../Shop/Cart'

const Header = ({
  category,
  hasWelcomeMat,
  onToggleMenu,
  onToggleLogin,
  tags,
  returnUrl
}) => {
  const { setMenuOpen, setShowSignUpModal } = useContext(InteractionContext)
  const { user, update } = useUser()
  const [totalCartItems, setTotalCartItems] = useState(false)
  const router = useRouter()

  const [userNavVisible, setUserNavVisible] = useState(false)
  const { cartVisible, setCartVisible } = useContext(InteractionContext)
  const [headerCollapsed, setHeaderCollapsed] = useState(false)

  const { items, totalItems } = useCart()

  const cartHolder = useRef()
  const userNav = useRef()
  const btnUser = useRef()
  const btnCart = useRef()

  // Handle Google PageViews
  if (category && tags && tags[0] && category === tags[0]) {
    gtag.pageView(tags[0], `/${tags[0]}`, false)
  } else if (!category) {
    gtag.pageView('Home', '/', false)
  }

  /**
   * Check if the user has an active, non-recurring membership and if it should be expired.
   */
  useEffect(() => {
    if (user) {
      if (membershipShouldExpire(user, router.query.testDate)) {
        const memberships = user.memberships.map(m => {
          return {
            ...m,
            active: false
          }
        })

        update(user.email, { memberships })
      }
    }
  }, [user, update, router.query.testDate])

  /**
   * Create a Swell customer account for the user.
   * @param {Object} user - The user object.
   */
  const createCustomer = async user => {
    const membership = getUserMembershipType(user)

    const data = {
      email: user.email,
      displayName: user.displayName
    }

    if (membership) {
      data.group = 'merlin_pegasus'
    }

    const response = await fetch('/api/createCustomer', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })

    const createCustomerResponse = await response.json()

    if (createCustomerResponse.success) {
      update(user.email, {
        customerIdSwell: createCustomerResponse.account.id
      })
    }
  }

  /**
   * Check if the user has a Swell customer ID, otherwise create their Swell account and update their user record.
   */
  useEffect(() => {
    if (user) {
      if (!user.customerIdSwell) {
        createCustomer(user)
      }
    }
  }, [user])

  // If the user is logged in, update the cart count in the header.
  useEffect(() => {
    setTotalCartItems(totalItems)
  }, [totalItems])

  useScrollPosition(({ prevPos, currPos }) => {
    let triggerPos = 300
    if (category) {
      triggerPos = 1
    }
    if (currPos.y < -triggerPos) {
      setHeaderCollapsed(true)
    } else {
      setHeaderCollapsed(false)
    }
  })

  const handleClickOutside = e => {
    const cartParent = e.target.closest('#cartHolder')
    const btnParent = e.target.closest('#btnCart')
    if (
      (cartVisible && cartParent) ||
      (userNavVisible && userNav.current.contains(e.target))
    ) {
      // inside click
      return
    }
    if (
      (cartVisible && btnParent) ||
      (userNavVisible && btnUser.current.contains(e.target))
    ) {
      // click the same button that revealed it
      return
    }

    // outside click
    setCartVisible(false)
    setUserNavVisible(false)
    document.removeEventListener('mousedown', handleClickOutside)
  }

  useEffect(() => {
    if (cartVisible || userNavVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [cartVisible, userNavVisible])

  const activeClass = 'text-accent'

  return (
    <div className='fixed top-0 z-30 w-full'>
      {/* <div className='w-full py-1 bg-black'>
        <div className='container'>
          <div className='w-full text-xs font-thin text-left text-white'>Brought to you by <a href='/' className='font-light underline'>BICESTER HERITAGE</a></div>
        </div>
      </div> */}
      <header
        className={`header ${headerCollapsed ? 'collapsed' : ''} ${
          !hasWelcomeMat ? 'bg-darkGrey' : ''
        }`}
      >
        <div
          className={`
            lg:flex flex-col hidden absolute bottom-0 left-0 w-full transform transition-all duration-300 translate-y-full pointer-events-none
            ${
              headerCollapsed
                ? 'opacity-100 backdrop-blur-md backdrop-filter'
                : 'opacity-0'
            }
          `}
        >
          <div className='w-full h-2 bg-darkGrey bg-opacity-[0.95]' />
          <div className='w-full h-2 opacity-[0.95] bg-gradient-to-b from-darkGrey' />
        </div>
        <div className='container z-0 flex justify-between h-full text-white'>
          <div className='relative z-10 items-center hidden h-full lg:flex'>
            <div
              className='absolute top-0 left-0 z-10 h-full transform -translate-x-full w-52'
              style={{
                backgroundImage: 'url(/images/bgShadow.png)',
                backgroundSize: '100% 100%'
              }}
            />
            <div
              className='z-10 flex-1 h-full bg-top bg-repeat-x bg-pureWhite'
              style={{
                backgroundImage: 'url(/images/bgShadow.png)',
                backgroundSize: '100% 100%'
              }}
            >
              <div className='flex items-center w-full h-full space-x-10 text-xs font-medium xl:space-x-20'>
                <a
                  href='https://bicesterheritage.co.uk'
                  className='flex flex-col items-center'
                >
                  <img
                    src='/images/bicesterHeritage.svg'
                    className={`
                      transition-all duration-300
                      ${headerCollapsed ? 'h-10' : 'h-12'}
                    `}
                  />
                </a>
              </div>
            </div>
            {/* <a
              href='https://bicesterheritage.co.uk/flywheel'
              className='hidden h-full xl:block'
            >
              <img src='/images/flyWheelTab.png' className='h-full' />
            </a> */}
            {/* uncomment this and remove image beneath when we remove flywheel */}
            {/* <img
              src='/images/curveBg.png'
              className='h-full pointer-events-none xl:hidden'
            /> */}
            <img
              src='/images/curveBg.png'
              className='h-full pointer-events-none'
            />
            {/* <img
              src='/images/curveBgFlywheel.png'
              className='hidden h-full pointer-events-none xl:block'
            /> */}
          </div>
          <div className='flex items-center justify-between flex-1 h-full border-b border-white lg:border-none'>
            <div className='flex items-center flex-shrink-0 space-x-5'>
              {/* logo */}
              <div className='header__logo'>
                <a href='/'>
                  <img src='/images/scramblers-logo.svg' alt='Scramblers' />
                </a>
              </div>
              {/* nav (only visible to users) */}
              {user && (
                <div className='z-10 flex items-center justify-start h-full'>
                  <nav className='z-10 items-center justify-end hidden h-full space-x-3 text-navigation lg:flex'>
                    <a href='/' className={`${!category ? activeClass : ''}`}>
                      All
                    </a>
                    <a
                      href='/read'
                      className={`${category === 'read' ? activeClass : ''}`}
                    >
                      Read
                    </a>
                    <a
                      href='/watch'
                      className={`${category === 'watch' ? activeClass : ''}`}
                    >
                      Watch
                    </a>
                    <a
                      href='/respond'
                      className={`${category === 'respond' ? activeClass : ''}`}
                    >
                      Respond
                    </a>
                    <a
                      href='/visit'
                      className={`${category === 'visit' ? activeClass : ''}`}
                    >
                      Visit
                    </a>
                    <a
                      href='/shop/all'
                      className={`${category === 'shop' ? activeClass : ''}`}
                    >
                      Shop
                    </a>
                    {/* <Link href='/shop'>
                    <a className=''>Shop</a>
                  </Link> */}
                  </nav>
                </div>
              )}
            </div>

            <div className='z-10 flex items-center space-x-3 md:space-x-4'>
              <div className='relative flex items-center space-x-3'>
                {/* nav cta's, only visible to non-members */}
                {!user && (
                  <>
                    <button
                      className='hidden button button--accent md:flex'
                      onClick={() => setShowSignUpModal(true)}
                    >
                      Join us &rarr;
                    </button>
                    <Link
                      href={
                        returnUrl ? `/login?returnUrl=${returnUrl}` : '/login'
                      }
                      className='button'
                    >
                      Log In
                    </Link>
                    <Link href='/shop/all' className='hidden button md:flex'>
                      Shop
                    </Link>
                  </>
                )}

                {/* bookmarks */}
                {user && (
                  <Link
                    href='/saved'
                    className='hover:text-accent focus:outline-none'
                  >
                    {category && category === 'saved' && (
                      <svg
                        className='w-5 h-5 text-accent'
                        viewBox='0 0 24 24'
                        strokeWidth='2'
                        stroke='currentcolor'
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M13 7a2 2 0 0 1 2 2v12l-5 -3l-5 3v-12a2 2 0 0 1 2 -2h6z' />
                        <path d='M9.265 4a2 2 0 0 1 1.735 -1h6a2 2 0 0 1 2 2v12l-1 -.6' />
                      </svg>
                    )}
                    {category !== 'saved' && (
                      <svg
                        className='w-5 h-5'
                        viewBox='0 0 24 24'
                        strokeWidth='2'
                        stroke='currentcolor'
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M13 7a2 2 0 0 1 2 2v12l-5 -3l-5 3v-12a2 2 0 0 1 2 -2h6z' />
                        <path d='M9.265 4a2 2 0 0 1 1.735 -1h6a2 2 0 0 1 2 2v12l-1 -.6' />
                      </svg>
                    )}
                  </Link>
                )}

                {/* user icon */}
                {/* <button onClick={() => setUserNavVisible(v => !v)} className='hidden hover:text-accent sm:block focus:outline-none'>
                  <svg className='w-5 h-5' width='44' height='44' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <circle cx='12' cy='7' r='4' />
                    <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
                  </svg>
                </button> */}

                {/* cart icon */}
                <button
                  ref={btnCart}
                  onClick={() => setCartVisible(v => !v)}
                  className='relative hover:text-accent focus:outline-none'
                >
                  {totalCartItems > 0 && (
                    <span className='absolute flex -top-1.5 group-hover:bg-accent -right-1.5 items-center justify-center w-3.5 h-3.5 text-black bg-white rounded-full text-xxs'>
                      {totalCartItems}
                    </span>
                  )}
                  <svg
                    className='w-5 h-5'
                    width='44'
                    height='44'
                    viewBox='0 0 24 24'
                    strokeWidth='2'
                    stroke='currentColor'
                    fill='none'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <circle cx='6' cy='19' r='2' />
                    <circle cx='17' cy='19' r='2' />
                    <path d='M17 17h-11v-14h-2' />
                    <path d='M6 5l14 1l-1 7h-13' />
                  </svg>
                </button>

                {user && (
                  <div className='hidden w-px h-5 bg-white opacity-25 md:block' />
                )}

                {user && (
                  <button
                    ref={btnUser}
                    onClick={() => setUserNavVisible(v => !v)}
                    className='flex text-navigation items-center space-x-1.5 hover:text-accent transition focus:outline-none'
                  >
                    <svg
                      className='w-5 h-5'
                      viewBox='0 0 24 24'
                      strokeWidth='2'
                      stroke='currentColor'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <circle cx='12' cy='7' r='4' />
                      <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
                    </svg>
                    <span className='hidden max-w-[200px] truncate text-navigation md:block'>
                      {user.displayName}
                    </span>
                  </button>
                )}

                {/* hamburger */}
                {user && (
                  <button
                    onClick={() => setMenuOpen(true)}
                    className='flex items-center h-full focus:outline-none hover:text-accent'
                  >
                    <svg
                      className='w-7 h-7'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M4 6h16M4 12h16M4 18h16'
                      />
                    </svg>
                  </button>
                )}

                <Transition
                  show={userNavVisible}
                  enter='transition-all duration-200'
                  enterFrom='opacity-0 transform translate-y-3'
                  enterTo='opacity-100 transform translate-y-0'
                  leave='transition-all duration-200'
                  leaveFrom='opacity-100 transform translate-y-0'
                  leaveTo='opacity-0 transform translate-y-3'
                  className='absolute top-0 right-0 pointer-events-none'
                >
                  <div ref={userNav}>{user ? <UserNav /> : null}</div>
                </Transition>

                <Transition
                  show={cartVisible}
                  enter='transition-all duration-200'
                  enterFrom='opacity-0 transform translate-y-3'
                  enterTo='opacity-100 transform translate-y-0'
                  leave='transition-all duration-200'
                  leaveFrom='opacity-100 transform translate-y-0'
                  leaveTo='opacity-0 transform translate-y-3'
                  className='absolute top-0 right-0 pointer-events-none'
                >
                  <div ref={cartHolder} id='cartHolder'>
                    <Cart />
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </header>

      {category && category !== 'saved' && category !== 'shop' && (
        <div
          className={`categoryFilter text-white caption ${
            headerCollapsed ? 'collapsed' : ''
          }`}
        >
          <div>
            <span className='text-white'>Now Viewing:</span> all posts tagged
          </div>
          <span className='inline-block px-1.5 py-0.5 leading-none rounded-full caption bg-accent text-black'>
            {category}
          </span>
        </div>
      )}
      {category && category === 'saved' && (
        <div
          className={`categoryFilter text-white caption ${
            headerCollapsed ? 'collapsed' : ''
          }`}
        >
          <div>
            <span className='text-white'>Now Viewing:</span>{' '}
            <span className='inline-block px-1.5 py-0.5 leading-none rounded-full caption bg-accent text-black'>
              All Saved Posts
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

Header.propTypes = {
  hasWelcomeMat: PropTypes.bool,

  // The function to call when the menu is toggled.
  onToggleMenu: PropTypes.func,
  onToggleLogin: PropTypes.func
}

export default Header
