import React from 'react'
import PromoCollection from '../Sections/PromoCollection'

const promoCollection = ({ data, post }) => {
  const typesMap = post._refSections

  // Collect an array of Promo components within this post.
  const promos = Object.keys(typesMap).map(id => {
    if (typesMap[id] === 'promo') {
      return post.sections.find(s => s._id === id)
    }
  }).filter(Boolean)

  return (
    <PromoCollection
      post={post}
      promos={promos}
    />
  )
}

export default promoCollection
