import React from 'react'

function FacebookIcon ({ className }) {
  return (
    <svg className={className} viewBox='0 0 15 15' fill='none'>
      <path d='M7.5 14.5a7 7 0 110-14 7 7 0 010 14zm0 0v-8a2 2 0 012-2h.5m-5 4h5' fill='none' stroke='currentColor' />
    </svg>
  )
}
export default FacebookIcon
