const OptedOutNotice = ({ user }) => {
  if (user && !user.canEmail) {
    return (
      <div className='px-5 py-3 text-xs italic font-light text-gray-400 border-t-2 border-white'>
        Please note: if you have chosen NOT to receive email updates from us, then we cannot send you event information or invitations. Please <a href='/profile/me#preferences' className='text-white underline'>visit your profile page</a> to adjust your settings.
      </div>
    )
  }

  return null
}

export default OptedOutNotice
