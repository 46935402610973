import PropTypes from 'prop-types'
import React from 'react'

const Promo = ({ imageUrl, originalPost, post, shortDescription, title }) => (
  <div className='relative grid grid-cols-12 componentPromo container'>
    <div className='componentPromo__inner default-grid-cols'>
      <div className='flex items-start w-full space-x-4'>
        <div className='flex-shrink-0 w-24'>
          <img className='w-full' src={`${imageUrl || 'https://cdn.shopify.com/s/files/1/0115/4570/6554/products/Issue3-coverProduct_6a9785b1-f00b-4b7f-93ca-45fbcde38d7b.jpg?v=1573131873'}`} />
        </div>
        <div className='flex flex-col items-start flex-1'>
          <h3 className='leading-none text-black bodyText'>{title}.</h3>
          <p className='pr-5 mt-2 text-sm text-gray-600'>{shortDescription}</p>
          <a href='/' className='mt-3 button button--textOnly'>Buy it now</a>
        </div>
      </div>
    </div>
  </div>
)

Promo.propTypes = {
  imageUrl: PropTypes.string,
  originalPost: PropTypes.object,
  post: PropTypes.object,
  shortDescription: PropTypes.string,
  title: PropTypes.string
}

export default Promo
