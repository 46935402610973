import { InteractionContext } from '@/contexts/InteractionContext'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useContext, useRef, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import marked from 'marked'
import ShareControlsExpanded from '../../ShareControlsExpanded'
import { useUser } from '@/hooks/useUser'
import JoinCta from '../../JoinCta'
import 'swiper/css' // core Swiper
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const url = require('@/lib/url')()

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 80, w: 1200 })
}

const getMarkdownText = input => {
  return {
    __html: marked(input || '')
  }
}

const FullScreenExhibition = ({ data, deepLink }) => {
  const { setExpandedExhibition, setMobileShareData } =
    useContext(InteractionContext)
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const paginationRefFraction = useRef(null)
  const paginationRefBullets = useRef(null)

  const { user } = useUser()

  const handleMobileShareControls = () => {
    setMobileShareData({ open: true, post: data.post })
  }

  return (
    <div
      id='full-screen-exhibition'
      className='flex items-center justify-center h-full'
    >
      <div className='absolute top-0 left-0 flex items-center justify-between w-screen h-16 border-b border-gray-800'>
        <div className='flex items-center justify-start flex-1 flex-shrink-0 h-full pl-5'>
          {user ? (
            <img className='flex-shrink-0 h-8' src='/images/scramblers-s.svg' />
          ) : (
            <img
              className='flex-shrink-0 h-8'
              src='/images/scramblers-logo.svg'
            />
          )}
        </div>
        {/* <h1 className='flex-shrink font-medium text-center text-white title--sans title--sml'>{title}</h1> */}
        <h1
          className='flex-shrink font-medium text-center text-white truncate title--sans title--sml'
          dangerouslySetInnerHTML={getMarkdownText(data.title)}
        />
        <div className='flex justify-end flex-1 pr-3 space-x-2'>
          <button
            onClick={handleMobileShareControls}
            className='relative top-0 right-0 text-white lg:hidden'
          >
            <svg
              className='w-5 h-5'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
              />
            </svg>
          </button>
          {deepLink ? (
            <a href='/' className='relative top-0 right-0 button--close group'>
              <svg
                className='block w-3 h-3 transition-all duration-300 ease-in transform group-hover:rotate-180'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor' />
              </svg>
            </a>
          ) : (
            <button
              onClick={() => setExpandedExhibition(null)}
              className='relative top-0 right-0 button--close group'
            >
              <svg
                className='block w-3 h-3 transition-all duration-300 ease-in transform group-hover:rotate-180'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor' />
              </svg>
            </button>
          )}
        </div>
      </div>

      <div className='absolute z-20 hidden bottom-3 right-3 lg:block'>
        <ShareControlsExpanded post={data.post} />
      </div>

      <div className='absolute z-30 items-center hidden lg:flex right-3'>
        <div className='flex items-center space-x-2 text-gray-400 caption'>
          <span ref={paginationRefFraction}>loading</span>
          <div className='w-8 h-px bg-gray-400' />
        </div>
        <div className='flex flex-col overflow-hidden border border-white rounded-full'>
          <button
            ref={nextRef}
            className='relative flex items-center justify-center w-10 h-10 text-white border-b border-white focus:outline-none group next'
          >
            <svg
              className='relative z-10 w-3 h-3 transition group-hover:text-black'
              viewBox='0 0 15 15'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5 14l7-6.5L5 1'
                stroke='currentColor'
                strokeLinecap='square'
              />
            </svg>
            <div className='absolute bottom-0 left-0 z-0 w-full h-px transition-all duration-300 rounded-t-full opacity-0 group-hover:h-full group-hover:opacity-100 bg-secondary' />
          </button>
          <button
            ref={prevRef}
            className='relative flex items-center justify-center w-10 h-10 text-white focus:outline-none group prev'
          >
            <svg
              className='relative z-10 w-3 h-3 transition group-hover:text-black'
              viewBox='0 0 15 15'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10 14L3 7.5 10 1'
                stroke='currentColor'
                strokeLinecap='square'
              />
            </svg>
            <div className='absolute top-0 left-0 z-0 w-full h-px transition-all duration-300 rounded-b-full opacity-0 group-hover:h-full group-hover:opacity-100 bg-secondary' />
          </button>
        </div>
      </div>
      <div
        ref={paginationRefBullets}
        className={`absolute left-0 z-50 flex items-center justify-center w-full pt-3 pb-10 ${
          user ? 'bottom-0' : 'bottom-28'
        } lg:hidden bg-themeBg`}
      />
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        loop
        speed={500}
        className={`h-full ${user ? '' : 'pb-28 lg:pb-24'}`}
        // onInit={(swiper) => {
        //   swiper.params.navigation.prevEl = prevRef.current
        //   swiper.params.navigation.nextEl = nextRef.current
        //   swiper.navigation.init()
        //   swiper.navigation.update()

        //   swiper.params.pagination.el = paginationRefFraction.current
        //   swiper.params.pagination.type = 'fraction'
        //   if (window.innerWidth < 1024) {
        //     swiper.params.pagination.type = 'bullets'
        //     swiper.params.pagination.el = paginationRefBullets.current
        //   }
        //   swiper.pagination.init()
        //   swiper.pagination.update()
        // }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current
        }}
        pagination={{
          el: paginationRefFraction.current,
          type: 'fraction',
          renderBullet: (index, className) => {
            if (window.innerWidth < 1024) {
              return (
                '<span class="' + className + '">' + (index + 1) + '</span>'
              )
            }
          }
        }}
        onBeforeInit={swiper => {
          swiper.navigation.init()
          swiper.navigation.update()

          swiper.pagination.init()
          swiper.pagination.update()
        }}
      >
        {data.images.map((image, index) => (
          <SwiperSlide key={index} className=''>
            <div className='flex flex-col items-start justify-center w-screen h-full lg:justify-start'>
              <div className='w-[95%] lg:w-[80%] relative flex-grow mx-auto'>
                <img
                  key={index}
                  src={getImageUrl(image)}
                  alt={data.imageMetadata[index].altText || ''}
                  className='absolute top-0 left-0 object-contain w-full h-full lg:object-top'
                />
              </div>
              {data.imageMetadata[index].captionHeading ||
              data.imageMetadata[index].captionBody ? (
                <div className='flex lg:w-[80%] text-center mx-auto flex-col items-center justify-end w-full pt-8 pb-12 lg:pb-0 px-3 lg:px-10 text-gray-300 lg:mt-0'>
                  {data.imageMetadata[index].captionHeading ? (
                    <span className='block w-full text-white uppercase bodyText'>
                      {data.imageMetadata[index].captionHeading}
                    </span>
                  ) : null}
                  {data.imageMetadata[index].captionBody ? (
                    <span
                      className='block w-full text-sm font-light normal-case captionBody'
                      dangerouslySetInnerHTML={getMarkdownText(
                        data.imageMetadata[index].captionBody
                      )}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {user ? null : <JoinCta />}
    </div>
  )
}

export default FullScreenExhibition
