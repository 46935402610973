import * as Constants from '@/lib/constants'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const Text = ({ data, post, id, onInteraction, user }) => {
  const [response, setResponse] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const { active, inactiveMessage } = data

  const handleSubmit = () => {
    onInteraction({ data: response })
    setSubmitting(true)
  }

  const handleChange = (e) => {
    setResponse(e.target.value)
  }

  const hasResponded = post.userInteractions &&
    post.userInteractions.length &&
    post.userInteractions.find(i => {
      if (
        i.type === Constants.INTERACTION_TYPE.RESPONSE &&
        i.post &&
        i.post._id === post._id
      ) {
        return i
      }

      return false
    })

  return (
    <div className='relative w-full mt-2'>

      <div className='px-4'>
        <textarea
          onChange={handleChange}
          value={hasResponded && hasResponded.data ? hasResponded.data : response}
          className={`w-full input bg-transparent border-white border placeholder-gray-300 focus:border-white text-white ${hasResponded ? 'pointer-events-none' : ''}`}
          rows='3'
          placeholder='Type your answer here'
        />
      </div>
      {
        user
          ? (
            <button onClick={handleSubmit} className={`transition-colors mt-4 cta-button ${hasResponded || submitting || !active ? ' pointer-events-none button--disabled' : null}`}>
              {active &&
                <>
                  {hasResponded ? 'Thanks for your response' : (submitting ? 'Submitting...' : 'Submit')}
                  {!hasResponded && !submitting
                    ? <svg viewBox='0 0 15 15' fill='none' className='w-4 h-4 ml-1.5'><path d='M13.5 7.5l-4-4m4 4l-4 4m4-4H1' stroke='currentColor' /></svg>
                    : null}
                </>}
              {!active && <span>{inactiveMessage}</span>}
            </button>
            )
          : (
            <a href='/signup' className='mt-4 transition-colors cta-button'>Register to submit your response</a>
            )
      }
    </div>
  )
}

Text.propTypes = {
  id: PropTypes.string,
  onInteraction: PropTypes.func
}

export default Text
