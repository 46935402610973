import * as gtag from '@/lib/gtag'
import marked from 'marked'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import ZoomableImage from './ZoomableImage'
// const Flickity = require('react-flickity-component')
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css' // core Swiper
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const url = require('@/lib/url')()

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 80, h: 600 })
}

const getMarkdownText = input => {
  return {
    __html: marked(input || '')
  }
}

const ImageGallery = props => {
  const { images, imageMetadata } = props

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const fullSizeImages = images
    ? images.map((image, index) => {
        return {
          ...image,
          metadata: imageMetadata ? imageMetadata[index] : {},
          src: getImageUrl(image)
        }
      })
    : []

  const renderedFullSizeImages = fullSizeImages.map((image, index) => {
    const { altText, captionBody, captionHeading } = image.metadata

    return (
      <SwiperSlide key={index} className='w-full lg:w-auto'>
        <img
          src={image.src}
          alt={altText || ''}
          className='lg:h-[400px] lg:w-auto h-auto w-full'
        />
        <div className='flex flex-col items-start mt-2 space-y-1 imageCell__caption'>
          <span className='font-bold leading-none text-black uppercase caption'>
            {captionHeading ? (
              <span>{captionHeading}</span>
            ) : (
              <span>&nbsp;</span>
            )}
          </span>
          <span className='leading-none bodyTextSml captionBody'>
            {captionBody ? (
              <span dangerouslySetInnerHTML={getMarkdownText(captionBody)} />
            ) : (
              <span>&nbsp;</span>
            )}
          </span>
        </div>
      </SwiperSlide>
    )
  })

  return (
    <div className='mt-10 componentImageGallery'>
      <div className='relative'>
        <div className='absolute right-0 z-30 flex items-center bottom-1'>
          <div className='flex flex-row overflow-hidden border border-black rounded-md'>
            <button
              ref={prevRef}
              className='relative flex items-center justify-center w-6 h-6 text-black group focus:outline-none'
            >
              <svg
                className='relative z-10 w-3 h-3 transition group-hover:text-black'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M10 14L3 7.5 10 1'
                  stroke='currentColor'
                  strokeLinecap='square'
                />
              </svg>
              <div className='absolute top-0 right-0 z-0 w-px h-full transition-all duration-300 opacity-0 rounded-l-md group-hover:w-full group-hover:opacity-100 bg-accent' />
            </button>
            <button
              ref={nextRef}
              className='relative flex items-center justify-center w-6 h-6 text-black border-l border-black focus:outline-none group'
            >
              <svg
                className='relative z-10 w-3 h-3 transition group-hover:text-black'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5 14l7-6.5L5 1'
                  stroke='currentColor'
                  strokeLinecap='square'
                />
              </svg>
              <div className='absolute bottom-0 left-0 z-0 w-px h-full transition-all duration-300 opacity-0 rounded-r-md group-hover:w-full group-hover:opacity-100 bg-accent' />
            </button>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={10}
          slidesPerView={1.2}
          // loop
          // loopedSlides={2}
          speed={500}
          onBeforeInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
            swiper.navigation.init()
            swiper.navigation.update()
          }}
          className='w-full'
        >
          {renderedFullSizeImages}
        </Swiper>
      </div>
    </div>
  )
}

ImageGallery.propTypes = {
  images: PropTypes.array,
  post: PropTypes.object
}

export default ImageGallery
