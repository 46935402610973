import React from 'react'

const listenMat = (props) => (
  <div
    className='fixed top-0 left-0 flex items-center justify-center w-full overflow-hidden bg-black'
    style={{ height: 500, zIndex: -1 }}
  >
    <img className='object-cover w-full h-full' src='https://media3.giphy.com/media/l4FGwbNesOqYDdZkI/source.gif' alt='Scramblers' />
  </div>
)

export default listenMat
