import * as Constants from '@/lib/constants'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Transition } from '@headlessui/react'

const Poll = ({ data, post, responses, id, onInteraction, user }) => {
  const [selectedResponse, setSelectedResponse] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const { active, inactiveMessage } = data

  const handleSubmit = () => {
    onInteraction({ response: selectedResponse._id })
    setSubmitting(true)
  }

  const hasResponded = post.userInteractions &&
    post.userInteractions.length &&
    post.userInteractions.find(i => {
      if (
        i.type === Constants.INTERACTION_TYPE.RESPONSE &&
        i.post &&
        i.post._id === post._id &&
        responses.map(r => r._id).includes(i.response._id)
      ) {
        return i
      }

      return false
    })

  const allResponses = [] // (post.interactions.post && post.interactions.post[Constants.INTERACTION_TYPE.RESPONSE]) || []

  return (
    <div className='relative mt-2'>
      <div className='relative flex flex-wrap justify-start w-full px-4'>
        {responses.map((response, index) => {
          const { _id, text } = response
          const isSelected = (selectedResponse && selectedResponse._id === _id) || (hasResponded && hasResponded.response._id === _id)
          const allResponse = allResponses.find(r => r.response._id === _id)

          if (isSelected && !selectedResponse) {
            setSelectedResponse(response)
          }

          return (
            <label
              onClick={() => {
                if (!hasResponded) {
                  setSelectedResponse(response)
                }
              }}
              className={`w-full mb-2 pollItem last:mb-0 ${hasResponded ? 'pointer-events-none' : ''}`} key={index}
            >
              <input
                checked={isSelected ? true : ''}
                readOnly={hasResponded}
                className='absolute invisible w-0 h-0 p-0 m-0'
                type='radio'
                name={id}
              />
              <div className={`inner ${hasResponded ? 'hasResponded' : null}`}>
                <span
                  className='absolute z-0 h-10 transition-all duration-300 bg-gray-100 left-px top-px'
                  style={{
                    width: allResponse ? allResponse.percentage + '%' : 0,
                    maxWidth: '99.6%',
                    opacity: allResponse ? 100 : 0
                  }}
                />
                <div className='z-10 flex items-center justify-start h-10'>
                  <span className='flex-1 checkbar' />
                  <span className='py-2.5'>{text}</span>
                </div>
                {hasResponded ? null : <svg className='checkmark' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4 7.5L7 10l4-5m-3.5 9.5a7 7 0 110-14 7 7 0 010 14z' stroke='currentColor' /></svg>}
                {allResponse && <span className='z-10 text-xs text-gray-400'>{allResponse.count} votes ({allResponse.percentage}%)</span>}
              </div>
            </label>
          )
        })}
      </div>

      {
        user
          ? (
            <button onClick={handleSubmit} className={`transition-colors mt-4 cta-button ${hasResponded || submitting || !active ? 'pointer-events-none button--disabled' : null}`}>
              {active &&
                <>
                  {hasResponded ? 'Voted!' : (submitting ? 'Saving' : 'Vote')}
                  {!hasResponded && !submitting
                    ? <svg viewBox='0 0 15 15' fill='none' className='w-4 h-4 ml-1.5'><path d='M13.5 7.5l-4-4m4 4l-4 4m4-4H1' stroke='currentColor' /></svg>
                    : null}
                </>}
              {!active && <span>{inactiveMessage}</span>}
            </button>
            )
          : (
            <a href='/signup' className='mt-4 transition-colors cta-button'>Register to vote</a>
            )
      }
    </div>
  )
}

Poll.propTypes = {
  respopnses: PropTypes.object,
  id: PropTypes.string,
  onInteraction: PropTypes.func
}

export default Poll
