import React, { useContext, useState } from 'react'

const JoinCta = () => {
  return (
    <div
      className='fixed bottom-0 z-50 w-screen text-white border-t-2 border-gray-800 h-36 md:h-24 bg-themeBg'
    >
      <div className='container flex flex-col md:flex-row justify-center items-center md:justify-between h-full max-w-[888px] mx-auto px-3 lg:px-0'>
        <div className='flex flex-col text-center md:text-left'>
          <div className='font-medium leading-none text-white uppercase subtitle'>Like what you see?</div>
          <div className='mt-1 text-sm font-light text-gray-600 md:text-base'>Become a Scramblers member to access more great content – and exclusive events</div>
        </div>
        <a href='/signup' className='flex-shrink-0 mt-3 button button--accent md:mt-0'>Join us, it's free!</a>
      </div>
    </div>
  )
}

export default JoinCta
