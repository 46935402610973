import PropTypes from 'prop-types'
import React from 'react'
import marked from 'marked'
import Textfit from 'react-textfit'

const getMarkdownText = input => {
  const rawMarkup = marked(input)
  return { __html: rawMarkup }
}

const Quote = ({ author, images, imageMetadata, link, linkText, post, title, quote }) => {
  if (link.indexOf('http') < 0) {
    link = `https://${link}`
  }

  const isExternalLink = link && new URL(link).origin !== window.location.origin

  return (
    <div className='flex flex-col w-full min-h-[250px] md:h-full'>
      <cite className='flex flex-col items-start flex-1 w-full p-3 pt-10 not-italic text-white lg:p-6 lg:pt-14 bg-primary rounded-xl'>

        <Textfit
          mode='multi'
          className='flex-1 w-full italic font-normal text-left'
          style={{ lineHeight: '1em' }}
          max={60}
          min={12}
        >
          <div dangerouslySetInnerHTML={getMarkdownText('"' + quote + '"')} />
        </Textfit>

        {/* author */}
        {
        author
          ? (
            <div className='flex items-start mt-3 space-x-2 lg:items-center'>
              {/* <img className='object-cover rounded-full w-7 h-7' src='https://images.unsplash.com/photo-1528892952291-009c663ce843?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=596&q=80' /> */}
              <div className='flex flex-col text-xs not-italic font-thin leading-tight lg:text-sm'>
                <span className=''>{author}</span>
                <div className='flex items-center'>
                  <span>Via <a href={link} target={`${isExternalLink ? '_blank' : '_self'}`} className='italic font-normal underline'>{linkText}</a></span>
                  {
                  isExternalLink
                    ? (
                      <svg className='ml-0.5 md:ml-0.5 w-2.5 h-2.5 md:w-3.5 md:h-3.5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14' />
                      </svg>
                      )
                    : null
                    }
                </div>
              </div>
            </div>
            )
          : null
        }
      </cite>
      <svg className='block transform translate-x-5 text-primary' width='80' height='18' viewBox='0 0 80 24' fill='none'>
        <path d='M30.3361 3.76429C23.8181 0.195571 0.543945 0 0.543945 0H79.9898C79.9898 0 59.4823 0.48482 51.8858 6.21108C47.5163 9.50484 45.1329 16.1864 42.6502 23.9032C38.4793 10.4459 34.805 6.21106 30.3361 3.76429Z' fill='currentColor' />
      </svg>
    </div>
  )
}

Quote.propTypes = {
  post: PropTypes.object
}

export default Quote
