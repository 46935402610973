import PropTypes from 'prop-types'
import React from 'react'

const widthClasses = {
  inline: 'default-grid-cols',
  fullBleed: 'w-full',
  fullWidth: 'col-span-12'
}

const Image = ({ metadata, post, src, width }) => {
  return (
    <div className={`componentImage mt-10 ${width !== 'fullBleed' ? '' : ''}`}>
      <img src={src} className='w-full' />
      <div className='flex flex-col col-span-12 mt-1.5 componentImageGallery__caption'>
        <span className='normal-case caption'>
          <span className='mr-1 font-normal text-black uppercase'>{metadata && metadata.captionHeading}</span> {metadata && metadata.captionBody}
        </span>
      </div>
      {/* <div className='flex flex-col col-span-12 componentImageGallery__caption'>
        <span className='font-medium text-black caption'>{metadata && metadata.captionHeading}</span>
        <span className='caption'>{metadata && metadata.captionBody}</span>
      </div> */}
    </div>
  )
}

Image.propTypes = {
  metadata: PropTypes.object,
  post: PropTypes.object,
  src: PropTypes.string,
  width: PropTypes.string
}

export default Image
