import { InteractionContext } from '@/contexts/InteractionContext'
import * as Constants from '@/lib/constants'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import marked from 'marked'
import Multiple from '../Sections/Cta/Multiple'
import Poll from '../Sections/Cta/Poll'
import Slider from '../Sections/Cta/Slider'
import Text from '../Sections/Cta/Text'
import { useUser } from '@/hooks/useUser'
import useUserInteractions from '@/hooks/useUserInteractions'
import OptedOutNotice from '../CustomComponent/Cta/OptedOutNotice'

const getMarkdownText = input => {
  const rawMarkup = marked(input || '')
  return { __html: rawMarkup }
}

const renderQuestions = (postData, post, q, onInteraction, user) => {
  let Handler

  switch (q.type) {
    case 'multiple':
      Handler = Multiple
      break
    case 'slider':
      Handler = Slider
      break
    case 'text':
      Handler = Text
      break
    case 'poll':
      Handler = Poll
      break
    default:
      Handler = Multiple
      break
  }

  return <Handler user={user} data={postData} post={post} responses={q.responses} id={q._id} onInteraction={onInteraction} />
}

const Cta = ({ active, inactiveMessage, body, buttons, displayTitle, imageUrl, post, questions, title }) => {
  const { handleInteraction } = useContext(InteractionContext)
  const { user, update } = useUser()
  const { interactions: userInteractions } = useUserInteractions(user, post)
  const postData = { active, inactiveMessage }

  post.userInteractions = userInteractions

  const handleCtaInteraction = (data = {}) => {
    handleInteraction({
      type: Constants.INTERACTION_TYPE.RESPONSE,
      data: {
        post: post._id,
        question: questions[0]._id,
        ...data
      }
    })
  }

  return (
    <div id='inline-cta'>
      <div className='cta-inner'>
        {body
          ? (
            <div className='normal-case description'>
              <div className='font-normal text-white subtitle subtitle--sans' dangerouslySetInnerHTML={getMarkdownText(body)} />
            </div>
            )
          : null}

        {/* questions */}
        <div className='flex flex-row flex-wrap items-center w-full pt-4'>
          {questions.map((q, index) => {
            return (
              <div className='w-full' key={index}>
                <span className='text-white bodyText'>{q.text}</span>

                {renderQuestions(postData, post, q, handleCtaInteraction, user)}

                <OptedOutNotice user={user} />
              </div>
            )
          })}
        </div>

      </div>
    </div>
  )
}

Cta.propTypes = {
  imageUrl: PropTypes.string,
  post: PropTypes.object,
  questions: PropTypes.array,
  text: PropTypes.string
}

Cta.defaultProps = {
  imageUrl: ''
}

export default Cta
