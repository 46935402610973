import PropTypes from 'prop-types'
import React from 'react'
import VideoEmbed from '../Sections/VideoEmbed'

const videoEmbed = ({ data, post }) => {
  return (
    <VideoEmbed
      displayTitle={data.displayTitle}
      embedCode={data.embedCode}
      title={data.title}
      posterImage={data.posterImage}
      post={post}
    />
  )
}

videoEmbed.propTypes = {
  /**
   * The section data for rendering the component.
   */
  data: PropTypes.object
}

export default videoEmbed
