import React from 'react'
// import TextOverMedia from '../sections/TextOverMedia'

const textOverMedia = ({ data, post }) => {
  return null
  // <TextOverMedia
  //   accentColour={data.accentColour}
  //   // heading={data.heading}
  //   heading={post.title}
  //   headingAlignment={data.headingAlignment}
  //   headingWidth={data.headingWidth}
  //   image={data.image}
  //   subheading={data.subheading}
  //   textPosition={data.textPosition}
  //   tintAmount={data.tintAmount}
  // />
}

export default textOverMedia
