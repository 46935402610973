import PropTypes from 'prop-types'
import React, { Component } from 'react'

class ShopProduct extends Component {
  render () {
    const { post, title, shortDescription, imageUrl } = this.props

    const shopProductId = Object.keys(post._refSections).find(section => {
      if (post._refSections[section] === 'shopProduct') {
        return section
      }
    })

    const shopProduct = post.sections.find(section => section._id === shopProductId)
    // const variants = shopProduct.additionalData.variants.map(variant => {
    //   return {
    //     id: variant.id,
    //     imageUrl: variant.image.src,
    //     price: variant.price,
    //     sku: variant.sku,
    //     weight: variant.weight
    //   }
    // })

    return (
      <div className='flex flex-col items-start w-full'>
        <a className='flex flex-col w-full group' href='#'>
          <img src={imageUrl} className='w-full' />
          <div className='flex items-start justify-between w-full pb-0 mt-4 mb-0'>
            <h3 className='flex-1 mb-0 leading-none text-gray-800 capitalize transition-colors group-hover:text-black bodyText'>{title}</h3>
            {/* <span className='flex-shrink-0 mb-0 italic text-gray-500 bodyText'>$15</span> */}
          </div>
          {/* <div className='text-navigation text-accent'>$15</div> */}
          <span className='flex-shrink-0 mb-0 italic text-gray-500 bodyText group-hover:text-accent'>$15</span>
        </a>
      </div>
    )
  }
}

ShopProduct.propTypes = {
  post: PropTypes.object,

  // The main title for the Shop Product section.
  title: PropTypes.string,
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  imageUrl: PropTypes.string
}

export default ShopProduct
