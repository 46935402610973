import React, { useState } from 'react'
import { parseCookies, setCookie } from 'nookies'

const COOKIES_COOKIE =
  process.env.NEXT_PUBLIC_COOKIES_COOKIE || '__scramblers.ac'

const CookieConsent = ({ cookies }) => {
  const [hideConsent, setHideConsent] = useState(false)

  if (!cookies) {
    return null
  }

  const clientCookies = parseCookies()

  const handleCookieConsent = () => {
    setCookie(null, COOKIES_COOKIE, true, {
      maxAge: 60 * 60 * 24 * 365, // 1 year
      path: '/'
    })

    setHideConsent(true)
  }

  let acceptsCookies = false

  if (clientCookies && clientCookies[COOKIES_COOKIE]) {
    acceptsCookies = true
  }

  if (cookies && cookies[COOKIES_COOKIE]) {
    acceptsCookies = true
  }

  if (!acceptsCookies) {
    return (
      <div className='container fixed left-0 right-0 z-50 mx-auto bottom-3'>
        <div className='flex flex-col items-center justify-between px-5 py-4 text-white border border-gray-700 rounded shadow-lg md:flex-row bg-themeBg'>
          <div className='text-sm font-light text-center md:pr-5 md:text-left lg:pr-20'>
            Scramblers uses cookies to provide useful site functionality such as
            logging you in to your account and saving your preferences. By
            remaining on this website you indicate your consent as outlined in
            our{' '}
            <a
              href='https://bicesterheritage.co.uk/cookies-statement/'
              target='_blank'
              rel='noreferrer'
              className='underline'
            >
              Cookie Policy
            </a>
            .
          </div>
          <button
            onClick={handleCookieConsent}
            className='flex-shrink-0 mt-3 button button--accent md:mt-0'
          >
            Accept &amp; Close
          </button>
        </div>
      </div>
    )
  }
  return null
}

export default CookieConsent
