import PropTypes from 'prop-types'
import raf from 'raf' // requestAnimationFrame polyfill
import React from 'react'
import ReactHowler from 'react-howler'

class AudioPlayer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0
    }

    this.getDuration = this.getDuration.bind(this)
    this.getSeekPos = this.getSeekPos.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleOnLoad = this.handleOnLoad.bind(this)
    this.handleOnEnd = this.handleOnEnd.bind(this)
    this.handleOnPlay = this.handleOnPlay.bind(this)
    this.handleStop = this.handleStop.bind(this)
    this.renderSeekPos = this.renderSeekPos.bind(this)
    this.handleLoopToggle = this.handleLoopToggle.bind(this)
    this.handleMuteToggle = this.handleMuteToggle.bind(this)
  }

  componentWillUnmount () {
    this.clearRAF()
  }

  getDuration () {
    if (this.state.duration) {
      return new Date(this.state.duration * 1000).toISOString().substr(11, 8)
    }
    
    return '0:00'
  }

  getSeekPos () {
    if (this.state.seek) {
      return new Date(this.state.seek * 1000).toISOString().substr(11, 8)
    }
    
    return '0:00'
  }

  handleToggle () {
    this.setState({
      playing: !this.state.playing
    })
  }

  handleOnLoad () {
    this.setState({
      loaded: true,
      duration: this.player.duration()
    })
  }

  handleOnPlay () {
    this.setState({
      playing: true
    })
    this.renderSeekPos()
  }

  handleOnEnd () {
    this.setState({
      playing: false
    })
    this.clearRAF()
  }

  handleStop () {
    this.player.stop()
    this.setState({
      playing: false // Need to update our local state so we don't immediately invoke autoplay
    })
    this.renderSeekPos()
  }

  handleLoopToggle () {
    this.setState({
      loop: !this.state.loop
    })
  }

  handleMuteToggle () {
    this.setState({
      mute: !this.state.mute
    })
  }

  renderSeekPos () {
    this.setState({
      seek: this.player.seek()
    })
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos)
    }
  }

  clearRAF () {
    raf.cancel(this._raf)
  }

  render () {
    const { imageUrl, media, title } = this.props

    return (
      <>
        <ReactHowler
          src={[media.url]}
          playing={this.state.playing}
          onLoad={this.handleOnLoad}
          onPlay={this.handleOnPlay}
          onEnd={this.handleOnEnd}
          loop={this.state.loop}
          mute={this.state.mute}
          volume={this.state.volume}
          ref={(ref) => (this.player = ref)}
        />
        <div className='relative flex items-center w-24 overflow-hidden bg-white border-2 border-black rounded-full shadow-lg md:rounded-md md:border md:border-gray-200 md:w-auto md:h-auto'>

          {/* image */}
          <div className='bg-gray-100 md:w-24'>
            <img src={imageUrl} className='object-cover w-full h-full' />
          </div>
          <div className='absolute flex items-center justify-center w-full h-full tracking-wide md:px-5 md:py-3 md:w-auto md:h-auto md:block md:relative'>

            {/* episode/title */}
            <div className='flex-col items-center justify-center hidden md:flex'>
              <span className='text-gray-500 caption'>Episode 3</span>
              <span className='leading-tight bodyText'>{title}</span>
            </div>

            {/* track */}
            <div className='relative hidden w-64 h-px mt-3 bg-black md:block'>
              <span className='absolute top-0 left-0 block w-2 h-2 -mt-1 bg-black rounded-full' />
            </div>

            {/* controls */}
            <div className='relative flex flex-col w-full md:mt-3'>
              <div className='relative z-0 flex justify-between order-2 w-full md:order-1'>
                <span className='w-full text-center text-white caption md:w-auto md:text-left md:text-black'>{this.getSeekPos()}</span>
                <span className='hidden caption md:block'>{this.getDuration()}</span>
              </div>
              <div className='top-0 left-0 z-10 flex items-center justify-center order-1 w-full md:order-2 md:absolute md:-mt-1'>
                <button onClick={this.handleToggle} className='text-white opacity-75 focus:outline-none md:text-black hover:text-accent md:opacity-100'>
                  <svg className={`w-12 h-12 md:w-5 md:h-5 ${!this.state.playing ? 'hidden' : ''}`} fill='currentColor' viewBox='0 0 20 20'><path fillRule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z' clipRule='evenodd' /></svg>
                  <svg className={`w-12 h-12 md:w-5 md:h-5 ${this.state.playing ? 'hidden' : ''}`} fill='currentColor' viewBox='0 0 20 20'><path fillRule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z' clipRule='evenodd' /></svg>
                </button>
              </div>
            </div>
          </div>
          {/* <img src='/images/audioPlayer.png' width='433' /> */}
        </div>
      
        {/*
        <div className='audio-player--inner'>
          <div className='full-control'>


          <p>{(this.state.loaded) ? 'Loaded' : 'Loading'}</p>

          <div className='toggles'>
            <label>
              Loop:
              <input
                type='checkbox'
                checked={this.state.loop}
                onChange={this.handleLoopToggle}
              />
            </label>
            <label>
              Mute:
              <input
                type='checkbox'
                checked={this.state.mute}
                onChange={this.handleMuteToggle}
              />
            </label>
          </div>

          <p>
            {'Status: '}
            {(this.state.seek !== undefined) ? this.state.seek.toFixed(2) : '0.00'}
            {' / '}
            {(this.state.duration) ? this.state.duration.toFixed(2) : 'NaN'}
          </p>

          <div className='volume'>
            <label>
              Volume:
              <span className='slider-container'>
                <input
                  type='range'
                  min='0'
                  max='1'
                  step='.05'
                  value={this.state.volume}
                  onChange={e => this.setState({volume: parseFloat(e.target.value)})}
                  style={{verticalAlign: 'bottom'}}
                />
              </span>
              {this.state.volume.toFixed(2)}
            </label>
          </div>

          <button onClick={this.handleToggle}>
            {(this.state.playing) ? 'Pause' : 'Play'}
          </button>
          <button onClick={this.handleStop}>
            Stop
          </button>
        </div>
      </div>
      */}
      </>
    )
  }
}

AudioPlayer.propTypes = {
  imageUrl: PropTypes.string,
  media: PropTypes.object
}

export default AudioPlayer
