import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import * as gtag from '@/lib/gtag'

const ZoomableImage = props => {
  const [isZoomed, setIsZoomed] = useState(false)
  const { image } = props

  const handleZoomChange = function (isZoomed) {
    setIsZoomed(isZoomed)

    // If the image is being zoomed, log an event.
    if (isZoomed) {
      gtag.event({
        category: 'select_content',
        action: 'zoom',
        label: image.fileName
      })
    }
  }

  return (
    <ControlledZoom isZoomed={isZoomed} zoomMargin={80} wrapStyle={{ width: '100%' }} onZoomChange={handleZoomChange}>
      <img alt={image.metadata.altText} className='w-full' src={image.src} />
    </ControlledZoom>
  )
}

ZoomableImage.propTypes = {
  image: PropTypes.object
}

export default ZoomableImage
