import useUserInteractions from '@/hooks/useUserInteractions'
import { useUser } from '@/hooks/useUser'
import { InteractionContext } from '@/contexts/InteractionContext'
import * as Constants from '@/lib/constants'
import React, { useContext } from 'react'
import Multiple from '../../Sections/Cta/Multiple'
import Poll from '../../Sections/Cta/Poll'
import Slider from '../../Sections/Cta/Slider'
import Text from '../../Sections/Cta/Text'
import marked from 'marked'
import { Transition } from '@headlessui/react'
import ShareControlsExpanded from '../../ShareControlsExpanded'
import JoinCta from '../../JoinCta'
import OptedOutNotice from './OptedOutNotice'

import * as url from '@/lib/url'

const getImageUrl = image => {
  return url().getMediaUrl(image.path, { q: 70, w: 800 })
}

const getMarkdownText = input => {
  return {
    __html: marked(input || '')
  }
}

const renderQuestions = (data, post, q, onInteraction, user) => {
  let Handler

  switch (q.type) {
    case 'multiple':
      Handler = Multiple
      break
    case 'slider':
      Handler = Slider
      break
    case 'text':
      Handler = Text
      break
    case 'poll':
      Handler = Poll
      break
    default:
      Handler = Multiple
      break
  }

  return <Handler data={data} post={post} responses={q.responses} id={q._id} user={user} onInteraction={onInteraction} />
}

const CtaExpanded = ({ ctaData, deepLink }) => {
  const { handleInteraction, setMobileShareData, setExpandedCta } = useContext(InteractionContext)
  const { data, post } = ctaData
  const { user, update } = useUser()
  const { interactions: userInteractions } = useUserInteractions(user, post)
  const { body, questions, image, title } = data
  const imageUrl = image && image[0] ? getImageUrl(image[0]) : null

  post.userInteractions = userInteractions

  const handleCtaInteraction = (data = {}) => {
    handleInteraction({
      type: Constants.INTERACTION_TYPE.RESPONSE,
      data: {
        post: post._id,
        question: questions[0]._id,
        ...data
      }
    })
  }

  const handleMobileShareControls = () => {
    setMobileShareData({ open: true, post })
  }

  return (
    <div className={`flex justify-center w-full ${user ? 'py-16 lg:py-20' : 'pt-16 pb-72 lg:pb-32 lg:pt-20'}`}>
      <div className='fixed top-0 z-30 flex items-center justify-between w-full h-16 px-3 bg-themeBg lg:bg-transparent'>
        {user
          ? <img className='flex-shrink-0 h-8' src='/images/scramblers-s.svg' />
          : <img className='flex-shrink-0 h-8' src='/images/scramblers-logo.svg' />}
        <div className='flex items-center space-x-2'>
          <button
            onClick={handleMobileShareControls}
            className='relative top-0 right-0 text-white lg:hidden'
          >
            <svg className='w-5 h-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z' />
            </svg>
          </button>
          {
            deepLink
              ? (
                <a href='/' className='relative top-0 right-0 button--close group'>
                  <svg className='block w-3 h-3 transition-all duration-300 ease-in transform group-hover:rotate-180' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor' />
                  </svg>
                </a>
                )
              : (
                <button onClick={() => setExpandedCta(null)} className='relative top-0 right-0 button--close group'>
                  <svg className='block w-3 h-3 transition-all duration-300 ease-in transform group-hover:rotate-180' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor' />
                  </svg>
                </button>
                )
          }
        </div>
      </div>

      <div className='absolute hidden bottom-3 right-3 lg:block'>
        <ShareControlsExpanded post={post} />
      </div>

      <Transition.Child
        enter='transition-all ease-in delay-75 transform'
        enterFrom='opacity-0 translate-y-10'
        enterTo='opacity-100 translate-y-0'
        leave='transition-all ease-in transform'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-10'
        className='w-full max-w-screen-sm rounded-lg'
      >
        <div id='full-screen-cta'>
          <div className='cta-inner'>
            {
              imageUrl
                ? (
                  <div className='cta-image'>
                    <img src={imageUrl} />
                  </div>
                  )
                : null
            }
            <div className='title'>
              <h1 className='text-white title--sans title--sml' dangerouslySetInnerHTML={getMarkdownText(title)} />
            </div>

            {
              body !== ''
                ? (
                  <div className='description'>
                    <p className='bodyText' dangerouslySetInnerHTML={getMarkdownText(body)} />
                  </div>
                  )
                : null
            }

            {/* questions */}
            <div className='flex flex-row flex-wrap items-center w-full pt-4'>
              {questions.map((q, index) => {
                return (
                  <div className='w-full' key={index}>
                    <span className='text-white bodyText'>{q.text}</span>

                    {renderQuestions(data, post, q, handleCtaInteraction, user)}

                    <OptedOutNotice user={user} />
                  </div>
                )
              })}
            </div>

          </div>
        </div>
      </Transition.Child>

      {
        user
          ? null
          : <JoinCta />
      }
    </div>
  )
}

export default CtaExpanded
