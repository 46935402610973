import React from 'react'
import Cta from '../Sections/Cta'
const url = require('@/lib/url')()

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 75, w: 500 })
}

const CtaComponent = ({ data, post }) => {
  const imageUrl = (data.image && data.image[0]) ? getImageUrl(data.image[0]) : ''

  // Set active to true as default for older CTAs that don't have the 'active' property.
  let active = true

  if ('active' in data) {
    active = data.active
  }

  return (
    <Cta
      active={active}
      inactiveMessage={data.inactiveMessage}
      buttons={data.buttons}
      body={data.body}
      displayTitle={data.displayTitle}
      imageUrl={imageUrl}
      post={post}
      questions={data.questions || []}
      title={data.title}
    />
  )
}

export default CtaComponent
