import { useUser } from '@/hooks/useUser'
import Pin from './Layout/Pin'
import PostDivider from './Sections/PostDivider'
import PropTypes from 'prop-types'
import React from 'react'
import { getUserMembershipType } from '@/lib/memberships'
import * as gtag from '@/lib/gtag'
const PostUtils = require('@/lib/post')

const Post = props => {
  const { displayMode, index, post } = props

  const postData = {
    ...post
  }

  // determine if user is a paid member and add this data to the post
  const { user } = useUser()
  postData.userIsMember = getUserMembershipType(user)

  postData.isBookmarked =
    postData.userInteractions &&
    postData.userInteractions.find(
      i => i.type === 'BOOKMARK' && i.active && i.post._id === postData._id
    ) !== undefined

  const postUtils = new PostUtils(postData)
  const { pinned, tags } = postData
  const primaryTag = tags && tags.find(t => t.primary)
  const contentTypeTag = tags && tags.find(t => !t.visible)

  const postComponents = postUtils.getPostComponents(displayMode)

  const showPostDivider = true
  const divider = post.showPostDivider ? (
    <PostDivider
      post={postData}
      contentType={contentTypeTag ? contentTypeTag.value : null}
      key={index}
      index={index}
      title={primaryTag ? primaryTag.label : null}
    />
  ) : (
    ''
  )

  // Insert the divider under the first component if the post is an article (tagged with "Read")
  // if (primaryTag && primaryTag.label === 'Read') {
  //   postComponents.splice(1, 0, divider)
  //   showPostDivider = false
  // }
  // if (primaryTag && primaryTag.label === 'Respond') {
  //   showPostDivider = false
  // }

  return (
    <>
      {showPostDivider ? divider : null}
      {pinned ? <Pin /> : null}
      {postComponents}
    </>
  )
}

Post.propTypes = {
  displayMode: PropTypes.string,
  index: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  post: PropTypes.object,
  onJoin: PropTypes.func,
  height: PropTypes.number
}

export default Post
