import React from 'react'
import PullQuote from '../Sections/PullQuote'

const PullQuoteComponent = ({ data, post }) => {
  return (
    <PullQuote
      author={data.author}
      post={post}
      text={data.quote}
      theme={data.theme}
      fontSize={data.fontSize}
      fontType={data.fontType}
      link={data.link}
      linkText={data.linkText}
    />
  )
}

export default PullQuoteComponent
