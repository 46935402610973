import PropTypes from 'prop-types'
import React from 'react'
import marked from 'marked'

const getMarkdownText = input => {
  const rawMarkup = marked(input)
  return { __html: rawMarkup }
}

const BodyText = ({ displayHeading, heading, post, text }) => (
  <div className={`relative mt-5 componentBodyText ${displayHeading && heading ? 'pt-8' : ''}`}>
    {
      displayHeading
        ? <h3 className='font-medium uppercase subtitle subtitle--sans'>{heading}</h3>
        : null
    }
    <div className={`text-gray-800 componentBodyText__inner ${displayHeading && heading ? '-mt-4' : ''}`} dangerouslySetInnerHTML={getMarkdownText(text)} />
    {/* <div className='gradient' /> */}
  </div>
)

BodyText.propTypes = {
  // Indicates whether the supplied `heading` value should be shown.
  displayHeading: PropTypes.bool,

  // The heading text for this Body Text section.
  heading: PropTypes.string,

  post: PropTypes.object,

  // The main text for the Body Text section.
  text: PropTypes.string
}

export default BodyText
