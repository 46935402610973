import marked from 'marked'
import React from 'react'

const getMarkdownText = input => {
  const rawMarkup = marked(input)
  return { __html: rawMarkup }
}

const MembersOnlyLayer = ({
  title,
  primaryImage,
  type,
  memberCTA,
  nonMemberCTA,
  userIsMember
}) => {
  return (
    <div className='absolute top-0 left-0 z-20 w-full h-full'>
      <div className='relative z-10 flex flex-col items-center justify-center w-full h-full p-10 text-center'>
        <h1
          className='text-center title--sans title--sml'
          dangerouslySetInnerHTML={getMarkdownText(title)}
        />
        <p className='mt-2 font-light leading-snug text-black'>
          This {type} is exclusive to{' '}
          <span className='font-medium'>Pegasus</span> members.
        </p>
        {userIsMember ? (
          <button className='mt-4 button !text-[#F2E8D2] hover:!text-black button--dark--transparent'>
            {memberCTA}
          </button>
        ) : (
          <a
            href='/profile/me#membership'
            className='mt-4 button hover:!text-black !text-[#F2E8D2] button--dark--transparent'
          >
            {nonMemberCTA}
          </a>
        )}
      </div>
      <img
        className='absolute top-0 left-0 z-0 object-cover w-full h-full filter saturate-0'
        src={primaryImage}
      />
      <div className='absolute z-0 top-0 left-0 bg-[#BEAB7D] opacity-90 w-full h-full' />
    </div>
  )
}

export default MembersOnlyLayer
