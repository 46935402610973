import React from 'react'
import Subtitle from '../Sections/Subtitle'

const subtitle = ({ data, post }) => {
  return (
    <Subtitle
      bold={data.bold}
      fontType={data.fontType}
      italic={data.italic}
      subtitle={data.content}
      author={data.author}
      contributor={data.contributor}
      showBorder={data.showBorder}
      post={post}
    />
  )
}

export default subtitle
