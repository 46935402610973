// import { getProviders } from 'next-auth/client'
import React, { useState, useEffect } from 'react'
import JoinStep1 from './JoinStep1'
import JoinStep2 from './JoinStep2'
import { StateMachineProvider, createStore } from 'little-state-machine'
import { Transition } from '@headlessui/react'

createStore({
  data: {}
})

const Login = props => {
  const { onToggleLogin } = props
  const [activeForm, setActiveForm] = useState('join')
  const [providers, setProviders] = useState(null)

  useEffect(() => {
    if (!providers) {
      getAuthProviders()
    }
  }, [])

  const getAuthProviders = async () => {
    // const providers = await getProviders()
    setProviders([])
  }

  let Component

  switch (activeForm) {
    case 'join':
      Component = JoinStep1
      break
    default:
      Component = JoinStep2
  }

  return (
    <StateMachineProvider>
      <div>
        <Transition.Child
          enter='transition-all ease-in delay-100 duration-200 transform'
          enterFrom='opacity-0 translate-y-20'
          enterTo='opacity-100 translate-y-0'
          leave='transition-all ease-in duration-200 transform'
          leaveFrom='opacity-0 translate-y-0'
          leaveTo='opacity-100 translate-y-20'
          className='relative w-11/12 max-w-md transition transform translate-y-0 bg-white border border-black rounded-sm p-7 md:p-12'
        >
          <div className='flex flex-col items-center text-center'>
            {/* close button */}
            <button
              onClick={onToggleLogin}
              className='absolute flex items-center justify-center w-8 h-8 text-sm transition-all ease-in bg-white border border-black rounded-full hover:border-accent hover:bg-accent hover:text-white group focus:outline-none -top-4 -right-4'
            >
              <svg
                className='w-3 h-3 transition-all ease-in transform group-hover:rotate-180'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
              >
                <path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor' />
              </svg>
            </button>

            {/* rat logo */}
            {/* <img src='/images/logoRatino.png' width='60' /> */}

            {/* the form */}
            <Component
              authProviders={providers}
              onNextStep={step => setActiveForm(step)}
            />
          </div>
        </Transition.Child>
      </div>
    </StateMachineProvider>
  )

  const loginForm = () => {
    return (
      <div className='mt-5'>
        <h3 className='w-full text-black uppercase border-gray-800 title title--sml title--sans'>
          Welcome back
        </h3>
        <p className='mt-3 text-xs font-light text-gray-600 md:text-sm sm:px-4'>
          Welcome back to the rat nullam id dolor id nibh ultricies vehicula ut
          id elit.
        </p>

        {/* social login */}
        <div className='flex flex-col space-y-3 mt-7'>
          <div className='relative'>
            <div className='absolute inset-0 flex items-center'>
              <div className='w-full border-t border-gray-300' />
            </div>
            <div className='relative flex justify-center text-sm leading-5'>
              <span className='px-2 text-gray-700 uppercase bg-white caption'>
                Log in with
              </span>
            </div>
          </div>
          <div className='flex flex-wrap items-center justify-center space-x-2'>
            <button className='flex-shrink-0 button button--small'>
              <svg
                className='w-3 h-3'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7.5 14.5a7 7 0 110-14 7 7 0 010 14zm0 0v-8a2 2 0 012-2h.5m-5 4h5'
                  stroke='currentColor'
                />
              </svg>
              <span>Facebook</span>
            </button>
            <button className='flex-shrink-0 button button--small'>
              <svg
                className='w-3 h-3'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.478 1.5l.5-.033a.5.5 0 00-.871-.301l.371.334zm-.498 2.959a.5.5 0 10-1 0h1zm-6.49.082h-.5.5zm0 .959h.5-.5zm-6.99 7V12a.5.5 0 00-.278.916L.5 12.5zm.998-11l.469-.175a.5.5 0 00-.916-.048l.447.223zm3.994 9l.354.353a.5.5 0 00-.195-.827l-.159.474zm7.224-8.027l-.37.336.18.199.265-.04-.075-.495zm1.264-.94c.051.778.003 1.25-.123 1.606-.122.345-.336.629-.723 1l.692.722c.438-.42.776-.832.974-1.388.193-.546.232-1.178.177-2.006l-.998.066zm0 3.654V4.46h-1v.728h1zm-6.99-.646V5.5h1v-.959h-1zm0 .959V6h1v-.5h-1zM10.525 1a3.539 3.539 0 00-3.537 3.541h1A2.539 2.539 0 0110.526 2V1zm2.454 4.187C12.98 9.503 9.487 13 5.18 13v1c4.86 0 8.8-3.946 8.8-8.813h-1zM1.03 1.675C1.574 3.127 3.614 6 7.49 6V5C4.174 5 2.421 2.54 1.966 1.325l-.937.35zm.021-.398C.004 3.373-.157 5.407.604 7.139c.759 1.727 2.392 3.055 4.73 3.835l.317-.948c-2.155-.72-3.518-1.892-4.132-3.29-.612-1.393-.523-3.11.427-5.013l-.895-.446zm4.087 8.87C4.536 10.75 2.726 12 .5 12v1c2.566 0 4.617-1.416 5.346-2.147l-.708-.706zm7.949-8.009A3.445 3.445 0 0010.526 1v1c.721 0 1.37.311 1.82.809l.74-.671zm-.296.83a3.513 3.513 0 002.06-1.134l-.744-.668a2.514 2.514 0 01-1.466.813l.15.989zM.222 12.916C1.863 14.01 3.583 14 5.18 14v-1c-1.63 0-3.048-.011-4.402-.916l-.556.832z'
                  fill='currentColor'
                />
              </svg>
              <span>Twitter</span>
            </button>
            <button className='flex-shrink-0 button button--small'>
              <svg
                className='w-3 h-3'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
              >
                <path
                  d='M14.5 7.5h.5V7h-.5v.5zm-7 6.5A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 0A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zm0 1c1.819 0 3.463.746 4.643 1.951l.714-.7A7.479 7.479 0 007.5 0v1zM8 8h6.5V7H8v1z'
                  fill='currentColor'
                />
              </svg>
              <span>Google</span>
            </button>
          </div>
        </div>

        {/* form login */}
        <div className='mt-7'>
          <div className='relative'>
            <div className='absolute inset-0 flex items-center'>
              <div className='w-full border-t border-gray-300' />
            </div>
            <div className='relative flex justify-center text-sm leading-5'>
              <span className='px-2 text-gray-700 uppercase bg-white caption'>
                Or
              </span>
            </div>
          </div>
          <div className='grid gap-3 mt-3 md:grid-cols-1'>
            <input
              name='email'
              type='email'
              required
              className='input'
              placeholder='Email Address'
            />
          </div>
          <button
            onClick={() => signin('email', { email: this.state.email })}
            className='w-full mt-5 button button--dark'
          >
            Log in
          </button>
          <p className='mt-2 text-xs font-light text-gray-500'>
            Not a member?{' '}
            <a
              onClick={event => this.handleFormSwitch('join')}
              className='text-gray-800 underline cursor-pointer'
            >
              Join now
            </a>
            .
          </p>
        </div>
      </div>
    )
  }
}

export default Login
