import { InteractionContext } from '@/contexts/InteractionContext'
import { XIcon } from '@heroicons/react/outline'
import React, { useContext } from 'react'
import { Transition } from '@headlessui/react'

const MembersOnlyModal = props => {
  const { showMembershipModal, setShowMembershipModal } =
    useContext(InteractionContext)

  const closeMembershipModal = () => {
    setShowMembershipModal(false)
  }

  return (
    <>
      <Transition
        show={showMembershipModal === true}
        enter='transition duration-200 ease-in'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition duration-200 ease-out delay-100'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed top-0 left-0 z-50 flex items-end justify-center w-screen h-full px-2 md:px-0 md:items-center'
      >
        {/* white bg, when clicked closes overlay */}
        <div
          onClick={closeMembershipModal}
          className='absolute top-0 left-0 z-0 w-full h-full bg-themeBg opacity-95'
        />
        {/* close button */}
        <button
          onClick={closeMembershipModal}
          className='absolute z-10 hidden top-4 right-16 md:block focus:outline-none'
        >
          <XIcon className='w-10 h-10 text-white' />
        </button>
        <Transition.Child
          enter='transition delay-100 duration-200 ease-in transform'
          enterFrom='opacity-0 translate-y-5'
          enterTo='opacity-100 translate-y-0'
          leave='transition duration-200 ease-out transform'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 translate-y-5'
          className='z-10 w-full max-w-lg p-8 bg-white rounded-t shadow-lg md:rounded'
        >
          <div className='flex flex-col items-center w-full space-y-4 text-center transform'>
            <h1 className='px-4 font-normal leading-none normal-case bg-white title title--sml title--sans'>
              Upgrade for access
            </h1>
            <div>
              <p className='font-light'>
                This content (plus many other great benefits) is exclusive to
                our Pegasus members.
              </p>
              <p className='mt-3 font-light'>
                <a href='/profile/me' className='underline text-accent'>
                  Visit your profile
                </a>{' '}
                to upgrade your membership.
              </p>
            </div>
            <div className='flex flex-wrap items-center justify-center w-full pt-3'>
              <a
                href='/profile/me#membrship'
                className='mb-2 mr-3 button button--accent'
              >
                Upgrade my membership
              </a>
              <a
                onClick={closeMembershipModal}
                className='mb-2 button button--black'
              >
                Maybe later
              </a>
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </>
  )
}

export default MembersOnlyModal
