import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useStateMachine } from 'little-state-machine'
import updateAction from './updateAction'
// import { signin } from 'next-auth/client'

const JoinStep2 = props => {
  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm()
  const { state } = useStateMachine({ updateAction })
  const [saving, setSaving] = useState(false)

  const onSubmit = data => {
    setSaving(true)
    const { onSignUpComplete } = props

    // Merge the data.
    const newState = {
      ...state,
      data: {
        ...state.data,
        ...data
      }
    }

    // Call handleSignUp in pages/signup.js
    onSignUpComplete(newState.data)
  }

  return (
    <>
      <img
        className='h-10 mb-4 sm:hidden'
        src='/images/scramblers-logo.svg'
        alt='Scramblers'
      />
      <p className='w-full max-w-2xl font-light text-center text-white uppercase title title--sml title--sans sm:px-4'>
        Welcome aboard,{' '}
        <span className='italic font-bold'>{state.data.displayName}</span>. One
        more question before we sign you up -{' '}
        <span className='italic font-bold text-accent'>
          what car do you drive?
        </span>
      </p>
      <div className='w-full max-w-xl px-6 py-10 pt-0 mt-12 bg-black border border-gray-800 rounded-lg shadow-lg md:px-10 bg-opacity-60'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='relative flex items-center justify-center flex-1 text-sm transform -translate-y-3'>
            <span className='flex items-center justify-center h-6 px-3 text-gray-300 uppercase bg-black border border-gray-800 rounded-full caption'>
              I drive a...
            </span>
          </div>
          <div className='grid gap-3 mt-3 md:mt-5 md:grid-cols-1'>
            <div className='relative form-row'>
              <div className='relative flex flex-col items-start'>
                <label htmlFor='carYear' className='label'>
                  Year <sup>*</sup>
                </label>
                <input
                  name='carYear'
                  id='carYear'
                  type='text'
                  {...register('carYear', { required: true, pattern: /\d{4}/ })}
                  maxLength='4'
                  className={`input input--dark w-full ${
                    errors.carYear ? 'input--error' : ''
                  }`}
                  placeholder='1965'
                />
                {errors.carYear && errors.carYear.type === 'required' && (
                  <span className='error'>Required</span>
                )}
                {errors.carYear && errors.carYear.type === 'pattern' && (
                  <span className='error'>Invalid</span>
                )}
              </div>
              <div className='relative flex flex-col items-start'>
                <label htmlFor='carMake' className='label'>
                  Make <sup>*</sup>
                </label>
                <input
                  name='carMake'
                  id='carMake'
                  type='text'
                  {...register('carMake', { required: true })}
                  className={`input input--dark w-full ${
                    errors.carMake ? 'input--error' : ''
                  }`}
                  placeholder='Porsche'
                />
                {errors.carMake && errors.carMake.type === 'required' && (
                  <span className='error'>Required</span>
                )}
              </div>
              <div className='relative flex flex-col items-start'>
                <label htmlFor='carModel' className='label'>
                  Model <sup>*</sup>
                </label>
                <input
                  name='carModel'
                  id='carModel'
                  type='text'
                  {...register('carModel', { required: true })}
                  className={`input input--dark w-full ${
                    errors.carModel ? 'input--error' : ''
                  }`}
                  placeholder='911'
                />
                {errors.carModel && errors.carModel.type === 'required' && (
                  <span className='error'>Required</span>
                )}
              </div>
            </div>
          </div>
          <div className='flex flex-wrap items-center mt-5'>
            <div className='w-full'>
              <button
                type='submit'
                disabled={saving}
                className='flex flex-col items-center justify-center w-full group button button--light'
              >
                <span>
                  {saving ? 'Creating your account' : 'Start Free Membership'}
                </span>
                <span
                  className={`text-xs font-light normal-case transition-colors duration-300 group-hover:text-white ${
                    saving ? 'text-white animate-pulse' : 'text-gray-600'
                  }`}
                >
                  {saving
                    ? 'Please wait one moment'
                    : 'Upgrade from your profile page later, if you wish'}
                </span>
              </button>
            </div>
            {/* <div className='w-full mt-3'>
            <button disabled type='button' className='flex flex-col items-center justify-center w-full button button--dark'>
              <span>Start Premium Membership</span>
              <span className='text-xs normal-case opacity-[0.7] font-light'>(Coming soon)</span>
            </button>
          </div> */}
          </div>
        </form>
      </div>
    </>
  )
}

export default JoinStep2
