import PropTypes from 'prop-types'
import React from 'react'
const url = require('@/lib/url')()

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 75 })
}

const PromoCollection = ({ post, promos }) => (
  <div className='relative grid grid-cols-12 mt-16 componentPromoCollection container'>
    <div className='py-5 border-t border-b border-black border-opacity-50 default-grid-cols xl:col-end-9'>
      {/* {promos.length < 3 ? doublePromo(promos) : promoCarousel(promos)} */}
      {promoCarousel(promos)}
    </div>
  </div>
)

// DOUBLE PROMO
function doublePromo (promos) {
  return (
    <div className='grid grid-cols-2 gap-5'>
      {
        promos.map(p => {
          return (
            <a className='flex items-start group' key={p.index} href='/'>
              <div className='flex-shrink-0 w-20'>
                <img className='w-full' src='https://cdn.shopify.com/s/files/1/0115/4570/6554/products/Issue3-coverProduct_6a9785b1-f00b-4b7f-93ca-45fbcde38d7b.jpg?v=1573131873' />
              </div>
              <div className='flex flex-col items-start flex-1 ml-5'>
                <h3 className='text-2xl italic font-light leading-none'>{p.title}</h3>
                <p className='mt-3 mb-0 text-xs uppercase'>{p.shortDescription}</p>
                <span className='mt-3 text-xs uppercase border-b border-black group-hover:text-accent group-hover:border-accent' href='#'>Buy it now</span>
              </div>
            </a>
          )
        })
      }
    </div>
  )
}

// PROMO CAROUSEL
function promoCarousel (promos) {
  const Flickity = require('react-flickity-component')

  return (
    <div className='relative w-full pb-1'>
      <Flickity
        options={{
          cellAlign: 'left',
          imagesLoaded: false,
          setGallerySize: true,
          wrapAround: true,
          pageDots: true,
          prevNextButtons: false,
          autoPlay: 5000,
          cellSelector: '.carousel-cell'
        }}
        disableImagesLoaded={false}
        reloadOnUpdate // default false
        static // default false
      >
        {
          promos.map((promo, index) => {
            let imageUrl

            if (promo.post && promo.post.sections[0]) {
              imageUrl = promo.post.sections[0].imageUrl
            } else if (promo.image && promo.image[0]) {
              imageUrl = getImageUrl(promo.image[0])
            }

            return (
              <a className='flex items-start w-3/4 pr-10 carousel-cell group' key={index} href='/'>
                <div className='flex-shrink-0 w-24'>
                  <img className='w-full' src={`${imageUrl || 'https://cdn.shopify.com/s/files/1/0115/4570/6554/products/Issue3-coverProduct_6a9785b1-f00b-4b7f-93ca-45fbcde38d7b.jpg?v=1573131873'}`} />
                </div>
                <div className='flex flex-col items-start flex-1 ml-5'>
                  <h3 className='text-2xl italic font-light leading-none'>{promo.title}</h3>
                  <p className='mt-3 mb-0 text-sm'>{promo.shortDescription}</p>
                  <span className='mt-3 text-xs uppercase border-b border-black group-hover:text-accent group-hover:border-accent' href='#'>Buy it now</span>
                </div>
              </a>
            )
          })
        }
        <div className='absolute right-0 z-10 w-20 h-full pointer-events-none bg-gradient-to-r from-transparent to-white' />
      </Flickity>
    </div>
  )
}

PromoCollection.propTypes = {
  post: PropTypes.object,
  promos: PropTypes.array
}

export default PromoCollection
