import React from 'react'

function InstagramIcon ({ className }) {
  return (
    <svg className={className} viewBox='0 0 15 15' fill='none'>
      <path d='M11 3.5h1M4.5.5h6a4 4 0 014 4v6a4 4 0 01-4 4h-6a4 4 0 01-4-4v-6a4 4 0 014-4zm3 10a3 3 0 110-6 3 3 0 010 6z' stroke='currentColor' />
    </svg>
  )
}
export default InstagramIcon
