const querystring = require('querystring')
const SaneUrlBuilder = require('sane-url-builder')
const urlParse = require('url-parse')

const CDN_IMAGE_DEFAULTS = {
  q: 75
}

function urlUtils () {
  return {
    getApiUrl () {
      const urlBuilder = new SaneUrlBuilder()
      const urlData = urlParse(process.env.NEXT_PUBLIC_API_HOST)

      return urlBuilder
        .scheme(urlData.protocol.replace(':', ''))
        .host(urlData.host)
        .value()
    },

    getCdnUrl () {
      const urlBuilder = new SaneUrlBuilder()
      const urlData = urlParse(process.env.NEXT_PUBLIC_CDN_HOST)

      return urlBuilder
        .scheme(urlData.protocol.replace(':', ''))
        .host(urlData.host)
        .port(process.env.NEXT_PUBLIC_CDN_PORT)
        .value()
    },

    getCurrentUrl (url) {
      const urlBuilder = new SaneUrlBuilder()
      const urlData = urlParse(process.env.NEXT_PUBLIC_PUBLIC_URL)

      return urlBuilder
        .scheme(urlData.protocol.replace(':', ''))
        .host(urlData.host)
        .path(url)
        .value()
    },

    getMediaUrl (path, options) {
      options = options || CDN_IMAGE_DEFAULTS

      if (process.env.NEXT_PUBLIC_CDN_HOST) {
        return `${this.getCdnUrl()}${path}?${querystring.encode(options)}`
      }

      return `${this.getApiUrl()}${path}`
    },

    getShareUrl (post) {
      return this.getCurrentUrl(`/post/${post.slug}`)
    }
  }
}

module.exports = urlUtils
