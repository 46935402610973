import { InteractionContext } from '@/contexts/InteractionContext'
import React, { useContext } from 'react'
import Post from './Post'
import { Transition } from '@headlessui/react'
import ShareControlsExpanded from './ShareControlsExpanded'
import { useUser } from '@/hooks/useUser'
import JoinCta from './JoinCta'

const PostExpanded = ({ post, deepLink }) => {
  const { setExpandedPost, setMobileShareData } = useContext(InteractionContext)
  const { user } = useUser()

  const handleMobileShareControls = () => {
    setMobileShareData({ open: true, post })
  }

  return (
    <div className={`flex justify-center w-full ${user ? 'py-16 lg:py-20' : 'pt-16 pb-72 lg:pb-32 lg:pt-20'}`}>

      {/* expanded post header (logo, close button) */}
      <div className='fixed top-0 z-30 flex items-center justify-between w-full h-16 px-3 bg-themeBg lg:bg-transparent'>
        {user
          ? <img className='flex-shrink-0 h-8' src='/images/scramblers-s.svg' />
          : <img className='flex-shrink-0 h-8' src='/images/scramblers-logo.svg' />}
        <div className='flex items-center space-x-2'>
          <button
            onClick={handleMobileShareControls}
            className='relative top-0 right-0 text-white lg:hidden'
          >
            <svg className='w-5 h-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z' />
            </svg>
          </button>
          {
            deepLink
              ? (
                <a href='/' className='relative top-0 right-0 button--close group'>
                  <svg className='block w-3 h-3 transition-all duration-300 ease-in transform group-hover:rotate-180' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor' />
                  </svg>
                </a>
              )
              : (
                <button onClick={() => setExpandedPost(null)} className='relative top-0 right-0 button--close group'>
                  <svg className='block w-3 h-3 transition-all duration-300 ease-in transform group-hover:rotate-180' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M1.5 1.5l12 12m-12 0l12-12' stroke='currentColor' />
                  </svg>
                </button>
              )
          }
        </div>
      </div>

      {/* share controls for expanded post */}
      <div className='absolute hidden lg:block'>
        <ShareControlsExpanded post={post} />
      </div>

      {/* the post content */}
      <Transition.Child
        enter='transition-all ease-in delay-75 transform'
        enterFrom='opacity-0 translate-y-10'
        enterTo='opacity-100 translate-y-0'
        leave='transition-all ease-in transform'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-10'
        className='w-full max-w-[888px] overflow-hidden relative bg-white rounded-lg'
      >
        <div id='full-screen-post'>
          <Post post={post} displayMode='raw' />
        </div>
      </Transition.Child>

      {/* 'JOIN!' cta shown for non logged in users */}
      {
        user
          ? null
          : <JoinCta />
      }

    </div>
  )
}

export default PostExpanded
