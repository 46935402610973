import Link from 'next/link'
import { InteractionContext } from '@/contexts/InteractionContext'
import Loader from '@/components/Icons/Loader'
import NumberFormat from 'react-number-format'
import { useContext, useState } from 'react'
import { trackEvent } from '@/lib/gtag'
import { useCart } from 'react-use-cart'
import { useUser } from '@/hooks/useUser'

const Cart = () => {
  const { setCartVisible } = useContext(InteractionContext)
  const { cartTotal, isEmpty, items, updateItemQuantity } = useCart()
  const [checkoutStatus, setCheckoutStatus] = useState('Checkout now')
  const { user } = useUser()

  const handleCheckout = async () => {
    setCheckoutStatus('Preparing checkout...')

    const res = await fetch('/api/getShopCheckout', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ cart: { items }, user })
    })

    if (res.status === 200) {
      const { checkoutUrl } = await res.json()

      // trackEvent({
      //   action: 'begin_checkout',
      //   data: shopifyUtils.getEventData(items)
      // })

      window.location.replace(checkoutUrl)
      setCheckoutStatus('Checkout now')

      setTimeout(() => {
        // emptyCart()
        setCartVisible(false)
      }, 5000)
    }
  }

  if (isEmpty) {
    return (
      <div className='flex flex-col p-5 w-80 bg-white rounded-md border border-gray-800 shadow-lg transform translate-y-9 pointer-events-auto'>
        <div className='text-center text-black'>
          <span className='text-bodyText'>Your cart is empty</span>
          <p className='mt-2 text-sm font-light leading-tight text-gray-600'>
            {/* Nullam quis risus eget urna mollis ornare vel eu leo. */}
          </p>

          <a className='mt-4 button button--secondary' href='/shop/all'>
            Visit the shop
          </a>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col p-5 w-80 bg-white rounded-md border border-gray-800 shadow-lg transform translate-y-9 pointer-events-auto'>
      <div className='space-y-4 w-full text-sm'>
        {items.map(item => {
          return (
            <div key={item.id} className='flex flex-col'>
              <div className='flex flex-row items-start pb-3 mb-3 space-x-4 border-b border-gray-600 last:mb-0'>
                <img
                  src={
                    item.images &&
                    item.images[0] &&
                    item.images[0].file &&
                    item.images[0].file.url
                  }
                  width='50'
                />
                <div className='flex flex-col flex-1 space-y-1'>
                  <Link
                    href={`/shop/${
                      item.categories && item.categories[0]
                        ? item.categories[0].slug
                        : 'item'
                    }/${item.slug}`}
                    className='text-black cursor-pointer'
                  >
                    {item.name}
                  </Link>
                  <div className='flex justify-between text-gray-600'>
                    <div className='flex items-center rounded-sm border border-gray-600'>
                      <button
                        className='flex justify-center items-center w-5 h-5 text-xs border-r border-gray-700'
                        onClick={() => {
                          updateItemQuantity(item.id, item.quantity - 1)
                        }}
                      >
                        -
                      </button>
                      <span className='flex-1 px-2 text-xs'>
                        {item.quantity}
                      </span>
                      <button
                        className='flex justify-center items-center w-5 h-5 text-xs border-l border-gray-700'
                        onClick={() => {
                          updateItemQuantity(item.id, item.quantity + 1)
                        }}
                      >
                        +
                      </button>
                    </div>
                    <span className=''>
                      <NumberFormat
                        value={item.price * item.quantity}
                        displayType='text'
                        fixedDecimalScale
                        decimalScale='2'
                        thousandSeparator
                        prefix='£'
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}

        {/* totals */}
        <div className='flex justify-between text-black'>
          <span>Total:</span>
          <span className=''>
            <NumberFormat
              value={cartTotal}
              displayType='text'
              fixedDecimalScale
              decimalScale='2'
              thousandSeparator
              prefix='£'
            />
          </span>
        </div>

        {/* checkout button */}
        {/* {user ? (
          <button
            className='w-full button button--secondary'
            disabled={checkoutStatus === 'Preparing checkout...'}
            onClick={handleCheckout}
          >
            {checkoutStatus === 'Preparing checkout...' && (
              <Loader className='w-[18px] h-[18px] animate-spin' />
            )}
            <span>{checkoutStatus}</span>
          </button>
        ) : ( */}
        <Link href='/shop/cart' legacyBehavior>
          <button
            className='w-full button button--secondary'
            onClick={() => setCartVisible(false)}
          >
            <span>View cart</span>
          </button>
        </Link>
        {/* )} */}
      </div>
    </div>
  )
}

export default Cart
