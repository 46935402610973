export const SWELL_GRAPHQL_API_ENDPOINT = '/graphql'
export const TAGS = {
  collections: 'collections',
  products: 'products',
  cart: 'cart'
}

export const COOKIE_TOKEN = 'accessToken'
export const COOKIE_TOKEN_EXPIRY = 'accessTokenExpiry'

export const MEDIA_COLLECTION = 'mediaStore'

export const STATUS_FAILED = 'STATUS_FAILED'
export const STATUS_IDLE = 'STATUS_IDLE'
export const STATUS_LOADED = 'STATUS_LOADED'
export const STATUS_LOADING = 'STATUS_LOADING'
export const STATUS_NOT_FOUND = 'STATUS_NOT_FOUND'

export const INTERACTION_TYPE = {
  BOOKMARK: 'BOOKMARK',
  PAGE_VIEW: 'PAGE_VIEW',
  RESPONSE: 'RESPONSE',
  SHARE: 'SHARE',
  TIME_SPENT: 'TIME_SPENT',
  VIEW_CONTENT: 'VIEW_CONTENT'
}
