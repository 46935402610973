import * as Constants from '@/lib/constants'
import { InteractionContext } from '@/contexts/InteractionContext'
import React, { useContext } from 'react'
import useUserInteractions from '@/hooks/useUserInteractions'
import { useUser } from '@/hooks/useUser'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share'
import { Transition } from '@headlessui/react'

const PostUtils = require('@/lib/post')

const ShareControlsMobile = ({ post }) => {
  const postUtils = new PostUtils(post)
  const { handleInteraction, setLoginOpen, setMobileShareData } = useContext(InteractionContext)
  const { user } = useUser()
  const { interactions: userInteractions } = useUserInteractions(user, post)

  const handleBookmarkIntention = event => {
    event.preventDefault()

    if (user) {
      handleInteraction({
        type: Constants.INTERACTION_TYPE.BOOKMARK,
        data: {
          post: post._id
        }
      })
    } else {
      setLoginOpen(true)
    }
  }

  const handleShareInteraction = platform => {
    handleInteraction({
      type: Constants.INTERACTION_TYPE.SHARE,
      data: {
        post: post._id
      }
    })
  }

  const handleMobileShareClose = () => {
    setMobileShareData({ open: false, post })
  }

  const isBookmarked = userInteractions && userInteractions.find(i => i.type === 'BOOKMARK' && i.active && i.post._id === post._id) !== undefined

  const tags = postUtils.getStructuredTags()

  return (
    <>
      <div
        className='fixed top-0 left-0 w-full h-full opacity-0'
        onClick={handleMobileShareClose}
      />
      <Transition.Child
        enter='transition duration-200 ease-in delay-100'
        enterFrom='opacity-0 translate-y-full'
        enterTo='opacity-100 translate-y-0'
        leave='transition duration-200 ease-out'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-full'
        className='absolute bottom-0 left-0 flex flex-col items-start w-full py-5 transform bg-white rounded-t-3xl'
      >
        <div className='flex flex-col w-full px-3 pb-3 border-b border-gray-300'>
          <div className='flex items-center'>
            <svg className='w-3 h-3 mr-2' viewBox='0 0 15 15' fill='none'>
              <path d='M.5 7.5l-.354-.354a.5.5 0 000 .708L.5 7.5zm7 7l-.354.354a.5.5 0 00.708 0L7.5 14.5zm7-7l.354.354A.5.5 0 0015 7.5h-.5zm-7-7V0a.5.5 0 00-.354.146L7.5.5zM.146 7.854l7 7 .708-.708-7-7-.708.708zm7.708 7l7-7-.708-.708-7 7 .708.708zM15 7.5v-6h-1v6h1zM13.5 0h-6v1h6V0zM7.146.146l-7 7 .708.708 7-7-.708-.708zM15 1.5A1.5 1.5 0 0013.5 0v1a.5.5 0 01.5.5h1zM10.5 5a.5.5 0 01-.5-.5H9A1.5 1.5 0 0010.5 6V5zm.5-.5a.5.5 0 01-.5.5v1A1.5 1.5 0 0012 4.5h-1zm-.5-.5a.5.5 0 01.5.5h1A1.5 1.5 0 0010.5 3v1zm0-1A1.5 1.5 0 009 4.5h1a.5.5 0 01.5-.5V3z' fill='currentColor' />
            </svg>
            <span className='flex flex-wrap items-center flex-shrink-0 space-x-1 caption'>
              {tags.map((tag, index) => {
                return (
                  <span key={index}>
                    <a
                      className='bg-themeBg rounded-full px-2 py-0.5 text-white capitalize'
                      href={tag.href}
                      onClick={() => {
                        { /* pageView(tag.href) */ }
                      }}
                    >
                      {tag.label}
                    </a>
                  </span>
                )
              })}
            </span>
          </div>
        </div>

        {/* share icons */}
        {/* these are hidden by default and animate out based on the 'shareOptionsOpen' hook */}
        <div className='flex items-center w-full p-3 space-x-2 border-b border-gray-300'>
          <svg className='w-3 h-3' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M4.5 7.5L8.5 7.5M11 4L8.5 7.49542L11 11M14.5 2.4987C14.5 3.60198 13.604 4.49739 12.5 4.49739C11.396 4.49739 10.5 3.60198 10.5 2.4987C10.5 1.39542 11.396 0.5 12.5 0.5C13.604 0.5 14.5 1.39542 14.5 2.4987ZM14.5 12.4922C14.5 13.5954 13.604 14.4909 12.5 14.4909C11.396 14.4909 10.5 13.5954 10.5 12.4922C10.5 11.3889 11.396 10.4935 12.5 10.4935C13.604 10.4935 14.5 11.3889 14.5 12.4922ZM4.5 7.49543C4.5 8.59871 3.604 9.49413 2.5 9.49413C1.396 9.49413 0.5 8.59871 0.5 7.49543C0.5 6.39215 1.396 5.49673 2.5 5.49673C3.604 5.49673 4.5 6.39215 4.5 7.49543Z' stroke='currentColor' strokeLinecap='square' />
          </svg>
          <div className='flex items-center flex-1 space-x-1.5'>
            <FacebookShareButton
              hashtag={postUtils.hashtags(false, true)}
              onShareWindowClose={() => handleShareInteraction('facebook')}
              quote={postUtils.socialShareMessage()}
              url={postUtils.socialShareUrl()}
              resetButtonStyle={false}
              className='flex text-xs items-center justify-center flex-1 px-2 py-1.5 space-x-2 border rounded border-themeBg'
            >
              <FacebookIcon size={18} round />
              <span>Facebook</span>
            </FacebookShareButton>
            <TwitterShareButton
              hashtags={postUtils.hashtags(true, false)}
              onShareWindowClose={() => handleShareInteraction('twitter')}
              title={postUtils.socialShareMessage()}
              url={postUtils.socialShareUrl()}
              via='wearescramblers'
              resetButtonStyle={false}
              className='flex items-center text-xs justify-center flex-1 px-2 py-1.5 space-x-2 border rounded border-themeBg'
            >
              <TwitterIcon size={18} round />
              <span>Twitter</span>
            </TwitterShareButton>
            <WhatsappShareButton
              onShareWindowClose={() => handleShareInteraction('whatsapp')}
              title={postUtils.socialShareMessage()}
              url={postUtils.socialShareUrl()}
              resetButtonStyle={false}
              className='flex items-center text-xs justify-center flex-1 px-2 py-1.5 space-x-2 border rounded border-themeBg'
            >
              <WhatsappIcon size={18} round />
              <span>WhatsApp</span>
            </WhatsappShareButton>
          </div>
        </div>

        {/* save button */}
        <div className='w-full p-3'>
          {
            user
              ? isBookmarked
                ? (
                  <a
                    className='flex items-center justify-center flex-1 w-full px-3 py-2.5 space-x-2 text-xs border rounded border-accent text-white bg-accent'
                    onClick={handleBookmarkIntention}
                  >
                    <svg className='w-4 h-4' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg' width='15' height='15'>
                      <path d='M13 0H2v14.5a.5.5 0 00.812.39L7.5 11.14l4.688 3.75A.5.5 0 0013 14.5V0z' fill='currentColor' />
                    </svg>
                    <span>Saved for later</span>
                  </a>
                )
                : (
                  <a
                    className='flex items-center justify-center flex-1 w-full px-3 py-2.5 space-x-2 text-xs border rounded border-themeBg'
                    onClick={handleBookmarkIntention}
                  >
                    <svg className='w-4 h-4' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M12.5 14.5l-.312.39A.5.5 0 0013 14.5h-.5zm0-14h.5V0h-.5v.5zm-10 0V0H2v.5h.5zm0 14H2a.5.5 0 00.812.39L2.5 14.5zm5-4l.312-.39a.5.5 0 00-.624 0l.312.39zm5.5 4V.5h-1v14h1zM2 .5v14h1V.5H2zm.812 14.39l5-4-.624-.78-5 4 .624.78zm4.376-4l5 4 .624-.78-5-4-.624.78zM12.5 0h-10v1h10V0z' fill='currentColor' />
                    </svg>
                    <span>Save for later</span>
                  </a>
                )
              : null
          }
        </div>
      </Transition.Child>
    </>
  )
}

export default ShareControlsMobile
