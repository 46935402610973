import React from 'react'
import BodyText from '../Sections/BodyText'

const bodyText = ({ data, post }) => {
  return (
    <BodyText
      displayHeading={data.displayHeading}
      // displayHeading={data.displayHeading}
      heading={data.heading}
      post={post}
      text={data.body}
      theme={data.theme}
    />
  )
}

export default bodyText
