import React from 'react'
import Form from '../Sections/Form'

const FormComponent = ({ data }) => {
  return (
    <Form
      body={data.body}
      elements={data.elements}
      title={data.title}
      theme={data.theme}
    />
  )
}

export default FormComponent
