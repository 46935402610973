import Article from './Article/Article'
import Cta from './Cta/Cta'
import Exhibition from './Exhibition/Exhibition'
import Podcast from './Podcast/Podcast'
import Promo from './Promo/Promo'
import Quote from './Quote/Quote'
import Video from './Video/Video'
import * as url from '@/lib/url'

const getImageUrl = image => {
  return url().getMediaUrl(image.path)
}

const getMediaUrl = data => {
  if (data.url) {
    return data.url
  }

  if (data.media) {
    return url().getMediaUrl(data.media[0].path)
  }

  return ''
}

const Index = ({ data, post, type }) => {
  const imageUrl = (data.image && data.image[0]) ? getImageUrl(data.image[0]) : ''

  switch (type) {
    case 'response':
      return (
        <Cta
          post={post}
          data={data}
        />
      )
    case 'promo':
      return (
        <Promo
          post={post}
          {...data}
        />
      )
    case 'exhibition':
      return (
        <Exhibition
          post={post}
          {...data}
        />
      )
    case 'article':
      return (
        <Article
          post={post}
          {...data}
        />
      )
    case 'video':
      return (
        <Video
          post={post}
          {...data}
        />
      )
    case 'quote':
      return (
        <Quote
          post={post}
          {...data}
        />
      )
    default:
      return (
        <Article
          post={post}
          {...data}
        />
      )
  }
}

export default Index
