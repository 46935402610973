import React from 'react'
import ImageGallery from '../Sections/ImageGallery'

const ImageGalleryComponent = ({ data, post }) => {
  return (
    <ImageGallery
      images={data.images}
      imageMetadata={data.imageMetadata}
      post={post}
    />
  )
}

export default ImageGalleryComponent
