import * as gtag from '@/lib/gtag'
import { InteractionContext } from '@/contexts/InteractionContext'
import { INTERACTION_TYPE } from '@/lib/constants'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import marked from 'marked'
import ReactPlayer from 'react-player'

const url = require('@/lib/url')()

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 70, w: 800 })
}

const getMarkdownText = input => {
  const rawMarkup = marked(input)
  return { __html: rawMarkup }
}

const VideoEmbed = ({ displayTitle, embedCode, post, posterImage, title }) => {
  const { handleInteraction } = useContext(InteractionContext)

  const handlePlayInteraction = () => {
    handleInteraction({
      type: INTERACTION_TYPE.VIEW_CONTENT,
      data: {
        post: post._id
      }
    })

    gtag.event({
      category: 'select_content',
      action: 'play-video',
      label: post.title
    })
  }

  return (
    <div className='video-embed'>
      <div className='react-player-wrapper'>
        <ReactPlayer
          url={embedCode}
          playing
          controls
          width='100%'
          height='100%'
          stopOnUnmount
          light={getImageUrl(posterImage[0])}
          onReady={handlePlayInteraction}
        />
      </div>
      <span className='caption' dangerouslySetInnerHTML={getMarkdownText(title)} />
    </div>
  )
}

VideoEmbed.propTypes = {
  displayTitle: PropTypes.bool,
  embedCode: PropTypes.string,
  title: PropTypes.string,
  posterImage: PropTypes.array
}

export default VideoEmbed
