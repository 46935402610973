import React from 'react'
import Image from '../Sections/Image'
const url = require('@/lib/url')()

const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 80, w: 800 })
}

const image = ({ data, post }) => {
  return (
    <Image
      metadata={data.imageMetadata && data.imageMetadata[0]}
      post={post}
      src={data.image && data.image[0] ? getImageUrl(data.image[0]) : ''}
      width={data.width}
    />
  )
}

export default image
