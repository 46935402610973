import React from 'react'
import { CheckIcon } from '@heroicons/react/outline'

const ModalMembershipOption = ({
  canRenew,
  description,
  disabled,
  features,
  price,
  priceDiscounted,
  title,
  onSelect
}) => {
  // Disable display of the remaining spots
  // spotsRemaining = false

  return (
    <div className='relative flex flex-col h-full overflow-hidden bg-black bg-opacity-25 border border-white rounded-md border-opacity-10'>
      <div className='flex flex-col items-start flex-1 p-5 text-gray-600 lg:p-8 pb-7'>
        <div className='flex items-end justify-between w-full pb-3 border-b border-gray-800'>
          <div className='flex items-end space-x-2'>
            <h4 className='text-lg text-white lg:text-2xl'>{title}</h4>
          </div>
          {!disabled && canRenew && priceDiscounted !== null && (
            <span className='text-sm text-themeYellow'>
              <s>{price}/year</s>
              <sup>*</sup>
            </span>
          )}
          {!disabled && !canRenew && (
            <span className='text-base text-themeYellow'>
              {price}
              {price !== 'FREE' ? '/year' : ''}
            </span>
          )}
        </div>
        <div className='mt-5 text-base font-light text-gray-300'>
          {description}
        </div>
        <ul className='flex flex-col mt-5 text-white bodyTextSml'>
          {features.map((feature, index) => {
            return (
              <div
                key={index}
                className='flex items-center mr-5 mb-1.5 space-x-1'
              >
                <CheckIcon className='w-3.5 h-3.5 text-accent' />
                <span className='font-light'>{feature}</span>
              </div>
            )
          })}
        </ul>
      </div>

      <button
        type='button'
        onClick={onSelect}
        className='py-3 border-none rounded-t-none button button--light'
      >
        Become a {title} member
      </button>
    </div>
  )
}

export default ModalMembershipOption
