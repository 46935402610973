import shopMat from './shop'
import listenMat from './listen'

const welcomeMats = {
  shop: shopMat,
  listen: listenMat
}

export const getWelcomeMat = (category) => {
  if (welcomeMats[category]) {
    return welcomeMats[category]
  }

  return null
}
