import { InteractionContext } from '@/contexts/InteractionContext'
import { INTERACTION_TYPE } from '@/lib/constants'
import MembersOnlyLayer from '../../MembersOnlyLayer'
import * as gtag from '@/lib/gtag'
import PropTypes from 'prop-types'
import React, { useContext, useState, useEffect, useRef } from 'react'
import marked from 'marked'
const url = require('@/lib/url')()

const getThumbnail = image => {
  return url.getMediaUrl(image.path, { w: 250, q: 70 })
}
const getImageUrl = image => {
  return url.getMediaUrl(image.path, { q: 70, w: 800 })
}

const getMarkdownText = input => {
  return {
    __html: marked(input || '')
  }
}

const Exhibition = ({ content, images, imageMetadata, title, post }) => {
  const { handleInteraction, setExpandedExhibition } =
    useContext(InteractionContext)
  const [activeThumb, setActiveThumb] = useState(1)

  const numImages = 9

  function useInterval (callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick () {
        savedCallback.current()
      }

      if (delay !== null) {
        const id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  const handleGalleryExpansion = () => {
    handleInteraction({
      type: INTERACTION_TYPE.VIEW_CONTENT,
      data: {
        post: post._id
      }
    })

    setExpandedExhibition({
      images,
      imageMetadata,
      title,
      slug: post.slug,
      post
    })

    gtag.event({
      category: 'select_content',
      action: 'open-gallery',
      label: post.title
    })
  }

  const updateCurrentImage = () => {
    const newImage = Math.floor(Math.random() * numImages)
    return newImage
  }

  useInterval(() => {
    const current = updateCurrentImage()
    setActiveThumb(current)
  }, 2500)

  // Always show 9 images by creating an array of repeating images.
  const images2 =
    images.length < numImages
      ? [...images, ...images, ...images].slice(0, 9)
      : images

  // determine whether article can be viewed
  const { userIsMember, subscriberOnly } = post
  let canView = true
  if (subscriberOnly && !userIsMember) {
    canView = false
  }

  return (
    <div
      id='grid-exhibition'
      className={`${subscriberOnly && canView && 'cursor-pointer'}`}
      onClick={subscriberOnly && canView ? handleGalleryExpansion : null}
    >
      {subscriberOnly && (
        <MembersOnlyLayer
          type='gallery'
          title={title}
          primaryImage={images ? getImageUrl(images[0]) : null}
          memberCTA='Open Gallery'
          nonMemberCTA='Upgrade to view gallery'
          userIsMember={userIsMember}
        />
      )}
      <div className='image-grid'>
        {images2.map((image, index) =>
          index + 1 <= numImages ? (
            <div
              className={`image-holder transition-all duration-2000 ${
                index === activeThumb ? 'opacity-[60%] active' : 'opacity-[50%]'
              }`}
              key={index}
            >
              <img src={getThumbnail(image)} data-index={index} className='' />
            </div>
          ) : null
        )}
      </div>

      {/* title / subtitle */}
      <div className='exhibition-detail'>
        {/* <Title
          title={props.title}
          fontSize={props.fontSize}
          fontType={props.fontType}
        />
        <Subtitle
          subtitle={props.content}
          showBorder={props.showBorder}
          author={props.author}
          post={post}
          italic={props.italic}
        /> */}

        {/* title */}
        <h1
          className='font-medium exhibition-title title--sans title--sml'
          dangerouslySetInnerHTML={getMarkdownText(title)}
        />

        {/* subtitle */}
        <span
          className='mt-2 text-base font-light leading-tight exhibition-subtitle'
          dangerouslySetInnerHTML={getMarkdownText(content)}
        />

        {/* cta */}
        <div className='flex justify-center mt-3'>
          <button onClick={handleGalleryExpansion} className='button'>
            <span className='hidden lg:block'>Open gallery</span>
            <span className='lg:hidden'>Open gallery</span>
            <svg
              className='w-3 h-3'
              viewBox='0 0 15 15'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M13.5 13.5H10m3.5 0V10m0 3.5l-4-4m.5-8h3.5m0 0V5m0-3.5l-4 4M5 1.5H1.5m0 0V5m0-3.5l4 4m-4 4.5v3.5m0 0H5m-3.5 0l4-4'
                stroke='currentColor'
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

Exhibition.propTypes = {
  post: PropTypes.object
}

export default Exhibition
